import React from 'react';
import clsx from 'clsx';
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    BackgroundColor: 'pink'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export function AppDrawer({ open, handleClick }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleClick}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <Tooltip title="Company" placement="right">
          <ListItem button key={"Company"} component={Link} to={'/company'}>
            <ListItemIcon><BusinessIcon /></ListItemIcon>
            <ListItemText primary={"Company"} />
          </ListItem>
        </Tooltip>
        <Tooltip title="Products and Services" placement="right">
          <ListItem button key={"Products and Services"} component={Link} to={'/products'}>
            <ListItemIcon><BusinessCenterIcon /></ListItemIcon>
            <ListItemText primary={"Products and Services"} />
          </ListItem>
        </Tooltip>
        <Tooltip title="Accepted Assets" placement="right">
          <ListItem button key={"Accepted Assets"} component={Link} to={'/assets'}>
            <ListItemIcon><AssignmentTurnedInIcon /></ListItemIcon>
            <ListItemText primary={"Accepted Assets"} />
          </ListItem>
        </Tooltip>
        <Tooltip title="Staff" placement="right">
          <ListItem button key={"Staff"} component={Link} to={'/staff'}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary={"Staff"} />
          </ListItem>
        </Tooltip>
        <Tooltip title="Resources" placement="right">
          <ListItem button key={"Resources"} component={Link} to={'/resources'}>
            <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
            <ListItemText primary={"Resources"} />
          </ListItem>
        </Tooltip>
      </List>
      <Divider />
      <List>
        <Tooltip title="My Events" placement="right" component={Link} to={'/'}>
          <ListItem button key={"My Events"}>
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary={"My Events"} />
          </ListItem>
        </Tooltip>
      </List>
    </Drawer>
  );
}