/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export function StaffBoothAdminActionButtons(
  value,
  key,
  onEdit,
  onDelete,
  eventIsArchived
) {
  let buttonStyle = {
    marginBottom: "8px",
    padding: "8px",
    borderRadius: "4px",
  };
  return (
    <ButtonGroup
      key={key}
      variant="contained"
      disableElevation={true}
      color="primary"
      size="small"
      fullWidth
      orientation="vertical"
    >
      <Button onClick={onEdit} color="primary" fullWidth style={buttonStyle}>
        {eventIsArchived ? "View" : "Edit"}
      </Button>
      {/* <Button onClick={onDelete} fullWidth style={buttonStyle}>
                Unassign
            </Button> */}
    </ButtonGroup>
  );
}
