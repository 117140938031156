import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import { Field } from "formik";

export function EditResourceInfo() {
  return (
    <React.Fragment>
      <Field
        component={() => (
          <Tooltip
            title="If public, The resource can be
                    viewed in the exhibit booth by everyone in the virtual environment
                    including other Exhibitors. If not public,
                    only Attendees can view this resource."
          >
            <FormControlLabel
              control={
                <Switch checked={true} name="isPublic" color="primary" />
              }
              label="Public"
            />
          </Tooltip>
        )}
        name="isPublic"
      />
      <Field
        component={TextField}
        name="resourceType"
        type="text"
        label="Resource Type"
        margin="dense"
        variant="outlined"
        fullWidth
        disabled
      />
      <Field
        component={TextField}
        name="resourceName"
        type="text"
        label="Resource Name"
        margin="dense"
        variant="outlined"
        fullWidth
      />
      <Field
        component={TextField}
        name="resourceDescription"
        type="text"
        label="Resource Description"
        margin="dense"
        variant="outlined"
        rows={5}
        maxRows={Infinity}
        multiline
        fullWidth
      />
    </React.Fragment>
  );
}
