/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

export function UrlToButton({ value }) {
    const [url, setUrl] = useState(value);

    if (url) {
        return (
            <Button variant="contained" size="small" color="primary" href={url} target="_blank">
                View
            </Button>
        )
    } else {
        return "No Data";
    }
}
