import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import Filter5Icon from '@material-ui/icons/Filter5';

import { useSelector} from 'react-redux'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
      display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
},
center: {
    textAlign: 'center',
},
}));

export default function Reporting() {
const classes = useStyles();
const event = useSelector((state) => state.event)
const [ mostLikedPoster, setMostLikedPoster] = useState([])
const [ mostActiveVisitor, setMostActiveVisitor] = useState([])
const [ mostEngagedAuthor, setMostEngagedAuthor] = useState([])
const [ mostEngagedVisitor, setMostEngagedVisitor] = useState([])


    useEffect(() => {
        (async () => {

            const user = await Auth.currentSession()

            const leaderboard = await axios({
              method: "GET",
              url: 'leaderboard/' + event.event.show_id,
              headers: { 
                  idtoken: user.idToken.jwtToken 
              },
          });

            setMostLikedPoster(leaderboard.data.mostLikedPoster.rows.slice(0, 5))
            setMostActiveVisitor(leaderboard.data.mostActiveVisitor.rows.slice(0, 5))
            setMostEngagedAuthor(leaderboard.data.mostEngagedAuthor.rows.slice(0, 5))
            setMostEngagedVisitor(leaderboard.data.mostEngagedVisitor.rows.slice(0, 5))

        })()
    }, [event.event.show_id])


  return (
    <Paper className={classes.paper}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.center} variant='h5' >
                    Most Liked Posters
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {mostLikedPoster.map((poster, index) => {
                        
                        let Icon
                        switch(index){
                            case 0:
                                Icon = Filter1Icon
                                break
                            case 1:
                                Icon = Filter2Icon
                                break
                            case 2:
                                Icon = Filter3Icon
                                break
                            case 3:
                                Icon = Filter4Icon
                                break
                            case 4:
                                Icon = Filter5Icon
                                break
                            default:
                                Icon = Filter1Icon
                        }

                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={poster.title} 
                                    // secondary={poster.organization} 
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.center} variant='h5' >
                    Most Active Visitors
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {mostActiveVisitor.map((visitor, index) => {
                        
                        let Icon
                        switch(index){
                            case 0:
                                Icon = Filter1Icon
                                break
                            case 1:
                                Icon = Filter2Icon
                                break
                            case 2:
                                Icon = Filter3Icon
                                break
                            case 3:
                                Icon = Filter4Icon
                                break
                            case 4:
                                Icon = Filter5Icon
                                break
                            default:
                                Icon = Filter1Icon
                        }

                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={visitor.name} 
                                    secondary={visitor.organization} 
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.center} variant='h5' >
                    Most Engaged Authors
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {mostEngagedAuthor.map((author, index) => {
                        
                        let Icon
                        switch(index){
                            case 0:
                                Icon = Filter1Icon
                                break
                            case 1:
                                Icon = Filter2Icon
                                break
                            case 2:
                                Icon = Filter3Icon
                                break
                            case 3:
                                Icon = Filter4Icon
                                break
                            case 4:
                                Icon = Filter5Icon
                                break
                            default:
                                Icon = Filter1Icon
                        }

                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={author.name} 
                                    secondary={author.organization} 
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.center} variant='h5' >
                    Most Engaged Visitors
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {mostEngagedVisitor.map((visitor, index) => {
                        
                        let Icon
                        switch(index){
                            case 0:
                                Icon = Filter1Icon
                                break
                            case 1:
                                Icon = Filter2Icon
                                break
                            case 2:
                                Icon = Filter3Icon
                                break
                            case 3:
                                Icon = Filter4Icon
                                break
                            case 4:
                                Icon = Filter5Icon
                                break
                            default:
                                Icon = Filter1Icon
                        }

                        return (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={visitor.name} 
                                    secondary={visitor.organization} 
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
        </Grid>
    </Paper>
  );
}