import { SET_AUTHENTICATED_USER, REMOVE_AUTHENTICATED_USER } from '../Actions/types';

const initialState = {
  user: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
        return {
          ...state,
          user:action.payload
      };
    case REMOVE_AUTHENTICATED_USER:
      return{
        ...state,
        user: null
      }
    default:
      return state;
  }
}

export default reducer