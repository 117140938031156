import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
      // indexAxis: 'y',
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Top Ten Presentations'
        }
      }
};

export default function Reporting(props) {
const [ filteredReport, setFilteredReport] = useState([])

    useEffect(() => {

            let presentations = []
            let presentationClickCount = {}
    
            props.report.map((datum) => {

              let labelString
              if(datum.resource_name.length > 30){
                labelString = datum.resource_name.substring(0,27) + '...'
              } else {
                labelString = datum.resource_name
              }

                    if(!presentations.includes(labelString)){
                        presentations.push(labelString)
                    }

                    if (!Object.keys(presentationClickCount).includes(labelString)){
                        presentationClickCount[labelString] = 1;
                    } else {
                        presentationClickCount[labelString]++
                    }
    
                return true
            })

            let sortedPresentationClickCount = Object.entries(presentationClickCount)
            .sort(([,a],[,b]) => b - a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});


            let labels = Object.keys(sortedPresentationClickCount).slice(0, 10)
            let data = Object.values(sortedPresentationClickCount).slice(0, 10)

            setFilteredReport({
                labels,
                datasets: [
                    {
                      label: 'Total Clicks',
                      data,
                      fill: true,
                      backgroundColor: '#6f1a1d',
                      borderColor: '#6f1a1d',
                    },
                ]
        })
    }, [props.report])


  return props.loading ? <CircularProgress /> : <Bar data={filteredReport} options={options} />
}
