import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AssetInfo } from "./AbstractOwnerInfo";
import { AssetGridActionButtons } from "./AbstractOwnerGridActionButtons";
import { assetGridOptions } from "./AbstractOwnerGridOptions";
import { DataTable } from "../Shared/DataTable";

// Configure Redux
import { connect } from "react-redux";

const useStyles = (theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
});

class AssetsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      editing: false,
      currentAbstract: null,
      loaded: false,
      show: null,
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getAssets();
      await this.getShow();
    }
  }

  async getAssets() {
    try {
      const data = await Auth.currentSession();

      let abstractIds = [];

      // get all abstracts connected to this email
      let myAssets = await axios({
        method: "get",
        url:
          "/abstracts/email/" +
          this.props.user.user.email +
          "/" +
          this.props.event.event.show_id,
        headers: { idtoken: data.idToken.jwtToken },
      });

      // remove duplicates (ie email is multiple roles from [presenter, author, submitter])
      let unique = await [
        ...new Map(
          myAssets.data.map((item) => [item["abstract_id"], item])
        ).values(),
      ];

      // set accepted_type if null, push id to abstractIds array
      for (let x = 0; x < unique.length; x++) {
        if (!unique[x].accepted_type) {
          unique[x].accepted_type = "N/A";
        }
        abstractIds.push(unique[x].abstract_id);
      }

      // get all abstracts for this event
      let allAssets = await axios({
        method: "get",
        url: "/abstracts/" + this.props.event.event.show_id,
        headers: { idtoken: data.idToken.jwtToken },
      });

      // go through each item and remove them from array
      // if they do not match an id in abstractIds
      for (var i = 0; i < allAssets.data.data.length; i++) {
        var obj = allAssets.data.data[i];

        if (abstractIds.indexOf(obj.abstract_id) === -1) {
          allAssets.data.data.splice(i, 1);
          i--;
        }
      }
      // console.log(allAssets.data.data);

      await this.setState({
        assets: allAssets.data.data,
        loaded: true,
      });
    } catch (error) {
      console.log(error);
      await this.setState({ assets: [] });
    }
  }

  async getShow() {
    const data = await Auth.currentSession();

    let myShow = await axios({
      method: "get",
      url: `/shows/${this.props.event.event.show_id}`,
      headers: { idtoken: data.idToken.jwtToken },
    });
    myShow = myShow.data[0];
    await this.setState({ show: myShow });
  }

  async handleEditingChange(e) {
    this.setState({
      editing: e,
    });
    if (!e) {
      this.getAssets();
    }
  }

  setCurrentAbstract(e) {
    this.setState({
      currentAbstract: e,
    });
  }

  handleEditClose() {
    this.setState({
      editing: false,
      currentAbstract: null,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentAbstract: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You have no submitted abstracts for this event
        </Typography>
      </Grid>
    );

    if (this.state.assets.length === 0) {
      if (this.state.loaded) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = loadingGraphic;
      }
    } else {
      bodyContent = (
        <Paper className={classes.paper}>
          <DataTable
            initialGridOptions={assetGridOptions}
            initialRowData={this.state.assets}
            actionButtons={AssetGridActionButtons}
            type="abstract"
            setEditing={(e) => this.handleEditingChange(e)}
            setCurrentPage={(e) => this.setCurrentAbstract(e)}
          />
        </Paper>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={3}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditingChange(false)}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    My Abstracts
                  </Typography>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </Paper>
            {this.state.editing ? (
              <AssetInfo
                user={this.props.user.user}
                currentAbstract={this.state.currentAbstract}
                getAssets={this.getAssets.bind(this)}
                handleEditClose={this.handleEditClose.bind(this)}
                sidebar={this.props.sidebar}
                event={this.props.event.event}
                show={this.state.show}
              />
            ) : (
              <React.Fragment>{bodyContent}</React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
  };
}
export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(AssetsList));
