import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../Components/Navbar';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '70%',
        },
    },
    headings: {
        padding: theme.spacing(3),
    },
    input: {
        display: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '80%'
    },
    formControlButton: {
        margin: theme.spacing(2),
        minWidth: 120,
        width: '50%'
    },
    button: {
        color: "white", 
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
    }
});


class ConfirmationCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            confirmationCode: '',
            passwordResetRequest: false,
            passwordReset: false,
            newPassword: '',
            errorMessage: '',
            error: false,
        }
    }

    handleChange(event, inputIdentifier) {
        this.setState({ [inputIdentifier]: event.target.value });
    }

    requestPasswordReset = () => {
        if (this.state.username){
        Auth.forgotPassword(this.state.username.toLowerCase())
        .then((data) => {
            this.setState({
                passwordResetRequest: true,
                error: false
            })
        })
        .catch((err) => {
            console.log(err)

            if(err.code === 'InvalidParameterException'){
                this.setState({
                    errorMessage: "Email must be verified in order to reset password. Please locate the link sent to your email address to verify. If problem persists, please contact support. ",
                    error: true
                })
            } else {
                this.setState({
                    errorMessage: "Unknown Email",
                    error: true
                })
            }

        });
        } else {
            this.setState({
                errorMessage: "Enter a Email",
                error: true
            })
        }
    }


    passwordReset = () => {
        Auth.forgotPasswordSubmit(this.state.username.toLowerCase(), this.state.confirmationCode, this.state.newPassword)
        .then((data) => {
            this.setState({
                passwordReset: true
            })
        })
        .catch(err => console.log(err));
    }

    render() {
        const { classes } = this.props;

        if(this.state.passwordReset){
            return (
                <div>
                <NavBar />
                <div className={classes.grid}>
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} spacing={0} justify="center">
                        <Grid item xs={12} sm={3} md={3} >
                            <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>
                                    Your password has been reset
                                </Typography>
                                <FormControl className={classes.formControlButton}>
                                    <Button component={Link} to={'/signin'} className={classes.button}>
                                        Sign In
                                    </Button>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
            )
        }
        else if (this.state.passwordResetRequest){
            return(
                <div>
                <NavBar />
                <div className={classes.grid}>
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} spacing={0} justify="center">
                        <Grid item xs={12} sm={3} md={3} >
                            <Paper className={classes.paper}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        onChange={(event) => this.handleChange(event, "username")}
                                        value={this.state.username}
                                        label="Email"
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        onChange={(event) => this.handleChange(event, "confirmationCode")}
                                        value={this.state.confirmationCode}
                                        label="Confirmation Code"
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        onChange={(event) => this.handleChange(event, "newPassword")}
                                        value={this.state.newPassword}
                                        type = 'password'
                                        label="New Password"
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlButton}>
                                    <Button variant="contained" onClick={this.passwordReset} className={classes.button}>
                                        Confirm New Password
                                    </Button>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
            )
        } else {
        return (
            <div>
                <NavBar />
                <div className={classes.grid}>
                    <Grid style={{ paddingTop: "85px" }} container layout={"row"} spacing={0} justify="center">
                        <Grid item xs={12} sm={3} md={3} >
                            <Paper className={classes.paper}>
                                <Typography variant="h6" className={classes.title}>
                                    Reset Your Password
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        onChange={(event) => this.handleChange(event, "username")}
                                        value={this.state.username}
                                        label="Email"
                                        error = {this.state.error}
                                        helperText={this.state.errorMessage}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlButton}>
                                    <Button className={classes.button} variant="contained" onClick={this.requestPasswordReset}>
                                        Reset
                                    </Button>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
            )
        }
    }
}

export default withStyles(useStyles)(ConfirmationCode);