import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import Router from "./router";

// Configure Theme
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

// Configure Redux
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./Store/Reducers/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import "react-dropzone-uploader/dist/styles.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "fontsource-roboto";

// Configure Amplify
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";

// Configure Stripe
import "@stripe/stripe-js";

// Main configuration
import { endpoint } from "./config";

const persistConfig = {
  key: "user",
  storage: storage,
  whitelist: ["user", "sidebar", "event"], // which reducer want to store
};

const pReducer = persistReducer(persistConfig, reducers);
const middleware = applyMiddleware(thunk);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

aws_exports.oauth.redirectSignIn =
  typeof window !== "undefined"
    ? window.location.origin + "/signin"
    : "http://localhost:3000/";
aws_exports.oauth.redirectSignOut =
  typeof window !== "undefined"
    ? window.location.origin + "/signin"
    : "http://localhost:3000/";

// catch users not on VPN when trying to SSO and redirect them
let windowHref = { url: window.location.href };
if (windowHref.url) {
  const query = new URLSearchParams(windowHref.url);
  for (let param of query.entries()) {
    if (param[0] === "error") {
      // second check may not be necessary but helps be explicit to SSO error
      if (param[1] === "access_denied") {
        alert(
          "You must be connected to the Company VPN. Please connect and try again."
        );
        window.location.href = window.location.origin + "/signin";
      }
    }
  }
}

Amplify.configure(aws_exports);

const theme = createTheme({
  palette: {
    primary: {
      main: "#2b377c",
      light: "#cbcddb",
      dark: "#171e43",
    },
    secondary: {
      main: "#6f1a1d",
      light: "#b92d30",
    },
    tertiary: {
      main: "#2e8fb5",
      light: "#5ab3d5",
      dark: "#1e5f78",
    },
    background: {
      main: "#ebebeb",
      dark: "#828282",
    },
  },
});

// original theme as of 23/02/08
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#6f1a1d",
//       light: "#b92d30",
//     },
//     secondary: {
//       main: "#2b377c",
//       light: "#cbcddb",
//     },
//     background: {
//       main: "#ebebeb",
//       dark: "#828282"
//     },
//   },
// });

// Configure Stripe
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// Configure Axios
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:5000";
} else if (process.env.REACT_APP_ENVIRONMENT === "dev") {
  axios.defaults.baseURL = "http://dev.planetconnect.com";
} else {
  axios.defaults.baseURL = endpoint;
}

axios.defaults.headers.common["Authorization"] = "AUTH TOKEN";
axios.defaults.headers.post["Content-Type"] = "application/json";

// GLobal Catch for All Outgoing Requests
axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log("interceptor error: OUTGOING", error);
    return Promise.reject(error);
  }
);

// Global Catch for All Incoming Responses
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("interceptor error: INCOMING", error);
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <Elements stripe={stripePromise}> */}
      {/* <Elements stripe={stripePromise} options={options}> */}
      <ThemeProvider theme={theme}>
        <Router />
        {/* <CheckoutForm /> */}
      </ThemeProvider>
      {/* </Elements> */}
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
