import { SET_EVENT_ID } from './types';
// import { SET_AUTHENTICATED_USER } from './types';

export default function setEvent(eventId) {
  return dispatch => {
    dispatch(setEventAsync(eventId))
    }
}

function setEventAsync(eventId){
  return {
    type: SET_EVENT_ID,
    payload: eventId
  };
}

// function setAuthenticatedUserAsync(user){
//   return {
//     type: SET_AUTHENTICATED_USER,
//     payload: user
//   };
// }