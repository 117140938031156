import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutFormStaffPass";

import axios from "axios";

import { stripeTest, stripeLive } from "../../config";

// swap between stripeTest and stripeLive for testing in development vs actually taking payment in production
// const stripePromise = loadStripe(stripeTest); //TESTING
const stripePromise = loadStripe(stripeLive);

export default function CheckoutFormContainer(props) {
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    // Create PaymentIntent when container renders
    const getClientSecret = async () => {
      const clientSecret = await axios("/payments/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: {
          items: props.items,
          metadata: {
            showId: props.showId,
            accountId: props.accountId,
            exhibitionId: props.exhibitionId,
            items: JSON.stringify(props.items),
          },
        },
      });
      setClientSecret(clientSecret.data.clientSecret);
    };
    getClientSecret();
  }, [props]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  const exhibitData = {
    items: props.items,
    showId: props.showId,
    accountId: props.accountId,
    exhibitionId: props.exhibitionId,
    availableStaffVirtual: props.availableStaffVirtual,
    availableStaffPhysical: props.availableStaffPhysical,
    usedStaffVirtual: props.usedStaffVirtual,
    usedStaffPhysical: props.usedStaffPhysical,
    exhibit: props.exhibit,
    showName: props.showName,
    contactName: props.contactName,
  };

  return (
    <div className="CheckoutFormContainer">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm props={exhibitData} />
        </Elements>
      )}
    </div>
  );
}
