import { combineReducers } from "redux";
import SidebarReducer from "./sidebar";
import UserReducer from "./user";
import EventReducer from "./event";
import ArchivedReducer from "./archived";

const rootReducer = combineReducers({
  sidebar: SidebarReducer,
  user: UserReducer,
  event: EventReducer,
  archived: ArchivedReducer,
});

export default rootReducer;
