import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { AssetInfo } from "./AssetInfo";
import { AssetGridActionButtons } from "./AssetGridActionButtons";
import { assetGridOptions } from "./assetGridOptions";
import { DataTable } from "../Shared/DataTable";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
});

class AssetsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      editing: false,
      currentAbstract: null,
      loaded: false,
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      this.getAssets();
    }
  }

  getAssets() {
    try {
      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url:
            "/abstracts/" +
            this.props.event.event.show_id +
            "/" +
            this.props.event.event.exhibition_id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            this.setState({
              assets: response.data.data,
              loaded: true,
            });
          })
          .catch((error) => {
            console.log(error);

            this.setState({
              assets: [],
            });
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  handleEditingChange(e) {
    this.setState({
      editing: e,
    });
  }

  setCurrentAbstract(e) {
    this.setState({
      currentAbstract: e,
    });
  }

  handleEditClose() {
    this.setState({
      editing: false,
      currentAbstract: null,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentAbstract: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You have no submitted abstracts for this event
        </Typography>
      </Grid>
    );

    if (this.state.assets.length === 0) {
      if (this.state.loaded) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = loadingGraphic;
      }
    } else {
      bodyContent = (
        <Paper className={classes.paper}>
          <DataTable
            initialGridOptions={assetGridOptions}
            initialRowData={this.state.assets}
            actionButtons={AssetGridActionButtons}
            type="abstract"
            setEditing={(e) => this.handleEditingChange(e)}
            setCurrentPage={(e) => this.setCurrentAbstract(e)}
          />
        </Paper>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={2}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditingChange(false)}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    Your Activities
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    An activity may be talk, presentation or poster. To link an
                    activity to your booth, please add the presenter of that
                    activity as a staff for your booth. Those that have been
                    submitted and accepted will display here. If you have any
                    questions or need help, please reach out to support in the
                    lower left.
                  </Typography>
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </Paper>
            {this.state.editing ? (
              <AssetInfo
                currentAbstract={this.state.currentAbstract}
                getAssets={this.getAssets.bind(this)}
                handleEditClose={this.handleEditClose.bind(this)}
                sidebar={this.props.sidebar}
              />
            ) : (
              <React.Fragment>{bodyContent}</React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(AssetsList));
