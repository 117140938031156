import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { Auth } from "aws-amplify";
import { allRoles } from "../../config";
import findCommonElement from "../../Utilities/arrayComparison";

import moment from "moment";
import momentTimezone from "moment-timezone"; //eslint-disable-line

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import setEvent from "../../Store/Actions/setEvent";
import setUser from "../../Store/Actions/setUser";
import setArchived from "../../Store/Actions/setArchived";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-between",
    justifyContent: "center",
  },
  GridListTile: {
    padding: 0,
  },
  title: {
    textAlign: "center",
  },
  gridContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  tabsCentered: {
    display: "flex",
    justifyContent: "center",
  },
  editButton: {
    // display: 'flex',
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  selected: {
    padding: `${theme.spacing(1)}px ${theme.spacing(5)}px`,
    backgroundColor: "#f5f5f5",
    border: "solid 1px black",
    borderRadius: ".5em",
  },
  cardSelected: {
    background: theme.palette.primary.light,
  },
  button: {
    // marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  gridItem: {
    "& .MuiPaper-elevation8": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  tabSingle: {
    minWidth: "auto",
  },
  tabSelected: {
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});

class MyEventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: {
        past: [],
        future: [],
      },
      tabValue: 1,
      loaded: false,
    };
  }

  async componentDidMount() {
    // testing
    // try {
    //   ForceLogOut(this.props.removeEvent, this.props.removeAuthenticatedUser);
    // } catch (error) {
    //   console.log(error);
    // }
    // this test SUCCEEDS
    try {
      const user = await Auth.currentSession();

      const usersShows = await axios({
        method: "get",
        url: `/shows/account/${this.props.user.user.email}`,
        headers: { idtoken: user.idToken.jwtToken },
      });

      async function transformShowsArray(shows) {
        let eventsArray = [];

        let prunedDataByRole = [];

        for (let n = 0; n < shows.data.data.length; n++) {
          if (
            findCommonElement(shows.data.data[n].roles.split(", "), allRoles)
          ) {
            prunedDataByRole.push(shows.data.data[n]);
          }
        }

        for (let i = 0; i < prunedDataByRole.length; i++) {
          let index = eventsArray
            .map((e) => e.showId)
            .indexOf(prunedDataByRole[i].show_id);

          if (index >= 0) {
            eventsArray[index].exhibitions.push(prunedDataByRole[i]);
          } else {
            let newShow = {
              showId: `${prunedDataByRole[i].show_id}`,
              showName: `${prunedDataByRole[i].show_name}`,
              exhibitions: [prunedDataByRole[i]],
              startDate: `${prunedDataByRole[i].start_date}`,
              endDate: `${prunedDataByRole[i].end_date}`,
            };

            eventsArray.push(newShow);
          }
        }
        const sortedEvents = { past: [], future: [] };

        eventsArray = eventsArray.sort((a, b) => {
          if (a.startDate > b.startDate) {
            return 1;
          }
          if (a.startDate < b.startDate) {
            return -1;
          }
          return 0;
        });

        eventsArray.forEach((event) => {
          let lastDay = moment(event.endDate).format("YYYY-MM-DD");
          let today = moment().format("YYYY-MM-DD");
          if (lastDay < today) {
            // event is in the past
            sortedEvents.past.push(event);
          } else {
            // event is in the future
            sortedEvents.future.push(event);
          }
        });

        return sortedEvents;
      }

      const finalShowsArray = await transformShowsArray(usersShows);
      let archiveOrCurrent = 1;
      if (this.props.event.event) {
        let selectedEventEndDate = moment(
          this.props.event.event.end_date
        ).format("YYYY-MM-DD");
        let today = moment().format("YYYY-MM-DD");

        if (selectedEventEndDate < today) {
          archiveOrCurrent = 0;
        }
      }

      this.setState({
        events: finalShowsArray,
        tabValue: archiveOrCurrent,
        loaded: true,
      });

      //end
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  selectEvent(event) {
    this.props.setEvent(event);
    this.props.setUser(event);

    let lastDay = moment(event.end_date).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    if (lastDay < today) {
      // event is in the past
      this.props.setArchived(true);
    } else {
      // event is in the future
      this.props.setArchived(false);
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    const { classes } = this.props;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );

    if (!this.state.loaded) {
      return (
        <Grid
          container
          className={classes.gridContainer}
          layout={"row"}
          alignItems="stretch"
          spacing={2}
        >
          {loadingGraphic}
        </Grid>
      );
    }

    if (
      this.state.events.past.length === 0 &&
      this.state.events.future.length === 0
    ) {
      return (
        <Grid
          container
          className={classes.gridContainer}
          layout={"row"}
          alignItems="stretch"
          spacing={2}
        >
          <Typography variant="h6" color="primary">
            You do not have the required roles to view/edit any event
            parameters. Please reach out to your event admin or the
            PlanetConnect support team.
          </Typography>
        </Grid>
      );
    }

    let pastFutureKeyword = "future";
    if (this.state.tabValue === 0) {
      pastFutureKeyword = "past";
    }

    return (
      <Grid
        container
        className={classes.gridContainer}
        layout={"row"}
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} className={classes.tabsCentered}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tooltip
              title={
                <Typography variant="subtitle2">
                  Display events that have passed
                </Typography>
              }
            >
              <Tab
                className={
                  (classes.tabSingle,
                  pastFutureKeyword === "past" && classes.tabSelected)
                }
                label={"Archived Events"}
              />
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="subtitle2">
                  Display events in the future
                </Typography>
              }
            >
              <Tab
                className={
                  (classes.tabSingle,
                  pastFutureKeyword === "future" && classes.tabSelected)
                }
                label={"Current Events"}
              />
            </Tooltip>
          </Tabs>
        </Grid>
        {pastFutureKeyword === "past" && (
          <Grid item xs={12}>
            <Typography variant="body1" color="textPrimary" align="center">
              Archived events are only viewable.
            </Typography>
          </Grid>
        )}
        {this.state.events[pastFutureKeyword].map((event, index) => {
          let select;
          let raised;
          let archived = pastFutureKeyword === "past";

          if (this.props.event.event === null) {
            // if (event.exhibitions.length === 1){
            select = (
              <Button
                className={classes.editButton}
                variant="outlined"
                onClick={() => this.selectEvent(event.exhibitions[0])}
              >
                Edit This Event
              </Button>
            );
          } else if (this.props.event.event.show_id === event.showId) {
            select = (
              <Typography
                className={`${classes.editButton} ${classes.selected}`}
                variant="h6"
                color="primary"
                onClick={() => this.selectEvent(event.exhibitions[0])}
              >
                Currently Selected
              </Typography>
            );
            raised = true;
          } else {
            let lastDay = moment(event.endDate).format("YYYY-MM-DD");
            let today = moment().format("YYYY-MM-DD");
            if (lastDay < today) {
              // event is in the past
              select = (
                <Button
                  className={classes.editButton}
                  variant="outlined"
                  onClick={() => this.selectEvent(event.exhibitions[0])}
                >
                  View This Event
                </Button>
              );
            } else {
              // event is in the future
              select = (
                <Button
                  className={classes.editButton}
                  variant="outlined"
                  onClick={() => this.selectEvent(event.exhibitions[0])}
                >
                  Edit This Event
                </Button>
              );
            }
          }

          let date;
          let venue;
          let environmentUrl;

          // .add(1, "d") is added because dates were displaying wrong in the admin panel
          if (
            moment(event.startDate).format("LL") ===
            moment(event.endDate).format("LL")
          ) {
            date = (
              <>
                <b>Date:</b>{" "}
                {/* {moment(event.startDate).tz("America/New_York").format("LL")}{" "} */}
                {moment(event.startDate).add(1, "d").format("MMMM D, YYYY")}{" "}
                <br />
              </>
            );
          } else {
            date = (
              <>
                <b>Start Date:</b>{" "}
                {/* {moment(event.startDate).tz("America/New_York").format("LL")} */}
                {moment(event.startDate).add(1, "d").format("MMMM D, YYYY")}
                <br />
                <b>End Date:</b>{" "}
                {/* {moment(event.endDate).tz("America/New_York").format("LL")} */}
                {moment(event.endDate).add(1, "d").format("MMMM D, YYYY")}
                <br />
              </>
            );
          }
          if (event.exhibitions[0].facility) {
            venue = (
              <>
                <b>Venue:</b> {event.exhibitions[0].facility}
                <br />
              </>
            );
          }
          if (event.exhibitions[0].virtual_environment_website) {
            if (!archived) {
              environmentUrl = (
                <>
                  <b>URL:</b> {event.exhibitions[0].virtual_environment_website}{" "}
                  <br />
                </>
              );
            }
          }

          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              className={classes.gridItem}
            >
              <Card
                className={
                  raised
                    ? `${classes.root} ${classes.cardSelected}`
                    : classes.root
                }
                raised={raised}
              >
                <CardContent style={{ width: "100%" }}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                  >
                    {event.showName} <br />
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography variant="body1" color="textPrimary">
                    {date}
                    {venue}
                    {environmentUrl}
                  </Typography>
                </CardContent>

                <CardActions className={classes.button}>{select}</CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    archived: state.archived,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEvent: setEvent,
      setUser: setUser,
      setArchived: setArchived,
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(MyEventsList));
