/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link } from "react-router-dom";

export function PollsGridActionButtons(value, key, onEdit, onDelete) {
  let buttonStyle = {
    marginBottom: "8px",
    padding: "8px",
    borderRadius: "4px",
  };
  return (
    <ButtonGroup
      key={key}
      variant="contained"
      disableElevation={true}
      color="primary"
      size="small"
      fullWidth
      orientation="vertical"
    >
      <Button color="primary" onClick={onEdit} fullWidth style={buttonStyle}>
        Edit
      </Button>
      <Button
        color="secondary"
        onClick={onDelete}
        fullWidth
        style={buttonStyle}
      >
        Delete
      </Button>
    </ButtonGroup>
  );
}
