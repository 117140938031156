import { UrlToButton } from '../Shared/UrlToButton';
import { BooleanToString } from '../Shared/BooleanToString';
import { ResourceGridActionButtons } from './ResourceGridActionButtons';

export const resourceGridOptions = {
    frameworkComponents: {
        urlToButtonCellRenderer: UrlToButton,
        booleanToStringCellRenderer: BooleanToString,
        actionButtonsCellRenderer: ResourceGridActionButtons
    },
    columnDefs: [
        {
            headerName: 'Resource Name',
            field: 'name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                closeOnApply: true,
                suppressAndOrCondition: true
            },
            sortable: true
        },
        {
            headerName: 'Resource Type',
            field: 'type',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                closeOnApply: true,
                suppressAndOrCondition: true
            },
            sortable: true

        },
        {
            headerName: 'URL',
            field: 'url',
            cellRenderer: 'urlToButtonCellRenderer'
        },
        {
            headerName: 'Is Public?',
            field: 'is_public',
            cellRenderer: 'booleanToStringCellRenderer',
            sortable: true
        },
        {
            headerName: 'Actions',
            field: 'resource_id',
            cellRenderer: 'actionButtonsCellRenderer'
        },

    ],
    // other grid options here...
}