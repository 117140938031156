import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import {
  acceptedDeclinedGridOptions,
  prospectiveGridOptions,
  recentlyAcceptedGridOptions,
  recentlyDeclinedGridOptions,
  singleLevelGridOptions,
} from "./hostGridOptions";
import { DataTable } from "../Shared/DataTable";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";
// moment
import moment from "moment";
// import momentTimezone from 'moment-timezone';

const useStyles = (theme) => ({
  // root: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  // },
  paperHeader: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    // marginBottom: theme.spacing(1),
  },
  clearFilter: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
  },
});

class HostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleLevel: false,
      currentLevel: false,
      acceptedSponsorships: [],
      declinedSponsorships: [],
      prospectivePartners: [],
      recentlyAcceptedSponsors: [],
      recentlyDeclinedSponsors: [],
      currentLevelSponsorships: [],
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      // this.getEvent()
      this.getAcceptedSponsorships();
      this.getDeclinedSponsorships();
      this.getProspectiveSponsorshipPartnersByStage();
      this.getRecentlyAcceptedSponsorshipPartners();
      this.getRecentlyDeclinedSponsorshipPartners();
    }
  }

  getSponsorshipsByLevelType(level, type) {
    try {
      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url: `/shows/sponsorships/${this.props.event.event.show_id}/${type}?filter_column=level_requested&filter_value=${level}`, // this.props.user.user.Account_Name.id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            for (let x = 0; x < response.data.data.length; x++) {
              let sponsorship = response.data.data[x];
              sponsorship.modified_time = moment(
                sponsorship.modified_time,
                "YYYY/MM/DD HH:mm a"
              ).format("YYYY/MM/DD hh:mm a");
            }

            this.setState({
              currentLevelSponsorships: response.data.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  getAcceptedSponsorships() {
    // events/sponsorships/' + this.props.event.event.show_id + '/accepted

    // gives all 16

    //  events/sponsorships/' + this.props.event.event.show_id + '/accepted?filter_column=level_requested&filter_value=Booth

    // gives a filtered set

    Auth.currentSession().then((data) => {
      axios({
        method: "get",
        url:
          "/shows/sponsorships/" +
          this.props.event.event.show_id +
          "/accepted/levels", // this.props.user.user.Account_Name.id,
        headers: { idtoken: data.idToken.jwtToken },
        // params: {
        //     order_by: 'level_requested',
        //     order_dir: 'asc',
        //   },
      })
        .then((response) => {
          // console.log('Accepted Sponsorships')
          // console.log(response)
          this.setState({
            acceptedSponsorships: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getDeclinedSponsorships() {
    Auth.currentSession().then((data) => {
      axios({
        method: "get",
        url:
          "/shows/sponsorships/" +
          this.props.event.event.show_id +
          "/declined/levels", // this.props.user.user.Account_Name.id,
        headers: { idtoken: data.idToken.jwtToken },
        // params: {
        //     order_by: 'level_requested',
        //     order_dir: 'asc',
        //   },
      })
        .then((response) => {
          // console.log('Declined Sponsorships')
          // console.log(response)
          this.setState({
            declinedSponsorships: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getProspectiveSponsorshipPartnersByStage() {
    Auth.currentSession().then((data) => {
      axios({
        method: "get",
        url:
          "/shows/sponsorships/" + this.props.event.event.show_id + "/stages", // this.props.user.user.Account_Name.id,
        headers: { idtoken: data.idToken.jwtToken },
      })
        .then((response) => {
          // console.log('Prospective Sponsorship Partners By Stage')
          // console.log(response)
          this.setState({
            prospectivePartners: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getRecentlyAcceptedSponsorshipPartners() {
    //Recently Accepted Sponsorship Partners (Last 5 Days)

    Auth.currentSession().then((data) => {
      axios({
        method: "get",
        url:
          "/shows/sponsorships/recent/" +
          this.props.event.event.show_id +
          "/accepted", // this.props.user.user.Account_Name.id,
        headers: { idtoken: data.idToken.jwtToken },
      })
        .then((response) => {
          // console.log('Recently Accepted Sponsorship Partners')
          // console.log(response)
          this.setState({
            recentlyAcceptedSponsors: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  getRecentlyDeclinedSponsorshipPartners() {
    //Recently Declined Sponsorship Partners (Last 5 Days)

    Auth.currentSession().then((data) => {
      axios({
        method: "get",
        url:
          "/shows/sponsorships/recent/" +
          this.props.event.event.show_id +
          "/declined", // this.props.user.user.Account_Name.id,
        headers: { idtoken: data.idToken.jwtToken },
      })
        .then((response) => {
          // console.log('Recently Declined Sponsorship Partners')
          // console.log(response)
          this.setState({
            recentlyDeclinedSponsors: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  setSingleLevel(e) {
    this.setState({
      singleLevel: e,
    });
  }

  setCurrentLevel(e, type) {
    let currentLevel = e.level_requested;
    this.setState({
      currentLevel: currentLevel,
    });
    this.getSponsorshipsByLevelType(currentLevel, type);
  }

  clearFilter() {
    this.setState({
      singleLevel: false,
      currentLevel: null,
      currentLevelSponsorships: [],
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        singleLevel: false,
        currentLevel: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let singleTable = (titleText, initialGridOptions, initialRowData, type) => (
      <Paper className={classes.paper}>
        <Typography className={classes.center} variant="body1">
          <b>{titleText}</b>
        </Typography>
        <DataTable
          initialGridOptions={initialGridOptions}
          initialRowData={initialRowData}
          // actionButtons={AssetGridActionButtons}
          type={type}
          setEditing={(e) => this.setSingleLevel(e)}
          setCurrentPage={(e) => this.setCurrentLevel(e, type)}
        />
        {!initialRowData.length ? (
          <Typography className={classes.center} variant="subtitle1">
            No Data Available
          </Typography>
        ) : null}
      </Paper>
    );

    let bodyContent;

    if (!this.state.acceptedSponsorships.length) {
      bodyContent = (
        <Typography className={classes.center} variant="h5">
          Loading sponsorships
        </Typography>
      );
    } else {
      if (this.state.singleLevel) {
        bodyContent = (
          <>
            <Button
              className={classes.clearFilter}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => this.clearFilter()}
            >
              <Typography variant="h6">Clear Filtered Results</Typography>
            </Button>
            {singleTable(
              `Accepted Sponsorship Partners by Level: ${this.state.currentLevel}`,
              singleLevelGridOptions,
              this.state.currentLevelSponsorships,
              ""
            )}
            {this.state.currentLevelSponsorships.length > 8 ? (
              <Button
                className={classes.clearFilter}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => this.clearFilter()}
              >
                <Typography variant="h6">Clear Filtered Results</Typography>
              </Button>
            ) : null}
          </>
        );
      } else {
        bodyContent = (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              {singleTable(
                "Accepted Sponsorship Partners by Level",
                acceptedDeclinedGridOptions,
                this.state.acceptedSponsorships,
                "accepted"
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {singleTable(
                "Declined Sponsorship Partners by Level",
                acceptedDeclinedGridOptions,
                this.state.declinedSponsorships,
                "declined"
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {singleTable(
                "Prospective Sponsorship Partners by Stage",
                prospectiveGridOptions,
                this.state.prospectivePartners,
                "prospective"
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {singleTable(
                "Recently Accepted Sponsorship Partners (Last 5 Days)",
                recentlyAcceptedGridOptions,
                this.state.recentlyAcceptedSponsors,
                "recentely_accepted"
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {singleTable(
                "Recently Declined Sponsorship Partners (Last 5 Days)",
                recentlyDeclinedGridOptions,
                this.state.recentlyDeclinedSponsors,
                "recently_declined"
              )}
            </Grid>
          </Grid>
        );
      }
    }
    // console.log(this.state);
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paperHeader}>
              <Typography
                className={classes.center}
                variant="h4"
                color="textPrimary"
              >
                Host Dashboard
              </Typography>
            </Paper>
            {bodyContent}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(HostList));
