import React, { useState, useEffect } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// import Switch from '@material-ui/core/Switch';
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] !== "string") {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1;
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function DataTable({
  initialGridOptions,
  initialRowData,
  actionButtons,
  type,
  setEditing,
  setCurrentPage,
  setDeleting,
  eventIsArchived,
  user,
}) {
  const [
    gridOptions,
    // setGridOptions
  ] = useState(() => initialGridOptions);
  const [rowData, setRowData] = useState(() => initialRowData);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  // const [selected, setSelected] = React.useState([]);
  const [
    page,
    // setPage
  ] = React.useState(0);
  const [
    rowsPerPage,
    // setRowsPerPage
  ] = React.useState(1000);

  useEffect(() => {
    setRowData(initialRowData);
  }, [initialRowData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    // console.log('isAsc', isAsc)
    // console.log('orderBy', orderBy)
    // console.log(orderBy)
    // console.log('order', order)
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // console.log('isAsc', isAsc)
  };

  function handleEditClick(id, infoObject) {
    setEditing(true);
    setCurrentPage(infoObject);

    // return setCurrentPage(null);
  }

  function handleDeleteClick(id, infoObject) {
    setDeleting(true);
    setCurrentPage(infoObject);

    // return setCurrentPage(null);
  }

  function EnhancedTableHead(props) {
    const {
      // classes, onSelectAllClick,
      order,
      orderBy,
      // numSelected, rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {gridOptions.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnchancedTableRows(props) {
    const { id, row, index, tabIndex, type, handleEditClick } = props;
    let currentRowKeys = Object.keys(row);
    let currentRowValues = Object.values(row);
    let typeId = `${type}_id`;
    return (
      <TableRow hover tabIndex={tabIndex} key={`${type}.${id}`}>
        {gridOptions.map((gridOption) => {
          let isExhibitor = false;
          if (user) {
            isExhibitor = user.account_id !== currentRowValues[10];
          }
          // index 10 is account_id
          return (
            <TableCell
              key={`${id}.${gridOption.id}.${index}`}
              align="left"
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "normal",
                background: isExhibitor && "#dfeee7",
              }}
            >
              {currentRowKeys.map((key, index) => {
                let keyId = `${key}.${id}`;
                let value;
                let valueAsString;
                if (key === gridOption.id && key !== typeId) {
                  switch (key) {
                    case "website":
                      value = (
                        <a
                          href={currentRowValues[index]}
                          alt="Our Website"
                          key={keyId}
                        >
                          {currentRowValues[index]}
                        </a>
                      );
                      break;
                    case "video_url":
                      value = (
                        <a
                          href={currentRowValues[index]}
                          alt="Our Video"
                          key={keyId}
                        >
                          {currentRowValues[index]}
                        </a>
                      );
                      break;
                    case "photo_url":
                      value = (
                        <Avatar
                          alt={null}
                          src={currentRowValues[index]}
                          key={keyId}
                        />
                      );
                      break;
                    case "types":
                      function capitalizeFirstLetter(string) {
                        return string.charAt(0).toUpperCase() + string.slice(1);
                      }
                      if (currentRowValues[index]?.includes("[")) {
                        valueAsString = JSON.parse(
                          `${currentRowValues[index]}`
                        );
                        let newValue = "";
                        valueAsString.forEach((item, i) => {
                          let newItem = capitalizeFirstLetter(item);
                          if (i === 0) {
                            newValue = newItem;
                          } else {
                            newValue = newValue + `, ${newItem}`;
                          }
                        });
                        valueAsString = newValue;
                      } else {
                        valueAsString = currentRowValues[index];
                      }

                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    case "is_public":
                      if (currentRowValues[index]) {
                        valueAsString = "Yes";
                      } else {
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    case "liked":
                      if (currentRowValues[index] === "True") {
                        valueAsString = "Yes";
                      } else {
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    case "account_id":
                      // Is Exhibitor
                      // if showname includes name of company, they are not exhibitor
                      if (isExhibitor) {
                        valueAsString = "Yes";
                      } else {
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    // formerly used but was a less efficient method
                    // case "account_name":
                    //   // Is Exhibitor
                    //   // if showname includes name of company, they are not exhibitor
                    //   if (user.show_name.includes(currentRowValues[index])) {
                    //     valueAsString = "No";
                    //   } else {
                    //     valueAsString = "Yes";
                    //   }
                    //   value = (
                    //     <Typography
                    //       variant="body2"
                    //       key={keyId}
                    //       style={{
                    //         textTransform: "capitalize",
                    //       }}
                    //     >
                    //       {valueAsString}
                    //     </Typography>
                    //   );
                    //   break;
                    case "booth_visible":
                      if (currentRowValues[index]) {
                        valueAsString = "Yes";
                      } else {
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    case "cleared_for_public_view":
                      if (currentRowValues[index] === "Yes - Approved") {
                        currentRowValues[index] = true;
                        valueAsString = "Yes";
                      } else {
                        currentRowValues[index] = false;
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    case "count":
                      value = (
                        <Button
                          variant="contained"
                          disableElevation
                          alt="Count"
                          key={keyId}
                          onClick={() =>
                            handleEditClick(currentRowValues[index], row)
                          }
                          style={{ width: "80%" }}
                        >
                          {currentRowValues[index]}
                        </Button>
                      );
                      break;
                    case "is_published":
                      if (currentRowValues[index]) {
                        valueAsString = "Yes";
                      } else {
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    case "is_multiple":
                      if (currentRowValues[index]) {
                        valueAsString = "Yes";
                      } else {
                        valueAsString = "No";
                      }
                      value = (
                        <Typography
                          variant="body2"
                          key={keyId}
                          style={{ textTransform: "capitalize" }}
                        >
                          {valueAsString}
                        </Typography>
                      );
                      break;
                    default:
                      value = (
                        <Typography variant="body2" key={keyId}>
                          {currentRowValues[index]}
                        </Typography>
                      );
                      break;
                  }
                  return value;
                } else if (key === gridOption.id && key === typeId) {
                  value = actionButtons(
                    currentRowValues[index],
                    keyId,
                    () => handleEditClick(currentRowValues[index], row),
                    () => handleDeleteClick(currentRowValues[index], row),
                    eventIsArchived
                  );
                  return value;
                } else {
                  return null;
                }
              })}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  function TableData(props) {
    return (
      <TableContainer>
        <Table size="medium">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rowData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                // console.log(row.accepted_type)
                const abstractId = row.abstract_id;
                const productId = row.product_id;
                const attendeeId = row.attendee_id;
                let finalId;
                switch (type) {
                  case "abstract":
                    finalId = abstractId;
                    break;
                  case "product":
                    finalId = productId;
                    break;
                  case "attendee":
                    finalId = attendeeId;
                    break;
                  default:
                    finalId = "NotFound";
                }
                return (
                  <EnchancedTableRows
                    key={`${index}.${finalId}`}
                    index={index}
                    row={row}
                    tabIndex={-1}
                    id={finalId}
                    type={type}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return <TableData />;
}
