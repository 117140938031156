// import { UrlToButton } from '../Shared/UrlToButton';
// import { BooleanToString } from '../Shared/BooleanToString';
// import { ProductGridActionButtons } from './ProductGridActionButtons';

// export const productGridOptions = {
//     frameworkComponents: {
//         urlToButtonCellRenderer: UrlToButton,
//         booleanToStringCellRenderer: BooleanToString,
//         actionButtonsCellRenderer: ProductGridActionButtons
//     },
//     columnDefs: [
export const pollsGridOptions = [
  { id: "question", numeric: false, disablePadding: false, label: "Question" },
  {
    id: "is_published",
    numeric: false,
    disablePadding: false,
    label: "Is Published",
  },
  {
    id: "is_multiple",
    numeric: false,
    disablePadding: false,
    label: "Is Multiple",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  { id: "poll_id", numeric: true, disablePadding: false, label: "Actions" },
];
