import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Line } from 'react-chartjs-2';
import { useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';


const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Visits to Each Room Over Time'
      }
    }
  };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
      display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
},
center: {
    textAlign: 'center',
    // padding: theme.spacing(2),
},
}));

export default function Reporting(props) {
const classes = useStyles();
const event = useSelector((state) => state.event)
const [ report, setReport] = useState([])
const [ filteredReport, setFilteredReport] = useState([])
const [ timeInterval, setTimeInterval] = useState('Hourly')

    useEffect(() => {

            let daysCount = {}

            props.report.map((datum) => {

              if(datum.action === 'Viewed'){
                if(timeInterval === 'Hourly'){
                  if(!Object.keys(daysCount).includes(datum.resource_type)){
                      daysCount[datum.resource_type] = {} 
                      props.times.map((e) => {
                        daysCount[datum.resource_type][e] = 0
                        return null
                      })
                  }

                  daysCount[datum.resource_type][moment(datum.visit_time).format('MMM Do YYYY, hh:00 a')]++

                } else {
                  if(!Object.keys(daysCount).includes(datum.resource_type)){
                    daysCount[datum.resource_type] = {} 
                    props.timesDaily.map((e) => {
                      daysCount[datum.resource_type][e] = 0
                      return null
                    })
                }

                daysCount[datum.resource_type][moment(datum.visit_time).format('dddd, MMM Do YYYY')]++
                }
              }
                return null
            })

          let keysSummary = {}

          Object.keys(daysCount).map((type) => {

            keysSummary[type] = Object.values(daysCount[type]).reduce((a, b) => a + b)
            return null
          })

          let keysSorted = Object.keys(keysSummary).sort((a, b) => keysSummary[b] - keysSummary[a])

            const colors = [
              '#6F1A1D',
              '#055864',
              '#FB4D3D',
              '#50B2C0',
              '#FFEAAE',
              '#8CDEDC',
              '#94C5CC',
              '#86BA90',
              '#03CEA4',
              '#345995', 
              '#6F1A1D',
              '#055864',
              '#FB4D3D',
              '#50B2C0',
              '#FFEAAE',
              '#8CDEDC',
              '#94C5CC',
              '#86BA90',
              '#03CEA4',
              '#345995',
              '#6F1A1D',
              '#055864',
              '#FB4D3D',
              '#50B2C0',
              '#FFEAAE',
              '#8CDEDC',
              '#94C5CC',
              '#86BA90',
              '#03CEA4',
              '#345995'
            ]

            const datasets = keysSorted.map((resource, i) => {

              return ({
                  label: `${resource}`,
                  data: Object.values(daysCount[resource]),
                  fill: true,
                  backgroundColor: colors[i],
              })

            })

            setReport(report)
            setFilteredReport({
                labels: timeInterval === 'Hourly' ? props.times : props.timesDaily,
                datasets: datasets
                  // .slice(0,topInteger)
                  .reverse(),
            })

    }, [event.event.show_id, props.report, props.times, props.timesDaily, timeInterval, report])

    const timeSelect = (e) => {

        setTimeInterval(e)

      };

  return (
    <Paper className={classes.paper}>
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Click Explorer</InputLabel>
                <Select
                value={timeInterval}
                onChange={(e) => timeSelect(e.target.value)}
                >
                <MenuItem value={'Hourly'}>Hourly</MenuItem>
                <MenuItem value={'Daily'}>Daily</MenuItem>
            </Select>
        </FormControl>
        {props.loading ? <CircularProgress/> : <Line data={filteredReport} options={options} />}
    </Paper>
  );
}
