import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// import Box from '@material-ui/core/Box';
// import Chip from '@material-ui/core/Chip';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ColorPicker } from "../Shared/ColorPicker";
import Dialog from "../../Components/Dialog";
import { Link } from "react-router-dom";
import {
  Formik,
  Form,
  Field,
  useField,
  // FieldArray
} from "formik";
// import { Img } from 'react-image';
// import { FileUploader } from '../Shared/FileUploader';

// Configure Redux
import { connect } from "react-redux";

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    padding: theme.spacing(2),
    // marginBottom: theme.spacing(3),
  },
  submitGridItem: {
    position: "fixed",
    bottom: "16px",
    width: "100%",
    zIndex: 1,
  },
  submitButton: {
    color: "white",
    width: "70%",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    marginLeft: "10%",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
});

const CompanyInfoField = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <Field> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  return (
    <>
      <Field
        className="text-input"
        component={TextField}
        margin="dense"
        variant="outlined"
        fullWidth
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

// const CompanyTagField = ({ ...props }) => {
//     // const classes = useStyles();
//     // const [tags, setTags] = React.useState([]);
//     const [field, meta, helpers] = useField(props);
//     const { setValue } = helpers;

//     let tags = [];
//     tags = props.values.tags;

//     const handleOnKeyDown = (e) => {
//         if (e.keyCode === 13) {
//             props.values.tags = [...tags, e.target.value];
//             setValue('')
//         }
//     }

//     return (
//         <Box component="ul" style={{
//         display: 'flex',
//         flexWrap: 'wrap',
//         listStyle: 'none',
//         padding: '2px',
//         margin: 0,
//         }}>
//             <Field
//                 component={TextField}
//                 // type="text"
//                 margin="dense"
//                 variant="outlined"
//                 fullWidth
//                 onKeyDown={handleOnKeyDown}
//                 {...field} {...props}
//             />
//         </Box>
//     );
// }

// const CompanyChips = ({ ...props }) => {
//     // const classes = useStyles();
//     const [field, meta, helpers] = useField(props);
//     const { setValue } = helpers;

//     let tags = props.values.tags;

//     const handleDelete = (e) => {

//         tags = tags.filter((tag) => {
//             return tag !== e
//         })
//         props.values.tags = tags;
//         setValue(tags)

//     };

//     let tagsMapped
//     if (tags) {
//         tagsMapped = tags.map((data, index) => {
//             return (
//                 <li key={index}>
//                     <Chip
//                         label={data}
//                         style={{margin: '2px'}}
//                         color="primary"
//                         onDelete={() => handleDelete(data)}
//                     // onDelete={data === "React" ? undefined : handleDelete(data)} // working method from example: https://codesandbox.io/s/material-demo-forked-2uggu?file=/demo.js

//                     />
//                 </li>
//             );
//         }
//         )
//     } else {
//         tagsMapped = null;
//     }

//     return (
//         <Box component="ul" style={{
//         display: 'flex',
//         flexWrap: 'wrap',
//         listStyle: 'none',
//         padding: '2px',
//         margin: 0,
//         }}>
//             {tagsMapped}
//         </Box>)
// }

const CompanyColorPicker = ({ ...props }) => {
  const [
    field,
    // meta,
    helpers,
  ] = useField(props);
  const { setValue } = helpers;
  if (field.value === null) {
    field.value = "";
  }
  return (
    <React.Fragment>
      <Field
        component={TextField}
        // name={label.replace(/ +/g, "")}
        type="text"
        // label={label}
        // value={color}
        margin="dense"
        variant="outlined"
        fullWidth
        disabled
        {...field}
        {...props}
      />
      <ColorPicker
        color={field.value}
        onChange={(chosenColor) => {
          setValue(chosenColor.hex);
        }}
      />
    </React.Fragment>
  );
};

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      success: null,
      tagFieldFocused: false,
      file: null,
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      this.getCompany();
    }
  }
  async getCompany() {
    await this.setState({
      company: null,
    });

    Auth.currentSession()
    .then((data) => {
      axios({
        method: "get",
        url:
          `/exhibitions/` +
          this.props.event.event.show_id +
          "/1691992000000120532", //this.props.user.user.id
        // this.props.user.user.Account_Name.id,
        headers: { idtoken: data.idToken.jwtToken },
      })
        .then((response) => {
          // console.log(response.data.data);
          if (
            response.data.data[0] === undefined ||
            response.data.data[0].length === 0
          ) {
            return null;
          } else if (!response.data.data[0].linked_in_tags) {
            response.data.data[0].linked_in_tags = "";
          } else {
            response.data.data[0].linked_in_tags =
              response.data.data[0].linked_in_tags.split(",");
          }

          this.setState({
            company: response.data.data[0],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  tagFieldFocus = () => {
    this.setState({
      tagFieldFocused: true,
    });
  };

  tagFieldBlur = () => {
    this.setState({
      tagFieldFocused: false,
    });
  };

  onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  handleFileUpload = (file, meta, status) => {
    this.setState({ file });
  };

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    // let bodyContent;

    if (!this.state.company) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Typography className={classes.center} variant="h4">
                  Create New Event
                </Typography>
              </Paper>
              {/* <Typography className={classes.center} variant='h5' >
                            We have your account but your booth is not set up yet. 
                            <br/> 
                            Please reach out to PlanetConnect for assistance.                  
                        </Typography> */}
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Formik
          initialValues={{
            companyNameForBooth: this.state.company.account_name,
            companyUrl: this.state.company.website,
            sponsorshipLevel: this.state.company.level_requested,
            companyDescription: this.state.company.main_text,
            currentTag: "",
            tags: this.state.company.linked_in_tags,
            primaryColor: this.state.company.primary_color,
            secondaryColor: this.state.company.secondary_color,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await new Promise((r) => setTimeout(r, 500));

            const user = await Auth.currentSession();

            if (this.state.file) {
              let formData = new FormData();
              formData.append("file", this.state.file);

              const uploadFile = await axios({
                method: "POST",
                url: "/upload",
                headers: {
                  "Content-Type": "multipart/form-data",
                  idtoken: user.idToken.jwtToken,
                },
                data: formData,
              });

              values.logo_address = uploadFile.data.Location;
            }

            const response = await axios({
              method: "PUT",
              url: "/exhibitions/",
              headers: { idtoken: user.idToken.jwtToken },
              data: {
                values,
                linked_in_tags: values.tags.join(","),
                id: this.state.company.exhibition_id,
              },
            });

            await this.wait(3000);

            if (response.data === true) {
              this.setState({
                success: true,
              });
            } else {
              alert("Your company information could not be updated");
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, handleChange }) => (
            <Form onKeyDown={this.onKeyDown}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper>
                    <Typography className={classes.center} variant="h4">
                      Create New Event
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      Event Information
                    </Typography>
                    <CompanyInfoField
                      name="eventName"
                      id="eventName"
                      label="Event Name"
                      type="text"
                      placeholder="Your Event Name"
                    />
                    <CompanyInfoField
                      name="eventUrl"
                      id="eventUrl"
                      label="Event URL"
                      type="text"
                      placeholder="Your Event URL"
                    />
                    <CompanyInfoField
                      name="eventDescription"
                      id="eventDescription"
                      label="Event Description"
                      type="text"
                      placeholder="Your Event Description"
                      rows={5}
                      maxRows={Infinity}
                      multiline
                    />
                    <CompanyInfoField
                      name="startDate"
                      id="startDate"
                      label="Start Date"
                      type="text"
                      placeholder="YYYY/MM/DD"
                    />
                    <CompanyInfoField
                      name="endDate"
                      id="endDate"
                      label="End Date"
                      type="text"
                      placeholder="YYYY/MM/DD"
                    />
                    <CompanyInfoField
                      name="eventType"
                      id="eventType"
                      label="Event Type"
                      type="text"
                      placeholder="Physical, Hybrid, Virtual"
                    />
                  </Paper>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      Event Administrator Information
                    </Typography>
                    <CompanyInfoField
                      name="contactFirstName"
                      id="contactFirstName"
                      label="First Name"
                      type="text"
                      placeholder="Admin First Name"
                    />
                    <CompanyInfoField
                      name="contactLastName"
                      id="contactLastName"
                      label="Last Name"
                      type="text"
                      placeholder="Admin Last Name"
                    />
                    <CompanyInfoField
                      name="contactEmail"
                      id="contactEmail"
                      label="Email Address"
                      type="email"
                      placeholder="Admin Email Address"
                    />
                    <CompanyInfoField
                      name="contactCompany"
                      id="contactCompany"
                      label="Company"
                      type="text"
                      placeholder="Company"
                    />
                    <CompanyInfoField
                      name="contactTitle"
                      id="contactTitle"
                      label="Title"
                      type="text"
                      placeholder="Title"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      Implementation
                    </Typography>
                    <CompanyInfoField
                      name="landingPageMessage"
                      id="landingPageMessage"
                      label="Landing Page Message"
                      type="text"
                      placeholder="Your Landing Page Message"
                      rows={5}
                      maxRows={Infinity}
                      multiline
                    />
                    <CompanyInfoField
                      name="registration"
                      id="registration"
                      label="Registration"
                      type="text"
                      placeholder="I handle / PlanetConnect handles"
                    />
                    <CompanyInfoField
                      name="registrationFieldsRequired"
                      id="registrationFieldsRequired"
                      label="Registration Fields Required"
                      type="text"
                      placeholder="Edit"
                    />
                    <CompanyInfoField
                      name="eventFeaturesRequired"
                      id="eventFeaturesRequired"
                      label="Event Features Required"
                      type="text"
                      placeholder="Edit"
                    />
                    <CompanyInfoField
                      name="rolesRequired"
                      id="rolesRequired"
                      label="Roles Required"
                      type="text"
                      placeholder="Edit"
                    />
                    <CompanyInfoField
                      name="sponsorLevelsRequired"
                      id="sponsorLevelsRequired"
                      label="Sponsor Levels Required"
                      type="text"
                      placeholder="Edit"
                    />
                  </Paper>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      Targets
                    </Typography>
                    <CompanyInfoField
                      name="targetVirtualAttendees"
                      id="targetVirtualAttendees"
                      label="Virtual Attendees"
                      type="text"
                      placeholder="Target Number of Virtual Attendees"
                    />
                    <CompanyInfoField
                      name="targetInPersonAttendees"
                      id="targetInPersonAttendees"
                      label="In Person Attendees"
                      type="email"
                      placeholder="Target Number of In Person Attendees"
                    />
                    <CompanyInfoField
                      name="targetExhibitBooths"
                      id="targetExhibitBooths"
                      label="Exhibit Booths"
                      type="text"
                      placeholder="Target Number of Exhibit Booths"
                    />
                    <CompanyInfoField
                      name="targetPosters"
                      id="targetPosters"
                      label="Posters"
                      type="text"
                      placeholder="Target Number of Posters"
                    />
                  </Paper>
                  <Paper
                    className={classes.paper}
                    style={{ marginBottom: "56px" }}
                  >
                    <Typography className={classes.header} variant="h5">
                      Booth Colors
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <CompanyColorPicker
                          name="primaryColor"
                          id="primaryColor"
                          label="Primary Color"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CompanyColorPicker
                          name="secondaryColor"
                          id="secondaryColor"
                          label="Secondary Color"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {/* <Grid container item xs={12} justify='center' > */}
                <Grid item xs={10} sm={12} className={classes.submitGridItem}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    <Typography variant="h6">
                      {isSubmitting ? "Submitting" : "Submit"}
                    </Typography>
                  </Button>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
        <Dialog
          open={this.state.success ? this.state.success : false}
          handleClose={() => this.setState({ success: null })}
        >
          <Typography>Success!</Typography>
          <Button onClick={() => this.setState({ success: null })}>
            Updated
          </Button>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}
export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(CompanyInfo));
