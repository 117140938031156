import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
      responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Track Popularity'
          }
        }
};

export default function Reporting(props) {
const [ filteredReport, setFilteredReport] = useState([])

    useEffect(() => {

            let tracks = []
            let trackClickCount = {}
    
            props.report.map((datum) => {

              let labelString
              if(datum.track.length > 30){
                labelString = datum.track.substring(0,27) + '...'
              } else {
                labelString = datum.track
              }

                    if(!labelString){
                        labelString = 'No Track'
                    }

                    if(!tracks.includes(labelString)){
                        tracks.push(labelString)
                    }

                    if (!Object.keys(trackClickCount).includes(labelString)){
                        trackClickCount[labelString] = 1;
                    } else {
                        trackClickCount[labelString]++
                    }
    
                return true
            })

            setFilteredReport({
                labels: Object.keys(trackClickCount),
                datasets: [
                    {
                      label: 'Total Clicks',
                      data: Object.values(trackClickCount),
                      fill: true,
                      backgroundColor: '#6f1a1d',
                      borderColor: '#6f1a1d',
                    },
                ]
            })

    }, [props.report])

  return props.loading ? <CircularProgress /> : <Bar data={filteredReport} options={options} />
}
