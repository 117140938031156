import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { SketchPicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0,
    },
}));

export function ColorPicker({ color, onChange }) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <SketchPicker
                disableAlpha
                width="100%"
                color={color}
                onChange={(chosenColor) => {
                    onChange(chosenColor);
                }}
            />
        </Box>
    );
}