function EmailTemplate(body) {
  if (body.banner) {
    return `<html><img src="${body.banner}" alt="banner" width="800"><h3>${body.title}</h3><p>${body.body}</p></html>`;
  }
  return `<html><h3>${body.title}</h3><p>${body.body}</p></html>`;
}

function FormatObject(object) {
  let output = "";
  for (const [key, value] of Object.entries(object)) {
    let finalValue = value;
    if (typeof value == "boolean") {
      if (value === true) {
        finalValue = "Yes";
      } else {
        finalValue = "No";
      }
    }
    output =
      output +
      `<p><b>${capitalizeFirstLetter(
        camelCaseToNormalText(key)
      )}:</b> ${finalValue}</p>`;
  }
  return output;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function camelCaseToNormalText(string) {
  return string.replace(/([A-Z])/g, " $1");
}

export { EmailTemplate, FormatObject };
