import React from 'react'

import { Img } from 'react-image';
import { FileUploader } from '../Shared/FileUploader';

export function EditResourceLink() {

    return (
        <div>
            <Img
                src={['https://events.planetconnect.com/wp-content/uploads/account_products/1691992000046680026/PlanetConnect/VirtualLobby.png']}
                width="100%"
            />
            <p>Update Image:</p>
            <FileUploader />
        </div>
    );
}
