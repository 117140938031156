import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import LandingPage from "./Containers/LandingPage";
import Signin from "./Containers/Authentication/Signin";
// import SSO from "./Containers/Authentication/SSO";
import ConfirmationCode from "./Containers/Authentication/ConfirmationCode";
import ForgotPassword from "./Containers/Authentication/ForgotPassword";

import Layout from "./Components/MiniSidebar";
import { NavContainer } from "./app/navigation/NavContainer";
import { MainContent } from "./app/content/MainContent";
import CompanyInfo from "./Containers/Company/CompanyInfo";
import ProductsList from "./Containers/Products/ProductsList";
import { ProductInfo } from "./Containers/Products/ProductInfo";
import AssetsList from "./Containers/Assets/AssetsList";
import { AssetInfo } from "./Containers/Assets/AssetInfo";
import StaffList from "./Containers/Staff/StaffList";
import { StaffInfo } from "./Containers/Staff/StaffInfo";
import { ResourcesList } from "./Containers/Resources/ResourcesList";
import { ResourceInfo } from "./Containers/Resources/ResourceInfo";
import MyEventsList from "./Containers/Events/MyEventsList";
import AbstractsList from "./Containers/Abstracts/AbstractsList";
import ExhibitBooth from "./Containers/Exhibits/ExhibitBooth";
import Reporting from "./Containers/Reporting/Layout.Reporting";
import HostList from "./Containers/Host/HostList";
import EventInfo from "./Containers/Events/EventInfo";
import NewEvent from "./Containers/Events/NewEvent";
import NewAttendee from "./Containers/Staff/NewAttendee";
import NewExhibitBooth from "./Containers/Exhibits/NewExhibitBooth";
import EventConfig from "./Containers/EventConfig/EventConfig";
import AbstractOwner from "./Containers/AbstractOwner/AbstractOwnerList";
import VotingList from "./Containers/Voting/VotingList";
import FormBuilder from "./Containers/FormBuilder/FormBuilder";
import Registration from "./Containers/Registration/RegisterUser";
import Polls from "./Containers/Polls/Polls";
import LeadsExhibitor from "./Containers/Leads/LeadsExhibitor";
import OrderSummary from "./Containers/Orders/OrderSummary";
import NewOrders from "./Containers/Orders/NewOrders";

import arrayComparison from "./Utilities/arrayComparison";

import { routerArray } from "./config";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import setAuthenticatedUser from "./Store/Actions/setUser";

class Router extends Component {
  components = {
    LandingPage,
    Signin,
    ConfirmationCode,
    ForgotPassword,
    Reporting,
    AssetsList,
    AssetInfo,
    AbstractsList,
    CompanyInfo,
    EventInfo,
    HostList,
    MyEventsList,
    ProductsList,
    ProductInfo,
    StaffList,
    StaffInfo,
    ResourcesList,
    ResourceInfo,
    MainContent,
    NavContainer,
    ExhibitBooth,
    NewEvent,
    NewAttendee,
    NewExhibitBooth,
    EventConfig,
    AbstractOwner,
    VotingList,
    FormBuilder,
    Registration,
    Polls,
    LeadsExhibitor,
    OrderSummary,
    NewOrders,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    // Available Attendee Routes
    if (!this.props.user.user) {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/signin" render={(props) => <Signin />} />
            <Route
              path="/forgotpassword"
              render={(props) => <ForgotPassword />}
            />
            <Route
              path="/confirmationcode"
              render={(props) => <ConfirmationCode />}
            />
            {/* <Route path="/sso" render={(props) => <SSO />} /> */}
            <Route path="/" render={(props) => <LandingPage />} />
          </Switch>
        </BrowserRouter>
      );
    } else if (this.props.user.user.roles) {
      let allowedRoutes = routerArray.filter((route) => {
        const includesRole = arrayComparison(
          route.allowedRoles,
          this.props.user.user.roles.split(", ")
        );

        if (includesRole) {
          return route;
        } else {
          return false;
        }
      });

      return (
        <BrowserRouter>
          <Layout>
            <Switch>
              {allowedRoutes.map((route) => {
                const TagName = this.components[route.container];

                return (
                  <Route
                    key={route.title}
                    path={`${route.route}`}
                    render={(props) => (
                      <TagName timestamp={new Date().toString()} {...props} />
                    )}
                  />
                );
              })}

              <Route
                key={"Events"}
                path={`/`}
                render={(props) => (
                  <MyEventsList timestamp={new Date().toString()} {...props} />
                )}
              />
            </Switch>
          </Layout>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/signin" render={(props) => <Signin />} />
            {/* <Route path="/authentication" render={(props) => <SSO />} /> */}
            <Route
              path="/forgotpassword"
              render={(props) => <ForgotPassword />}
            />
            <Route
              path="/confirmationcode"
              render={(props) => <ConfirmationCode />}
            />
            <Route path="/" render={(props) => <LandingPage />} />
          </Switch>
        </BrowserRouter>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthenticatedUser: setAuthenticatedUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
