import { Auth } from "aws-amplify";

// incomplete as of 05/12/23
// need to apply to every place that makes a request
const ForceLogOut = async (removeEvent, removeAuthenticatedUser) => {
  console.log("ForceLogOut");

  await removeEvent();

  await removeAuthenticatedUser();

  await Auth.signOut({ global: true });
};

export default ForceLogOut;
