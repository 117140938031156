// handles putting the routes into the necessary array

export default function prepareRoutes(availableRoutes) {
  const swappedRoutes = [...availableRoutes];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // ADDING A NEW ROUTE
  // STEP 1: add it here
  let allRoutes = [
    {
      title: "Lobby",
      default: "Lobby",
      container: "Lobby",
      icon: "HomeIcon",
      route: "/lobby",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Agenda",
      default: "Agenda",
      container: "Agenda",
      icon: "AccessTimeIcon",
      route: "/agenda",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Workshop",
      default: "Workshop",
      container: "Workshop",
      icon: "CastForEducationIcon",
      route: "/workshop",
      allowedRoles: ["Pharma Attendee", "Attendee"],
    },
    {
      title: "Networking",
      default: "Networking",
      container: "DiscussionForum",
      icon: "LocalCafeIcon",
      route: "/discussionforum",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Poster Hall",
      default: "Poster Hall",
      container: "PosterSessions",
      icon: "FilterNoneIcon",
      route: "/posterhall",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Exhibit Hall",
      default: "Exhibit Hall",
      container: "ExhibitHall",
      icon: "BusinessIcon",
      route: "/exhibithall",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Activities Hall",
      default: "Activities Hall",
      container: "ActivitiesHall",
      icon: "WidgetsIcon",
      route: "/activitieshall",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Conference Center",
      default: "Conference Center",
      container: "ConferenceCenter",
      icon: "OndemandVideoIcon",
      route: "/auditorium",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Divider",
      default: "Divider",
      container: "Leads",
      icon: "MyleadsIcon",
      route: "/leads",
      allowedRoles: ["Booth Admin", "Booth Staff", "Attendee"],
    },
    {
      title: "Engagement Zone",
      default: "Engagement Zone",
      container: "EngagementZone",
      icon: "ForumIcon",
      route: "/engagementzone",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Vendor Queries",
      default: "Vendor Queries",
      container: "ChallengeDashboard",
      icon: "BubbleChartIcon",
      route: "/vendorqueries",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "QR Scanner",
      default: "QR Scanner",
      container: "QRScanScreen",
      icon: "CropFreeIcon",
      route: "/scan",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Leaderboard",
      default: "Leaderboard",
      container: "LeaderboardScreen",
      icon: "TableChartIcon",
      route: "/leaderboard",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Leads",
      default: "Leads",
      container: "Leads",
      icon: "MyleadsIcon",
      route: "/leads",
      allowedRoles: ["Booth Admin", "Booth Staff", "Poster Presenter"],
    },
    {
      title: "Reporting",
      default: "Reporting",
      container: "Reporting",
      icon: "BarChartIcon",
      route: "/reporting",
      allowedRoles: ["Reporting"],
    },
    {
      title: "Briefcase",
      default: "Briefcase",
      container: "Briefcase",
      icon: "WorkIcon",
      route: "/briefcase",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "My Personal Agenda",
      default: "My Personal Agenda",
      container: "MyPersonalAgenda",
      icon: "EventAvailableIcon",
      route: "/mypersonalagenda",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Support",
      default: "Support",
      container: "Help",
      icon: "HelpIcon",
      route: "/help",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Settings",
      default: "Settings",
      container: "Settings",
      icon: "SettingsIcon",
      route: "/settings",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Debug",
      default: "Debug",
      container: "Debug",
      icon: "VisibilityIcon",
      route: "/debug101",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
    {
      title: "Account Settings",
      default: "Account Settings",
      container: "AccountSettings",
      icon: "PersonIcon",
      route: "/accountsettings",
      allowedRoles: [
        "Pharma Attendee",
        "Booth Staff",
        "Booth Admin",
        "Attendee",
        "Poster Presenter",
        "Speaker",
      ],
    },
  ];

  // ADDING A NEW ROUTE
  // STEP 2: add it here, take note to name
  let routesEnabled = {
    lobby: false,
    agenda: false,
    workshop: false,
    networking: false,
    posterHall: false,
    exhibitHall: false,
    activitiesHall: false,
    conferenceCenter: false,
    engagementZone: false,
    vendorQueries: false,
    qRScanner: false,
    leaderboard: false,
    leads: false,
    reporting: false,
    briefcase: false,
    myPersonalAgenda: false,
    support: false,
    settings: false,
    accountSettings: false,
  };

  let assignRouteTitle = (route, property) => {
    for (let newRoute of swappedRoutes) {
      if (route.container === newRoute.container) {
        route.title = newRoute.title;
        routesEnabled[property] = true;
      }
    }
  };

  // ADDING A NEW ROUTE
  // STEP 3: add it here,
  // if container !== to property name, write custom logic to assignRouteTitle
  for (let route of allRoutes) {
    for (const property in routesEnabled) {
      if (route.container === capitalizeFirstLetter(property)) {
        if (route.title === "Divider") {
          // do nothing
        } else {
          assignRouteTitle(route, property);
        }
      } else if (
        route.container === "DiscussionForum" &&
        property === "networking"
      ) {
        assignRouteTitle(route, property);
      } else if (
        route.container === "PosterSessions" &&
        property === "posterHall"
      ) {
        assignRouteTitle(route, property);
      } else if (route.container === "Help" && property === "support") {
        assignRouteTitle(route, property);
      } else if (
        route.container === "QRScanScreen" &&
        property === "qRScanner"
      ) {
        assignRouteTitle(route, property);
      } else if (
        route.container === "ChallengeDashboard" &&
        property === "vendorQueries"
      ) {
        assignRouteTitle(route, property);
      } else if (
        route.container === "LeaderboardScreen" &&
        property === "leaderboard"
      ) {
        assignRouteTitle(route, property);
      }
    }
  }

  return [allRoutes, routesEnabled];
}
