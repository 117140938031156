import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "../../Components/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
// import Chip from "@material-ui/core/Chip";
// import Box from "@material-ui/core/Box";
import axios from "axios";
import { Link } from "react-router-dom";
// import { Auth } from 'aws-amplify';
import moment from "moment";
import { formDataShape, choiceShapeMaker } from "./FormDataShape";

// icons
import CheckIcon from "@material-ui/icons/Check";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import EditIcon from "@material-ui/icons/Edit";

// formik
import { Formik, Form, Field, useField } from "formik";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import setEvent from "../../Store/Actions/setEvent";
import setUser from "../../Store/Actions/setUser";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(11),
  },
  header: {
    textAlign: "center",
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    width: "100%",
  },
  submitGridItem: (props) => ({
    position: "fixed",
    bottom: "60px",
    left: props.sidebar?.open ? "120px" : "2px",
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  }),
  submitButton: (props) => ({
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  }),
  gridFieldLeft: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    flexGrow: 0,
  },
  gridFieldRight: {
    flexGrow: 11,
  },
  gridTextRight: {
    flexGrow: 11,
    // maxWidth: '60%',
  },
  toggleButtonGroup: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  toggleButton: {
    height: "40px",
    width: "40px",
  },
  titleSpacing: {
    marginLeft: theme.spacing(2),
  },
  chipInputField: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "2px",
    },
  },
  gridAlias: {
    padding: theme.spacing(0, 0.5),
    flexGrow: 12,
  },
  selectGridItems: {
    padding: theme.spacing(0.5),
  },
  selectButtons: {
    width: "100%",
  },
  editButton: {
    width: "40px",
    height: "40px",
    minWidth: "40px",
    minHeight: "40px",
  },
  iconDescription: {
    border: "1px solid black",
    borderRadius: theme.spacing(0.5),
  },
});

class FormBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formBuilt: false,
      success: false,
      baseValues: null,
      preexistingForm: false,
      dialogChoicesOpen: false,
      dialogTypesOpen: false,
      selectedField: null,
      selectedIndex: null,
      currentType: "",
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      try {
        axios({
          method: "get",
          url: "forms/" + this.props.event.event.show_id,
          // headers: { idtoken: data.idToken.jwtToken },
          params: { showId: this.props.event.event.show_id },
        })
          .then((response) => {
            console.log("expected fields: 2", response.data.data);
            const baseValues = {};
            let preexistingForm = true;
            if (response.data.data.length === 0) {
              preexistingForm = false;
              formDataShape[0].show_id = this.props.event.event.show_id;
              formDataShape[1].show_id = this.props.event.event.show_id;
              baseValues.registration = formDataShape[0];
              baseValues.abstractSubmission = formDataShape[1];
            } else {
              let formObject = response.data.data;
              let registration = formObject.filter(
                (item) => item.type === "registration"
              )[0];
              let abstractSubmission = formObject.filter(
                (item) => item.type === "abstract_submission"
              )[0];
              registration.fields = registration.fields.sort(function (a, b) {
                return a.field_id - b.field_id;
              });
              abstractSubmission.fields = abstractSubmission.fields.sort(
                function (a, b) {
                  return a.field_id - b.field_id;
                }
              );
              baseValues.registration = registration;
              baseValues.abstractSubmission = abstractSubmission;
            }
            baseValues.currentType = "";
            // baseValues.currentAttendance = "";
            // was used when chips were used

            let currentEvent = this.props.event.event;
            let startDate = moment(currentEvent.start_date);
            let endDate = moment(currentEvent.end_date);
            let totalNumberOfDays = endDate.diff(startDate, "days") + 1;
            let attendanceChoices = [];
            for (let i = 0; i <= totalNumberOfDays; i++) {
              attendanceChoices.push(choiceShapeMaker(i, "Attendance"));
            }
            baseValues.registration.fields.forEach((field, index) => {
              // console.log(index, typeof field.choices, field.choices?.length);
              if (!field.choices?.length) {
                field.choices = [];
              }
              if (field.label === "Attendance") {
                if (!field.choices || field.choices?.length === 0)
                  field.choices = attendanceChoices;
              }
            });
            baseValues.abstractSubmission.fields.forEach((field, index) => {
              field.choices = [];
            });

            this.setState({
              baseValues: baseValues,
              formBuilt: true,
              preexistingForm,
            });
          })
          .catch((error) => console.log(error));
      } catch (error) {
        if (error === "No current user") {
          console.log(error, "log them out");
          try {
            ForceLogOut(
              this.props.removeEvent,
              this.props.removeAuthenticatedUser
            );
          } catch (error) {
            console.log("ForceLogOut", error);
          }
        }
        console.log(error);
      }
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    const handleDialog = () => {
      this.setState({
        success: false,
        dialogChoicesOpen: false,
        dialogTypesOpen: false,
        selectedFieldName: null,
        selectedFieldFormalName: null,
        selectedFieldIndex: null,
        selectedFormType: null,
      });
    };

    const FormBuildInfoField = ({ ...props }) => {
      const [field, meta] = useField(props);
      if (!field.value) {
        field.value = "";
      }

      return (
        <>
          <Field
            className="text-input"
            component={TextField}
            margin="dense"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div
              className="error"
              style={{ color: "red", textAlign: "center" }}
            >
              {meta.error}
            </div>
          ) : null}
        </>
      );
    };

    const FormBuildToggle = ({ ...props }) => {
      const [field, meta, helpers] = useField(props);
      if (!field.value) {
        field.value = false;
      }

      const { setValue } = helpers;

      let onClickFunction = () => setValue(!field.value);
      let selected = field.value;
      let isDisabled = false;
      let buttonContent = (
        <CheckIcon style={{ color: "#F2F2F2" }} fontSize="small" />
      );
      if (props.required) {
        buttonContent = (
          <Typography style={{ color: "#F2F2F2" }}>R </Typography>
        );
      }
      if (props.dropdown === "true") {
        buttonContent = (
          <FormatListBulletedIcon
            style={{ color: "#F2F2F2" }}
            fontSize="small"
          />
        );
        selected = props.type === "select" || props.type === "multi_select";
        isDisabled = !props?.fieldobject?.is_enabled;
        onClickFunction = () => {
          if (field.value === "text_field") {
            setValue("select");
          } else {
            setValue("text_field");
          }
        };
      }

      return (
        <>
          <Field
            className={classes.toggleButtonGroup}
            component={ToggleButtonGroup}
          >
            <ToggleButton
              className={classes.toggleButton}
              value="check"
              selected={selected}
              {...field}
              // {...props}
              disabled={isDisabled}
              onClick={() => onClickFunction()}
            >
              {buttonContent}
            </ToggleButton>
          </Field>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </>
      );
    };

    const FormBuildButtonAddDeleteChoice = ({ ...props }) => {
      const [field, meta, helpers] = useField(props); // eslint-disable-line

      const { setValue } = helpers;
      // console.log(field);
      // console.log(props);

      function addNewChoice() {
        let currentChoices = field.value;
        let index = currentChoices.length;
        currentChoices.push(choiceShapeMaker(index, props.fieldType));
        setValue(currentChoices);
      }
      function deleteChoice(event) {
        let currentChoices = field.value;
        currentChoices.splice(props.index, 1);
        setValue(currentChoices);
      }
      return (
        <Field
          className={classes.selectButtons}
          component={Button}
          variant={props.variant}
          color={props.color}
          onClick={(event) => {
            if (props.type === "add") {
              addNewChoice();
            } else if (props.type === "delete") {
              deleteChoice(event);
            }
          }}
          style={{ ...props.style }}
        >
          {props.label}
        </Field>
      );
    };

    const FormBuildButtonSelect = ({ ...props }) => {
      const [field, meta, helpers] = useField(props); // eslint-disable-line
      if (!field.value) {
        field.value = false;
      }
      const { setValue } = helpers;
      let selected = props.type === field.value;

      return (
        <Field
          className={classes.selectButtons}
          component={Button}
          variant="contained"
          color={selected ? "primary" : "default"}
          onClick={() => setValue(props.type)}
        >
          {selected && (
            <CheckIcon style={{ color: "#F2F2F2" }} fontSize="small" />
          )}
          {props.label}
        </Field>
      );
    };

    const FormBuildButtonEditDropdown = ({ ...props }) => {
      const [field, meta, helpers] = useField(props); // eslint-disable-line
      if (!field.value) {
        field.value = false;
      }
      // const { setValue } = helpers;

      let fromForm = props.name.split(".")[0];

      return (
        <Field
          className={classes.editButton}
          component={Button}
          variant="outlined"
          color="primary"
          disabled={props.disabled}
          onClick={() =>
            this.setState({
              dialogChoicesOpen: true,
              selectedFieldName: props.name,
              selectedFieldFormalName: props.label,
              selectedFieldIndex: props.index,
              selectedFormType: fromForm,
            })
          }
        >
          <EditIcon fontSize="small" />
          {/* <EditIcon style={{ color: "#F2F2F2" }} fontSize="small" /> */}
        </Field>
      );
    };

    const FormBuildButtonAddType = ({ ...props }) => {
      const [field, meta, helpers] = useField(props); // eslint-disable-line
      // if (typeof field.value !== "string") {
      //   field.value = "";
      // }

      const { setValue } = helpers;

      let createField = (string) => {
        let snakeCaseString = toSnakeCase(string);

        return {
          form_id: null,
          // field_id: null,
          name: snakeCaseString,
          label: string,
          type: "text_field",
          placeholder: string,
          section: "type",
          is_enabled: true,
          is_required: true,
        };
      };
      const toSnakeCase = (str) =>
        str &&
        str
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
          )
          .map((x) => x.toLowerCase())
          .join("_");

      let addType = () => {
        let newType = createField(field.value);
        props.values.abstractSubmission.fields = [
          ...props.values.abstractSubmission.fields,
          newType,
        ];
        setValue("");
      };

      return (
        <Grid container>
          <Grid item xs={8}>
            <Field
              className="text-input"
              component={TextField}
              margin="dense"
              variant="outlined"
              fullWidth
              {...props}
              {...field}
              style={{ margin: "0px" }}
            />
          </Grid>
          <Grid item xs style={{ marginLeft: "8px" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "100%" }}
              onClick={() => addType()}
            >
              + New Type
            </Button>
          </Grid>
        </Grid>
      );
    };

    const toggleAndField = (fieldName, fieldObject, key) => {
      const dropdownFieldsToOmit = [
        "First Name",
        "Last Name",
        "Email",
        "Job Title",
        "Street Address",
        "City",
        "State",
        "Zip",
        "Country",
        "Phone Number",
        "Talk",
        "Poster",
        "Bio",
      ];
      const hideDropdown = !dropdownFieldsToOmit.includes(fieldObject.label);
      return (
        <Grid
          container
          key={fieldName + key}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item className={classes.gridFieldLeft}>
            <FormBuildToggle
              name={fieldName + ".is_enabled"}
              id={fieldName + ".is_enabled"}
            />
          </Grid>
          <Grid item className={classes.gridFieldLeft}>
            <FormBuildToggle
              name={fieldName + ".is_required"}
              id={fieldName + ".is_required"}
              required={true}
            />
          </Grid>
          <Grid item className={classes.gridFieldLeft}>
            <FormBuildButtonEditDropdown
              name={fieldName}
              id={fieldName}
              label={fieldObject.label}
              type="select"
              index={key}
              disabled={!fieldObject.is_enabled}
            />
          </Grid>
          {hideDropdown && (
            <Grid item className={classes.gridFieldLeft}>
              <FormBuildToggle
                name={fieldName + ".type"}
                id={fieldName + ".type"}
                dropdown="true"
                fieldname={fieldObject.label}
                fieldobject={fieldObject}
                type={fieldObject.type}
              />
            </Grid>
          )}
          <Grid item className={classes.gridFieldRight}>
            <FormBuildInfoField
              name={fieldName + ".name"}
              id={fieldName + ".name"}
              label={fieldObject.label}
              placeholder={fieldObject.label}
              type="text"
            />
          </Grid>
        </Grid>
      );
    };

    const toggleAndText = (fieldName, fieldObject, size, key, type) => {
      let variableSize = size;
      if (!size) {
        variableSize = 12;
      }
      let fromForm = fieldName.split(".")[0];

      // const isDropdown = type === "select" || type === "multi_select";

      const dropdownFieldsToOmit = [
        "First Name",
        "Last Name",
        "Email",
        "Job Title",
        "Street Address",
        "City",
        "State",
        "Zip",
        "Country",
        "Phone Number",
        "Talk",
        "Poster",
        "Bio",
      ];
      const hideDropdown = !dropdownFieldsToOmit.includes(fieldObject.label);
      const hideRequired = fieldObject.section !== "type";

      return (
        <Grid
          item
          xs={variableSize}
          key={fieldName + key}
          container
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item className={classes.gridFieldLeft}>
            <FormBuildToggle
              name={fieldName + ".is_enabled"}
              id={fieldName + ".is_enabled"}
              fieldobject={fieldObject}
            />
          </Grid>
          {hideRequired && (
            <Grid item className={classes.gridFieldLeft}>
              <FormBuildToggle
                name={fieldName + ".is_required"}
                id={fieldName + ".is_required"}
                required={true}
                fieldobject={fieldObject}
              />
            </Grid>
          )}
          {/* TODO: build this out for abstractSubmission
          may not be necessary for abstractSubmission... */}
          {fromForm !== "abstractSubmission" && (
            <>
              <Grid item className={classes.gridFieldLeft}>
                <FormBuildButtonEditDropdown
                  name={fieldName}
                  id={fieldName}
                  label={fieldObject.label}
                  type="select"
                  index={key}
                  disabled={!fieldObject.is_enabled}
                />
              </Grid>
              {hideDropdown && (
                <Grid item className={classes.gridFieldLeft}>
                  <FormBuildToggle
                    name={fieldName + ".type"}
                    id={fieldName + ".type"}
                    dropdown="true"
                    fieldobject={fieldObject}
                    type={type}
                    disabled={!fieldObject.is_enabled}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} className={classes.gridTextRight}>
            <Typography
              variant="body1"
              paragraph={true}
              style={{ marginBottom: "0px" }}
            >
              {fieldObject.label}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
      );
    };

    const toggleFieldAndDropdown = (
      fieldName,
      fieldObject,
      key,
      displayIsRequired,
      arrayFieldName
    ) => {
      let isDropdown =
        fieldObject.type === "select" || fieldObject.type === "multi_select";
      let dropdownOptions = fieldObject[arrayFieldName]?.map(
        (option, index) => {
          return fieldAndAlias(
            option,
            fieldName + `.${arrayFieldName}`,
            fieldObject,
            index
          );
        }
      );
      let selectVsMultiOption = (
        <>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="subtitle2">
              <b>Mode for multiple choices</b>
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.selectGridItems}>
            <FormBuildButtonSelect
              name={fieldName + ".type"}
              id={fieldName + ".type"}
              label="Select"
              type="select"
            />
          </Grid>
          <Grid item xs={6} className={classes.selectGridItems}>
            <FormBuildButtonSelect
              name={fieldName + ".type"}
              id={fieldName + ".type"}
              label="Multi-Select"
              type="multi_select"
            />
          </Grid>
        </>
      );
      let addOptionButton = (
        <Grid item xs={12} style={{ padding: "0px 4px" }}>
          <FormBuildButtonAddDeleteChoice
            name={fieldName + ".choices"}
            id={fieldName + ".choices"}
            label="+ Add Choice"
            type="add"
            fieldType={fieldObject.label}
            variant="contained"
            color="primary"
          />
        </Grid>
      );

      const multiSelectFieldsToOmit = [
        "Department",
        "Division",
        "Company",
        "Site",
      ];
      const hideSelectOptions = !multiSelectFieldsToOmit.includes(
        fieldObject.label
      );

      return (
        <Grid
          container
          key={fieldName + key}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item className={classes.gridFieldLeft}>
            <FormBuildToggle
              name={fieldName + ".is_enabled"}
              id={fieldName + ".is_enabled"}
            />
          </Grid>
          {displayIsRequired && (
            <Grid item className={classes.gridFieldLeft}>
              <FormBuildToggle
                name={fieldName + ".is_required"}
                id={fieldName + ".is_required"}
                required={true}
              />
            </Grid>
          )}
          <Grid item className={classes.gridFieldRight}>
            <FormBuildInfoField
              name={fieldName + ".name"}
              id={fieldName + ".name"}
              label={fieldObject.label}
              placeholder={fieldObject.placeholder}
              type="text"
            />
          </Grid>
          {isDropdown && (
            <>
              {hideSelectOptions && selectVsMultiOption}
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="subtitle2">
                  <b>Potential choices</b>
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                {dropdownOptions}
              </Grid>
              {addOptionButton}
            </>
          )}
        </Grid>
      );
    };

    const fieldAndAlias = (option, fieldName, fieldObject, key) => {
      let size = 40;
      return (
        <Grid
          container
          key={fieldName + key}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item style={{ paddingLeft: "4px" }}>
            <FormBuildButtonAddDeleteChoice
              index={key}
              name={fieldName}
              id={fieldName}
              label="X"
              type="delete"
              fieldType={fieldObject.label}
              variant="contained"
              color="secondary"
              style={{
                marginTop: "4px",
                minWidth: `${size}px`,
                minHeight: `${size}px`,
                maxWidth: `${size}px`,
                maxHeight: `${size}px`,
              }}
            />
          </Grid>
          <Grid item className={classes.gridAlias}>
            <FormBuildInfoField
              name={fieldName + `[${key}].label`}
              id={fieldName + `[${key}].label`}
              label="Label"
              placeholder="Label or Alias for this option"
              type="text"
            />
          </Grid>
          <Grid item className={classes.gridAlias}>
            <FormBuildInfoField
              name={fieldName + `[${key}].value`}
              id={fieldName + `[${key}].value`}
              label="Value"
              placeholder={fieldObject.label}
              type="text"
              disabled={fieldObject.label === "Attendance"}
            />
          </Grid>
        </Grid>
      );
    };

    // const DomainTagField = ({ ...props }) => {
    //   const [field, meta, helpers] = useField(props); //eslint-disable-line
    //   const { setValue } = helpers;

    //   let tags = [];
    //   tags = props.values.registration.fields[props.index].choices;

    //   const onSubmit = (e) => {
    //     props.values.registration.fields[props.index].choices = [
    //       ...tags,
    //       {
    //         value: props.values.currentAttendance,
    //         label: props.values.currentAttendance,
    //       },
    //     ];
    //     setValue("");
    //   };

    //   return (
    //     <Box
    //       component="ul"
    //       style={{
    //         display: "flex",
    //         flexWrap: "wrap",
    //         listStyle: "none",
    //         padding: "2px",
    //         margin: 0,
    //         paddingRight: "0px",
    //       }}
    //     >
    //       <Field
    //         className={classes.chipInputField}
    //         component={TextField}
    //         // type="text"
    //         margin="dense"
    //         variant="outlined"
    //         fullWidth
    //         // onKeyDown={handleOnKeyDown}
    //         {...field}
    //         {...props}
    //         InputProps={{
    //           endAdornment: (
    //             <Button
    //               variant="outlined"
    //               color="primary"
    //               onClick={(e) => onSubmit(e)}
    //             >
    //               +Add
    //             </Button>
    //           ),
    //         }}
    //       />
    //     </Box>
    //   );
    // };

    // const DomainChips = ({ ...props }) => {
    //   const [field, meta, helpers] = useField(props); //eslint-disable-line
    //   const { setValue } = helpers;

    //   let tags = props.values.registration.fields[props.index].choices;

    //   const handleDelete = (e) => {
    //     tags = tags.filter((tag) => {
    //       return tag !== e;
    //     });
    //     props.values.registration.fields[props.index].choices = tags;
    //     setValue(tags);
    //   };

    //   let tagsMapped;
    //   if (tags) {
    //     tagsMapped = tags.map((data, index) => {
    //       return (
    //         <li key={index}>
    //           <Chip
    //             label={data.label}
    //             style={{ margin: "2px" }}
    //             color="primary"
    //             onDelete={() => handleDelete(data)}
    //             // onDelete={data === "React" ? undefined : handleDelete(data)} // working method from example: https://codesandbox.io/s/material-demo-forked-2uggu?file=/demo.js
    //           />
    //         </li>
    //       );
    //     });
    //   } else {
    //     tagsMapped = null;
    //   }

    //   return (
    //     <Box
    //       component="ul"
    //       style={{
    //         display: "flex",
    //         flexWrap: "wrap",
    //         listStyle: "none",
    //         padding: "2px",
    //         margin: 0,
    //       }}
    //     >
    //       {tagsMapped}
    //     </Box>
    //   );
    // };

    let baseValues;
    let bodyContent;

    if (!this.state.formBuilt) {
      bodyContent = (
        <Typography className={classes.center} variant="h5">
          Form Builder loading...
        </Typography>
      );
    } else {
      baseValues = this.state.baseValues;

      bodyContent = (
        <>
          {/* <Paper className={classes.paper}> */}
          <Formik
            initialValues={baseValues}
            onSubmit={async (values, { setSubmitting }) => {
              await new Promise((r) => setTimeout(r, 500));
              const newValues = [];
              let processingValues = JSON.parse(JSON.stringify(values)); // Make a deep copy

              for (const form in processingValues) {
                if (form !== "currentType") {
                  let formSection = processingValues[form];
                  formSection?.fields?.forEach((field, index) => {
                    if (field?.choices?.length) {
                      field.choices.forEach((choice) => {
                        choice.value = choice.value.trim();
                        choice.label = choice.label.trim();
                      });
                      field.choices = JSON.stringify(field.choices);
                    }
                  });
                  newValues.push(processingValues[form]);
                }
              }

              // CODE FOR SUBMITTING AXIOS REQUEST
              console.log(newValues);
              // TODO: ANY UPDATES TO THE FORM SHOULD REQUIRE AUTHENTICATION
              // const user = await Auth.currentSession()
              if (this.state.preexistingForm) {
                // eslint-disable-next-line
                const response = await axios({
                  method: "put",
                  url: "forms/" + this.props.event.event.show_id,
                  // headers: { idtoken: data.idToken.jwtToken },
                  params: { showId: this.props.event.event.show_id },
                  data: newValues,
                })
                  .then((response) => {
                    if (response.data === true) {
                      this.setState({ success: true });
                    }
                  })
                  .catch((error) => {
                    alert("Your Form could not be updated");
                    console.log(error);
                  });
              } else {
                // eslint-disable-next-line
                const response = await axios({
                  method: "post",
                  url: "forms/" + this.props.event.event.show_id,
                  // headers: { idtoken: data.idToken.jwtToken },
                  params: { showId: this.props.event.event.show_id },
                  data: newValues,
                })
                  .then((response) => {
                    if (response.data === true) {
                      this.setState({ success: true });
                    }
                  })
                  .catch((error) => {
                    alert("Your Form could not be created");
                    console.log(error);
                  });
              }

              // await wait(3000);

              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <FormBuildToggle
                            name="registration.is_enabled"
                            id="registration.is_enabled"
                          />
                        </Grid>
                        <Grid item className={classes.titleSpacing}>
                          <Typography className={classes.header} variant="h5">
                            Registration
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.titleSpacing}>
                          <Typography
                            className={classes.header}
                            variant="body1"
                          >
                            If box is checked, registration will be enabled.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className={classes.divider} />
                      <Typography className={classes.header} variant="body1">
                        <b>Standard Fields</b>
                        <br />
                      </Typography>
                      <Divider className={classes.divider} />
                      <Grid container>
                        {values.registration.fields.map((field, index) => {
                          if (field.section === "fieldsStandard") {
                            if (field.label === "Attendance") {
                              return (
                                <React.Fragment key={index}>
                                  <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                    <Typography
                                      className={classes.header}
                                      variant="body1"
                                    >
                                      <b>Attendance</b>
                                      <br />
                                    </Typography>
                                    <Divider className={classes.divider} />
                                  </Grid>
                                  {toggleFieldAndDropdown(
                                    `registration.fields[${index}]`,
                                    field,
                                    index,
                                    true,
                                    "choices"
                                  )}
                                </React.Fragment>
                              );
                            } else {
                              return toggleAndText(
                                `registration.fields[${index}]`,
                                field,
                                6,
                                index,
                                field.type
                              );
                            }
                          } else {
                            return null;
                          }
                        })}
                      </Grid>
                      <Divider className={classes.divider} />
                      <Typography className={classes.header} variant="body1">
                        <b>Custom Fields</b>
                        <br />
                      </Typography>
                      <Divider className={classes.divider} />
                      {values.registration.fields.map((field, index) => {
                        if (field.section === "fieldsCustom") {
                          return toggleAndField(
                            `registration.fields[${index}]`,
                            field,
                            index
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Divider className={classes.divider} />
                      <Typography className={classes.header} variant="body1">
                        <b>Questions</b>
                        <br />
                      </Typography>
                      <Divider className={classes.divider} />
                      {values.registration.fields.map((field, index) => {
                        if (field.section === "question") {
                          return toggleAndField(
                            `registration.fields[${index}]`,
                            field,
                            index
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.paper}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <FormBuildToggle
                            name="abstractSubmission.is_enabled"
                            id="abstractSubmission.is_enabled"
                          />
                        </Grid>
                        <Grid item className={classes.titleSpacing}>
                          <Typography className={classes.header} variant="h5">
                            Abstract Submission
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.titleSpacing}>
                        <Typography className={classes.header} variant="body1">
                          If box is checked, abstract submission will be
                          enabled.
                        </Typography>
                      </Grid>
                      <Divider className={classes.divider} />
                      <Typography className={classes.header} variant="body1">
                        <b>Types Offered</b>
                        <br />
                      </Typography>
                      <Divider className={classes.divider} />
                      <Grid container>
                        {values.abstractSubmission.fields.map(
                          (field, index) => {
                            if (field.section === "type") {
                              return toggleAndText(
                                `abstractSubmission.fields[${index}]`,
                                field,
                                3,
                                index,
                                field.type
                              );
                            } else {
                              return null;
                            }
                          }
                        )}
                        <Grid item xs={12}>
                          <FormBuildButtonAddType
                            name="currentType"
                            id="currentType"
                            label="New Type"
                            placeholder="New Type"
                            values={values}
                          />
                        </Grid>
                      </Grid>
                      {/* TOPICS NOT NECESSARY HERE BECAUSE THEY EXIST IN EVENT CONFIG */}
                      {/* <Divider className={classes.divider} />
                      <Typography className={classes.header} variant='body1'>
                        <b>Topics Offered</b><br/>
                      </Typography>
                      {values.abstractSubmission.fields
                        .map((field, index )=> {
                          if (field.section === 'topic' ) {
                            return toggleAndField(`abstractSubmission.fields[${index}]`, field, index)
                          } else {
                            return null
                          }
                      })} */}
                      <Divider className={classes.divider} />
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography
                            className={classes.header}
                            variant="body1"
                          >
                            <b>Author Fields</b>
                            <br />
                          </Typography>
                          <Divider
                            className={classes.divider}
                            style={{ margin: "8px" }}
                          />
                          {values.abstractSubmission.fields.map(
                            (field, index) => {
                              if (field.section === "author_fields") {
                                return toggleAndText(
                                  `abstractSubmission.fields[${index}]`,
                                  field,
                                  12,
                                  index,
                                  field.type
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            className={classes.header}
                            variant="body1"
                          >
                            <b>Presenter Fields</b>
                            <br />
                          </Typography>
                          <Divider
                            className={classes.divider}
                            style={{ margin: "8px" }}
                          />
                          {values.abstractSubmission.fields.map(
                            (field, index) => {
                              if (field.section === "presenter_fields") {
                                return toggleAndText(
                                  `abstractSubmission.fields[${index}]`,
                                  field,
                                  12,
                                  index,
                                  field.type
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={10} sm={12} className={classes.submitGridItem}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    <Typography variant="h6">
                      {isSubmitting ? "Saving..." : "Save Changes"}
                    </Typography>
                  </Button>
                </Grid>
                <Dialog
                  open={this.state.dialogChoicesOpen}
                  handleClose={() => handleDialog()}
                >
                  <Typography variant="h5">
                    Field Customization Options
                  </Typography>
                  <Typography>
                    Field may be renamed and will display on registration.{" "}
                    <br />
                    If field is set to dropdown, you may add, edit and delete
                    choices.
                  </Typography>
                  <br />
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                      <Typography className={classes.header} variant="body1">
                        <b>
                          {
                            values[this.state.selectedFormType]?.fields[
                              this.state.selectedFieldIndex
                            ]?.label
                          }
                        </b>
                        <br />
                      </Typography>
                      <Divider className={classes.divider} />
                    </Grid>
                    {this.state.selectedFieldFormalName === "Country" ? (
                      <Typography variant="body1">
                        Country list is handled within the virtual environemnt.{" "}
                        <br />
                        No need to edit or populate it here.
                      </Typography>
                    ) : (
                      this.state.selectedFieldName && (
                        <>
                          {toggleFieldAndDropdown(
                            this.state.selectedFieldName,
                            values[this.state.selectedFormType]?.fields[
                              this.state.selectedFieldIndex
                            ],
                            this.state.selectedFieldIndex,
                            true,
                            "choices"
                          )}
                        </>
                      )
                    )}
                  </React.Fragment>
                  <br />
                  <Divider className={classes.divider} />
                  <br />
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => handleDialog()}
                  >
                    X Close
                  </Button>
                </Dialog>
                <Dialog
                  open={this.state.dialogTypesOpen}
                  handleClose={() => handleDialog()}
                >
                  <Typography variant="h5">Add a new type</Typography>
                  <Typography>
                    If the type you are looking for isn't in the standard set,
                    add one here.
                  </Typography>
                  <br />
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                      <Typography className={classes.header} variant="body1">
                        <b>
                          {
                            values[this.state.selectedFormType]?.fields[
                              this.state.selectedFieldIndex
                            ]?.label
                          }
                        </b>
                        <br />
                      </Typography>
                      <Divider className={classes.divider} />
                    </Grid>
                    {this.state.selectedFieldFormalName === "Country" ? (
                      <Typography variant="body1">
                        Country list is handled within the virtual environemnt.{" "}
                        <br />
                        No need to edit or populate it here.
                      </Typography>
                    ) : (
                      this.state.selectedFieldName && (
                        <>
                          {toggleFieldAndDropdown(
                            this.state.selectedFieldName,
                            values[this.state.selectedFormType]?.fields[
                              this.state.selectedFieldIndex
                            ],
                            this.state.selectedFieldIndex,
                            true,
                            "choices"
                          )}
                        </>
                      )
                    )}
                  </React.Fragment>
                  <br />
                  <Divider className={classes.divider} />
                  <br />
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => handleDialog()}
                  >
                    X Close
                  </Button>
                </Dialog>
              </Form>
            )}
          </Formik>
          <Dialog open={this.state.success} handleClose={() => handleDialog()}>
            <Typography variant="h5">Success!</Typography>
            <Typography>Your Form has been built.</Typography>
            <br />
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleDialog()}
            >
              X Close
            </Button>
          </Dialog>
          {/* </Paper> */}
        </>
      );
    }

    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Typography
              className={classes.center}
              variant="h4"
              color="textPrimary"
            >
              Form Builder
            </Typography>
            <Typography className={classes.header} variant="subtitle1">
              Toggle{" "}
              <CheckIcon fontSize="small" className={classes.iconDescription} />{" "}
              checkbox to enable/disable field.
              <br />
              Toggle{" "}
              <div
                className={classes.iconDescription}
                style={{ display: "inline", padding: "0px 4px" }}
              >
                <b>R</b>
              </div>{" "}
              checkbox to enable/disable that field as required.
              <br />
              Click{" "}
              <EditIcon
                fontSize="small"
                className={classes.iconDescription}
              />{" "}
              to edit name and dropdown options.
              <br />
              Toggle{" "}
              <FormatListBulletedIcon
                fontSize="small"
                className={classes.iconDescription}
              />{" "}
              checkbox on supported fields to turn into dropdown mode.
              <br />
            </Typography>
          </Paper>
          {bodyContent}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEvent: setEvent,
      setUser: setUser,
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(FormBuilder));
