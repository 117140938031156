import React, { Component } from "react";
import NavBar from "../Components/Navbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link, withRouter } from "react-router-dom";
// import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import banner from "../Assets/Banner.jpg";

const useStyles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  headings: {
    padding: theme.spacing(3),
  },
  listElement: {
    paddingLeft: "20px",
  },
  image: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
});

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    let slug = this.props.location.pathname.substring(1);

    let body;

    let defaultBody = (
      <Typography variant="body1" gutterBottom align="center">
        The <b>Administration Portal</b> provides access to a wide range of
        administrative functions for a variety of event participants. You MUST
        be signed up for a PlanetConnect event and personally designated with a
        role to enter this portal. The portal includes Current as well as
        Archived events in which you may have participated.
        <br />
        <br />
        Have questions or need help? Contact us at{" "}
        <a
          href="mailto:support@planetconnect.com"
          target="_blank"
          rel="noreferrer"
        >
          support@planetconnect.com
        </a>{" "}
        or use the support bubble in the lower left.
      </Typography>
    );

    let exhibitorBody = (
      <Typography variant="body1" gutterBottom align="center">
        The <b>Administration Portal</b> provides access to a wide range of
        administrative functions for participating Exhibitors. You MUST be
        signed up for a PlanetConnect event and personally designated with a
        role (e.g., Booth Administrator, Poster Presenter, Speaker) to enter
        this portal. The portal includes Current as well as Archived events you
        may have participated in.
        <br />
        <br />
        Designated Booth Administrators will be able to view and update Company
        information, Booth Staff, Exhibitor Kit items and sponsorships, leads,
        and Abstracts. Your company may have more than one Booth Administrator -
        if you need to be a booth administrator for a particular event, contact
        us at exhibitorsupport@planetconnect.com (include the show name).
        <br />
        <br />
        Have questions or need help? Contact us at{" "}
        <a
          href="mailto:exhibitorsupport@planetconnect.com"
          target="_blank"
          rel="noreferrer"
        >
          exhibitorsupport@planetconnect.com
        </a>{" "}
        or use the support bubble in the lower left.
      </Typography>
    );

    let hostBody = (
      <Typography variant="body1" gutterBottom align="center">
        The <b>Administration Portal</b> provides access to a wide range of
        administrative functions for a variety of event participants. You MUST
        be signed up for a PlanetConnect event and personally designated by
        PlanetConnect with a role (e.g., Booth Administrator, Poster Presenter,
        Speaker) to enter this portal. The portal includes Current as well as
        Archived events in which you may have participated.
        <br />
        <br />
        Different roles will see different things in this portal.
        <br />
        <br />
        If you are a Poster Presenter, you will see your poster abstract
        submission and be able to either upload an associated PDF and video or
        provide direct URL's to them and any private meeting room links you may
        have.
        <br />
        <br />
        If you are on the Host Committee, you will be able to view and vote on
        abstracts.
        <br />
        <br />
        If you are a Designated Booth Administrators, you will be able to view
        and update Booth Name, Booth Staff, leads, and Abstracts.
        <br />
        <br />
        Have questions or need help? Contact us at{" "}
        <a
          href="mailto:support@planetconnect.com"
          target="_blank"
          rel="noreferrer"
        >
          support@planetconnect.com
        </a>{" "}
        or use the support bubble in the lower left.
      </Typography>
    );

    body = defaultBody;

    if (slug === "exhibitor") {
      body = exhibitorBody;
    }
    if (slug === "host") {
      body = hostBody;
    }

    return (
      <div className={classes.root}>
        <NavBar />
        <Grid
          container
          justifyContent="center"
          spacing={0}
          style={{ marginTop: "65px" }}
        >
          <Grid item xs={12} align="center">
            <img
              src={banner}
              alt="Lobby"
              style={{
                width: "calc(100% + 32px)",
                marginLeft: "-16px",
                display: "block",
                position: "relative",
                padding: 0,
                top: "-5px",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} align="center">
            <br />
            <br />
            {body}
            <br />
            <br />
            <Button
              size="large"
              variant="contained"
              color="primary"
              component={Link}
              to={"/signin"}
              className={classes.button}
            >
              <Typography variant="h6" align="center">
                Sign in
              </Typography>
            </Button>
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(LandingPage));
