import { REMOVE_AUTHENTICATED_USER } from './types';

export default function removeAuthenticatedUser() {
  return dispatch => {
    dispatch(removeAuthenticatedUserAsync())
  }
}

function removeAuthenticatedUserAsync(){
  return {
    type: REMOVE_AUTHENTICATED_USER,
    payload: false
  };
}