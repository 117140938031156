export const abstractGridOptions = [
  { id: "title", numeric: false, disablePadding: false, label: "Title" },
  { id: "seq_no", numeric: false, disablePadding: false, label: "Sequence Number",
  },
  { id: "account_name", numeric: false, disablePadding: false, label: "Is Exhibitor",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "types", numeric: false, disablePadding: false, label: "Submitted Type(s)",
  },
  { id: "topic", numeric: false, disablePadding: false, label: "Topic" },
  { id: "cleared_for_public_view", numeric: false, disablePadding: false, label: "Is Public",
  },
  { id: "abstract_id", numeric: true, disablePadding: false, label: "Actions" },
];
