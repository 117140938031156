import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import getValidUrl from "../../Utilities/getValidUrl";

import axios from "axios";
import { Auth } from "aws-amplify";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    "& .MuiTypography-root": {
      alignSelf: "center",
    },
    "& .MuiChip-root	": {
      alignSelf: "center",
    },
    "& .MuiAccordionSummary-content": {
      margin: theme.spacing(0.5),
    },
  },
  accordionMain: {
    padding: "0px 8px 0px 24px",
    backgroundColor: "#f2f2f2",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "60%",
    flexShrink: 0,
  },
  panelExpanded: {
    "& .MuiGrid-item": {
      textAlign: "center",
    },
  },
  icon: {
    maxHeight: "30vh",
    maxWidth: "100%",
  },

  buttonLearn: {
    minWidth: "90%",
    fontSize: theme.typography.pxToRem(15),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
  selected: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
  },
  selectedText: {
    color: "#FFF",
  },
  textBody: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: "center",
    textAlign: "left",
  },
});

const viewGetFileInNewWindow = async (fullFileUrl) => {
  try {
    if (fullFileUrl.includes("eventhorizon-assets")) {
      const user = await Auth.currentSession();

      let filenamePath = fullFileUrl.split(".com/")[1];

      let dataShape = {
        operation: "getObject",
        file_name: filenamePath,
        bucket_name: "eventhorizon-assets",
      };

      const signedUrl = await axios({
        method: "POST",
        url: "/upload/signed",
        headers: {
          idtoken: user.idToken.jwtToken,
        },
        data: dataShape,
      });

      window.open(signedUrl.data, "_blank");
    } else {
      window.open(getValidUrl(fullFileUrl), "_blank");
    }
  } catch (error) {
    console.log(error);
    window.open(getValidUrl(fullFileUrl), "_blank");
  }
};

class ExhibitProductItem extends Component {
  render() {
    const { classes } = this.props;

    let selectedClassCheck =
      this.props.selectedProductId === this.props.product.product_id
        ? classes.selected
        : null;

    return (
      <Accordion className={classes.root}>
        <AccordionSummary
          className={classes.expansionPanelMain + " " + selectedClassCheck}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6" align="left" className={classes.heading}>
            {this.props.product.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className={classes.panelExpanded}
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {this.props.product.product_image_url ? (
              <Grid
                item
                xs={12}
                sm={12}
                style={{ backgroundColor: "#CCCCCC", width: "100%" }}
              >
                <img
                  className={classes.icon}
                  src={this.props.product.product_image_url}
                  alt=""
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12}>
              <pre
                style={{
                  whiteSpace: "pre-line",
                  wordWrap: "break-word",
                  margin: 0,
                }}
              >
                <Typography className={classes.textBody}>
                  {this.props.product.description}
                </Typography>
              </pre>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-start">
              {this.props.product.product_video_url ? (
                <Grid item xs={6}>
                  <Button
                    className={classes.buttonLearn}
                    variant="contained"
                    target="_blank"
                    // href={getValidUrl(this.props.product.product_video_url)}
                    onClick={() =>
                      viewGetFileInNewWindow(
                        this.props.product.product_video_url
                      )
                    }
                  >
                    Product Video
                  </Button>
                </Grid>
              ) : (
                <React.Fragment />
              )}
              {this.props.product.product_document_url ? (
                <Grid item xs={6}>
                  <Button
                    className={classes.buttonLearn}
                    variant="contained"
                    target="_blank"
                    // href={getValidUrl(this.props.product.product_document_url)}
                    onClick={() =>
                      viewGetFileInNewWindow(
                        this.props.product.product_document_url
                      )
                    }
                  >
                    Product Document
                  </Button>
                </Grid>
              ) : (
                <React.Fragment />
              )}
              {this.props.product.product_url ? (
                <Grid item xs={12}>
                  <Button
                    className={classes.buttonLearn}
                    variant="contained"
                    target="_blank"
                    href={getValidUrl(this.props.product.product_url)}
                    style={{ minWidth: "60%" }}
                  >
                    Learn More
                  </Button>
                </Grid>
              ) : (
                <React.Fragment />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapStateToProps(state) {
  return {
    briefcase: state.briefcase,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // addBriefcaseElement: addBriefcaseElement,
      // deleteBriefcaseElement: deleteBriefcaseElement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ExhibitProductItem));
