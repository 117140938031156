import { SET_ARCHIVED } from "./types";

export default function setArchived(archivedState) {
  return (dispatch) => {
    dispatch(setArchivedAsync(archivedState));
  };
}

function setArchivedAsync(archivedState) {
  return {
    type: SET_ARCHIVED,
    payload: archivedState,
  };
}
