import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
    margin: theme.spacing(2),
    display: "grid",
    textAlign: "center",
  },
});

class SimpleDialog extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     dialogOpen: false,
  //   };
  // }

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth="lg"
        open={this.props.open}
        onClose={(event) => this.props.handleClose(event)}
      >
        <div className={classes.paper}>{this.props.children}</div>
      </Dialog>
    );
  }
}

export default withStyles(useStyles)(SimpleDialog);
