export const abstractGridOptions = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Title', },
  { id: 'seq_no', numeric: false, disablePadding: false, label: 'Sequence Number', },
  { id: 'account_id', numeric: false, disablePadding: false, label: 'Is Exhibitor', },
  // { id: 'account_name', numeric: false, disablePadding: false, label: 'Is Exhibitor', },
  { id: 'submitterName', numeric: false, disablePadding: false, label: 'Submitter', },
  { id: 'presenterName', numeric: false, disablePadding: false, label: 'Presenter', },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', },
  { id: 'types',  numeric: false, disablePadding: false, label: 'Submitted Type(s)', },
  { id: 'cleared_for_public_view', numeric: false, disablePadding: false, label: 'Is Public', },
  { id: 'liked', numeric: false, disablePadding: false, label: 'My Vote', },
  { id: 'count', numeric: false, disablePadding: false, label: 'Total Votes', },
  { id: 'abstract_id', numeric: true, disablePadding: false, label: 'Actions', },      
]