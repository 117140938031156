export const staffGridOptions = [
  {
    id: "photo_url",
    numeric: false,
    disablePadding: false,
    label: "Staff Photo",
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  { id: "title", numeric: false, disablePadding: false, label: "Title" },
  {
    id: "booth_visible",
    numeric: false,
    disablePadding: false,
    label: "Visible in Booth?",
  },
  {
    id: "booth_order",
    numeric: false,
    disablePadding: false,
    label: "Booth Order",
  },
  { id: "attendee_id", numeric: true, disablePadding: false, label: "Actions" },
];
