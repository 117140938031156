import React, { useState } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dialog from "../../Components/Dialog";
import moment from "moment";
import { Formik, Form, Field, useField } from "formik";
import { FileUploader } from "../Shared/FileUploader";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  submitGridItem: {
    position: "fixed",
    bottom: "0px",
    // left: (props) => (props.sidebar?.open ? "120px" : "2px"),
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  },
  submitButton: {
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
}));

const AssetInfoField = ({ ...props }) => {
  const [field, meta] = useField(props);
  if (field.value === null) {
    field.value = "";
  }
  if (field.name === "start_time" || field.name === "end_time") {
    field.value = moment(field.value).format("LLL");
  }
  return (
    <>
      <Field
        className="text-input"
        component={TextField}
        margin="dense"
        variant="outlined"
        fullWidth
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error" style={{ color: "red" }}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

const wait = async function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

let validateURL = (url) => {
  let error;
  const urlExpression =
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
  let regex = new RegExp(urlExpression);
  if (url !== "") {
    if (url.match(regex)) {
      error = "";
    } else {
      error = "Invalid URL";
    }
  }
  return error;
};

export function AssetInfo(props) {
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const classes = useStyles();

  const handleFileUpload = (file, meta, status) => {
    setFile(file);
  };

  return (
    <>
      <Formik
        initialValues={props.currentAbstract}
        onSubmit={async (values, { setSubmitting }) => {
          console.log(values);
          await new Promise((r) => setTimeout(r, 500));

          const user = await Auth.currentSession();

          if (file) {
            let formData = new FormData();
            formData.append("file", file);

            const uploadFile = await axios({
              method: "POST",
              url: "/upload",
              headers: {
                "Content-Type": "multipart/form-data",
                idtoken: user.idToken.jwtToken,
              },
              data: formData,
            });

            values.presentation_pdf = uploadFile.data.Location;
          }

          const response = await axios({
            method: "PUT",
            url: `/zoho/abstracts/${props.currentAbstract.show_id}/${props.currentAbstract.account_id}/${props.currentAbstract.abstract_id}`,
            headers: { idtoken: user.idToken.jwtToken },
            data: {
              values,
            },
          });

          await wait(3000);

          if (response.data) {
            setSuccess(true);
          }

          setSubmitting(false);

          props.getAssets();
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} style={{ marginBottom: "56px" }}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Event Activity Information
                  </Typography>
                  <Divider className={classes.divider} />
                  <AssetInfoField
                    name="title"
                    id="title"
                    label="Abstract Title"
                    type="text"
                    multiline
                    placeholder="Abstract Title"
                    disabled
                  />
                  <AssetInfoField
                    name="content"
                    id="content"
                    label="Abstract"
                    type="text"
                    placeholder="Abstract Content"
                    maxRows={Infinity}
                    multiline
                    disabled
                  />
                  <AssetInfoField
                    name="presentation_video"
                    id="presentation_video"
                    label="Presentation Video"
                    type="text"
                    placeholder="Abstract Video URL"
                    // validate={validateURL}
                    disabled
                  />
                  <AssetInfoField
                    name="status"
                    id="status"
                    label="Status"
                    type="text"
                    placeholder="None"
                    disabled
                  />
                  <AssetInfoField
                    name="accepted_type"
                    id="accepted_type"
                    label="Accepted Type"
                    type="text"
                    placeholder="N/A"
                    disabled
                  />
                  <AssetInfoField
                    name="types"
                    id="types"
                    label="Submitted Type"
                    type="text"
                    placeholder="Submitted Type"
                    disabled
                  />
                  <AssetInfoField
                    name="start_time"
                    id="start_time"
                    label="Start Time"
                    type="text"
                    placeholder="Start Time"
                    disabled
                  />
                  <AssetInfoField
                    name="end_time"
                    id="end_time"
                    label="End Time"
                    type="text"
                    placeholder="End Time"
                    disabled
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "56px" }}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Presentation PDF
                  </Typography>
                  <Divider className={classes.divider} />
                  {props.currentAbstract.presentation_pdf ? (
                    <a
                      href={props.currentAbstract.presentation_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        View PDF
                      </Button>
                    </a>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {/* <Divider className={classes.divider} />
                  <br />
                  <Typography className={classes.header} variant="subtitle1">
                    Update PDF:
                  </Typography>
                  <Typography className={classes.header} variant="subtitle2">
                    Accepts PDF. Max size 50mb.
                  </Typography>
                  <FileUploader
                    handleFileUpload={handleFileUpload.bind(this)}
                    acceptedTypes={[".pdf"]}
                  /> */}
                </Paper>
              </Grid>
            </Grid>
            {/* <Grid container item xs={12} justifyContent='center'> */}
            {/* <Grid
              item
              xs={12}
              className={classes.submitGridItem}
              style={{ left: props.sidebar?.open ? "120px" : "2px" }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                className={classes.submitButton}
                color="primary"
                variant="contained"
                size="large"
              >
                <Typography variant="h6">
                  {isSubmitting ? "Saving..." : "Save Changes"}
                </Typography>
              </Button>
            </Grid> */}
            {/* </Grid> */}
          </Form>
        )}
      </Formik>
      <Dialog open={success} handleClose={() => props.handleEditClose()}>
        <Typography variant="h5">Success!</Typography>
        <Typography>Your Event Activity has been updated.</Typography>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.handleEditClose()}
        >
          X Close
        </Button>
      </Dialog>
    </>
  );
}
