import React from "react";
import Button from "@material-ui/core/Button";

export function AbstractGridActionButtons(value, key, onEdit, onDelete) {
  return (
    <Button
      key={key}
      variant="contained"
      color="primary"
      onClick={onEdit}
      fullWidth
    >
      View
    </Button>
  );
}
