import React from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Img } from "react-image";

import Dropzone from "react-dropzone-uploader";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    overflow: "auto",
    padding: 10,
    border: "1px dotted grey",
  },
  fileUploaded: {
    background: "#31da14",
    padding: theme.spacing(1, 3),
    width: "100%",
    borderRadius: theme.spacing(1),
  },
}));

export function FileUploader(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);

  // removed because we don't actually need to upload it
  // we upload it ourselves
  //   const getUploadParams = ({ meta }) => {
  //     return { url: "https://httpbin.org/post" };
  //   };

  const handleChangeStatus = ({ meta, file }, status, fileWithMeta) => {
    if (status === "rejected_file_type") {
      setErrorMessage("Invalid file type");
    }
    if (status === "done" && file.size < maxSize && !file.name.includes(" ")) {
      props.handleFileUpload(file, meta, status);
      setErrorMessage(null);
    }
  };

  const checkFileSize = (fileWithMeta) => {
    if (fileWithMeta.meta.size > maxSize) {
      fileWithMeta.remove();
      setErrorMessage("File too large");
    }
    if (fileWithMeta.meta.name.includes(" ")) {
      // console.log('spaces found');
      fileWithMeta.remove();
      setErrorMessage("Please remove spaces from filename and resubmit");
    }
  };

  const maxSize = 52428800;

  return (
    <div>
      <Dropzone
        accept={
          props.acceptedTypes ? props.acceptedTypes.join(", ") : "image/*, .pdf"
        }
        addClassNames={{ dropzone: classes.dropZone }}
        // getUploadParams={getUploadParams}
        maxFiles={1}
        minSize={0}
        maxSize={maxSize} // 50mb.
        multiple={false}
        onChangeStatus={handleChangeStatus}
        PreviewComponent={Preview}
        inputContent="Click to Add / Drag and Drop File"
        validate={(fileWithMeta) => checkFileSize(fileWithMeta)}
        // submitButtonContent='Upload'
        disabled={(files) =>
          files.some((f) =>
            ["preparing", "getting_upload_params", "uploading"].includes(
              f.meta.status
            )
          )
        }
      />
      <Typography variant="body1" color="error">
        {errorMessage}
      </Typography>
    </div>
  );
}

const Preview = ({ meta }) => {
  const classes = useStyles();
  const { name, percent, status, previewUrl } = meta;

  return (
    <div className={classes.fileUploaded}>
      <Typography gutterBottom variant="h5">
        {name}
      </Typography>
      <Typography gutterBottom variant="body1">
        100% - {status}
        <br />
        File Captured
        {/* {Math.round(percent)}% - {status} when we used getUploadParams */}
      </Typography>
      {previewUrl && <Img src={[previewUrl]} width="100%" />}
    </div>
  );
};
