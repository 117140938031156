export const productGridOptions = [
  { id: "name", numeric: false, disablePadding: false, label: "Product Name" },
  {
    id: "product_url",
    numeric: false,
    disablePadding: false,
    label: "Product URL",
  },
  {
    id: "product_video_url",
    numeric: false,
    disablePadding: false,
    label: "Video URL",
  },
  {
    id: "is_public",
    numeric: false,
    disablePadding: false,
    label: "Is Public",
  },
  {
    id: "booth_order",
    numeric: false,
    disablePadding: false,
    label: "Product Order",
  },
  { id: "product_id", numeric: true, disablePadding: false, label: "Actions" },
];
