import React, { Component } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
// import Box from '@material-ui/core/Box';
// import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { ColorPicker } from '../Shared/ColorPicker';
import Dialog from '../../Components/Dialog';
// import { Link } from 'react-router-dom';
import { Formik, Form, Field, useField,
    //  FieldArray 
    } from 'formik';
// import { Img } from 'react-image';
// import { FileUploader } from '../Shared/FileUploader';


// Configure Redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    header: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        padding: theme.spacing(2),
        // marginBottom: theme.spacing(3),
    },
    submitGridItem: {
        position: 'fixed',
        bottom: '16px',
        width: '100%',
        zIndex: 1,
    },
    submitButton: {
        color: "white", 
        width: '70%', 
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        marginLeft: '10%',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
      },
});

const AttendeeInfoField = ({ ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <Field> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    return (
        <>
            <Field
                className="text-input"
                component={TextField}
                margin='dense'
                variant='outlined'
                fullWidth
                {...field} {...props}
            />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

class CompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: null,
            success: null,
            tagFieldFocused: false,
            file: null,
        };
      }

      componentDidMount(){

      }

      async wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
      }

    onKeyDown =(keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      }

      render(){
        const { classes } = this.props;


        let baseAttendee = {
            first_name: '',
            last_name: '', 
            email: '', 
            account_name: '', 
            title: '', 
            // address_street: '',
            // address_city: '',
            // address_state: '',
            // address_zip: '',
            // address_country: '',
            roles: 'Pharma Attendee', 
            // department: '', 
            // expertise_area: '', 
            // show_name: '', 
            // attendee_id: '', 
            // badge_key: '', 
            // contact_id: '', 
            // exhibitor_lead_retrieval: '', 
            // created_time: '', 
            // days: '', 
            // is_complimentary: '', 
            // note: '', 
    
        }

    return (
        <div className={classes.root}>
            <Formik
                initialValues= {baseAttendee}
                // initialValues= {{
                //     companyNameForBooth: this.state.company.account_name,
                //     companyUrl: this.state.company.website,
                //     sponsorshipLevel: this.state.company.level_requested,
                //     companyDescription: this.state.company.main_text,
                //     currentTag: '',
                //     tags: this.state.company.linked_in_tags,
                //     primaryColor: this.state.company.primary_color,
                //     secondaryColor: this.state.company.secondary_color
                // }}
                onSubmit={
                    async (values, { setSubmitting }) => {
                    
                    await new Promise(r => setTimeout(r, 500));

                    const user = await Auth.currentSession()
// temporary disabled so I can test submit without accidentally modifying the database
                    // if(this.state.file){

                    //     let formData = new FormData();
                    //     formData.append('file', file);
                
                    //     const uploadFile = await axios({
                    //         method: "PUT",
                    //         url: '/upload',
                    //         headers: { 
                    //             'Content-Type': 'multipart/form-data',
                    //             idtoken: user.idToken.jwtToken 
                    //         },
                    //         data: formData
                    //     });

                    //     // values.photo_url = uploadFile.data.Location;

                    // }
console.log(this.props.showId);
console.log(this.props.accountId);
                    const response = await axios({
                        method: "POST",
                        url: '/show-attendees/',
                        headers: { idtoken: user.idToken.jwtToken },
                        data: {
                            values,
                            showId: this.props.showId,
                            accountId: this.props.accountId,
                            // attendeeId: props.currentStaff ? props.currentStaff.attendee_id : null,
                            // contactId: props.currentStaff ? props.currentStaff.contact_id : null,
                        }
                    });

                    await this.wait(3000);
// let response = {data: true}
console.log(response);
console.log(response.data);
                    if(response.data === true){
                        this.setState({
                            success: true,
                        })
                    } else {
                        alert("Your attendee could not be created")
                    }
// console.log(values);
                    setSubmitting(false);
                }
            }
                >
                {({ isSubmitting, values, handleChange }) => (
                    <Form onKeyDown={this.onKeyDown}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper>
                                    <Typography className={classes.center} variant='h4' >
                                        Create New Attendee
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <Paper className={classes.paper}>
                                    <Typography className={classes.header} variant='h5'>Attendee Information</Typography>
                                        <AttendeeInfoField
                                            name='first_name'
                                            id='first_name'
                                            label='First Name'
                                            type='text'
                                            placeholder='First Name'
                                        />
                                        <AttendeeInfoField
                                            name='last_name'
                                            id='last_name'
                                            label='Last Name'
                                            type='text'
                                            placeholder='Last Name'
                                        />
                                        <AttendeeInfoField
                                            name='email'
                                            id='email'
                                            label='Email Address'
                                            type='email'
                                            placeholder='Email Address'
                                        />
                                        <AttendeeInfoField
                                            name='account_name'
                                            id='account_name'
                                            label='Company'
                                            type='text'
                                            placeholder='Company'
                                        />
                                        <AttendeeInfoField
                                            name='title'
                                            id='title'
                                            label='Title'
                                            type='text'
                                            placeholder='Title'
                                        />
                                        {/* <AttendeeInfoField
                                            name='contactPhoneNumber'
                                            id='contactPhoneNumber'
                                            label='Phone Number'
                                            type='text'
                                            placeholder='Phone Number'
                                        /> */}
                                </Paper> 
                                
                            </Grid>
                            <Grid item xs={12} md={6} >    
                            {/* <Paper className={classes.paper}>
                                    <Typography className={classes.header} variant='h5'>Address</Typography>
                                    <AttendeeInfoField
                                        name='address_street'
                                        id='address_street'
                                        label='Street Address'
                                        type='text'
                                        placeholder='Street Address'
                                    />
                                    <AttendeeInfoField
                                        name='address_city'
                                        id='address_city'
                                        label='City'
                                        type='text'
                                        placeholder='City'
                                    />
                                    <AttendeeInfoField
                                        name='address_state'
                                        id='address_state'
                                        label='State'
                                        type='text'
                                        placeholder='State'
                                    />
                                    <AttendeeInfoField
                                        name='address_zip'
                                        id='address_zip'
                                        label='Zip Code'
                                        type='text'
                                        placeholder='Zip Code'
                                    />
                                    <AttendeeInfoField
                                        name='address_country'
                                        id='address_country'
                                        label='Country'
                                        type='text'
                                        placeholder='Country'
                                    />
                                </Paper>                                   */}
                            </Grid>
                            {/* <Grid container item xs={12} justify='center' > */}
                                    <Grid item xs={10} sm={12} className={classes.submitGridItem}>
                                        <Button
                                            type='submit'
                                            disabled={isSubmitting}
                                            className={classes.submitButton}
                                            color='primary'
                                            variant='contained'
                                            size='large'
                                        >
                                            <Typography variant='h6'>

                                            {isSubmitting ? 'Submitting' : 'Submit'}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                {/* </Grid> */}
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Dialog open={this.state.success ? this.state.success : false} handleClose={()=> this.setState({success: null})}>
            <Typography>
                    Success!
                </Typography>
                <Button onClick={()=> this.setState({success: null})}>
                    Updated
                </Button>
            </Dialog>
        </div>
    );
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        event: state.event,
    }
  }
  export default connect(mapStateToProps, null)(withStyles(useStyles)(CompanyInfo));