import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { resourceGridOptions } from './resourceGridOptions';
import { DataTable } from '../Shared/DataTable';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    }
}));

export function ResourcesList() {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [resources, setResources] = useState([
        {
            resource_id: 1,
            name: 'Company Profile',
            type: 'pdf',
            exhibition_id: 'Strand Life Sciences',
            exhibition_title: '1512312314123412123',
            account_id: 'test events',
            account_name: 'Jamesh',
            url: 'https://events.planetconnect.com/wp-content/uploads/exhibit_resources/1691992000035385078/Strand Life Sciences/Strand-Life-Sciences.pdf',
            description: 'Software Engineer',
            show_id: 'Virtual',
            is_public: 'Booth Staff'
        }
    ]);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                    <Paper className={classes.paper}>
                        <h2>Resources</h2>
                        <DataTable initialGridOptions={resourceGridOptions} initialRowData={resources} />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}