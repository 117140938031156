import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "@material-ui/core/Button";
// import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";

// table components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
// import trackAction from "../../Utilities/tracker";

// reporting components
// import Reporting from "./Reporting.Leads";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";
// import getChatMessages from "../../Store/Actions/getChatMessages";
// import addChatSubscription from "../../Store/Actions/addChatSubscription";
// import setChatMembership from "../../Store/Actions/setChatMembership";
// import selectActivePrivateChat from "../../Store/Actions/selectActivePrivateChat";
// import setChatbar from "../../Store/Actions/setChatbar";
// import viewPrivateChat from "../../Store/Actions/viewPrivateChat";
// import trackAction from "../../Store/Actions/tracker";
// import { setChatWindow } from "../../Store/Reducers/twilio";
// import twilioCreateJoinOneOnOneConversation from "../../Store/Actions/twilioCreateJoinOneOnOneConversation";
// import getLeadsArray from "../../Store/Actions/getLeadsArray";

// Utils
// import getTwilioOneOnOneConversation from "../../Utilities/getTwilioOneOnOneConversation";

// Download to CSV
import { CSVLink } from "react-csv";

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  singleLead: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  downloadLink: {
    textDecoration: "none",
  },
  downloadButton: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "100%",
    padding: theme.spacing(1),
    margin: "auto",
    "& .MuiButton-textSizeLarge": {
      size: "large",
    },
  },
  downloadButtonContainer: {
    paddingLeft: "16px",
    // intentionally using 'sm' below
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingTop: theme.spacing(2),
    },
  },
  chatButtonContainer: {
    textAlign: "end",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
});

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadsArray: [],
      leadsArrayObjects: [],
      leadsReporting: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    try {
      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url: `/audit/${this.props.event.event.show_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            order_by: "visit_time",
            order_dir: "asc",
          },
        })
          .then((response) => {
            let filteredData;

            if (this.props.user.user.exhibitor_lead_retrieval === "View Own") {
              for (let i = 0; i < response.data.data.length; i++) {
                if (response.data.data[i].resource_type === "Exhibit") {
                  filteredData.push(response.data.data[i]);
                } else {
                  for (
                    let j = 0;
                    j < response.data.data[i].contacts.length;
                    j++
                  ) {
                    if (
                      response.data.data[i].contacts[j].email ===
                      this.props.user.user.email
                    ) {
                      filteredData.push(response.data.data[i]);
                    }
                  }
                }
              }
            } else {
              filteredData = response.data.data;
            }

            let arrayCSV = filteredData.map((event) => {
              return {
                "Account Owner": event.resource_owner,
                Action: event.action,
                Type: event.resource_type,
                "Resource Name": event.resource_name,
                "User Account": event.visitor_account_name,
                Email: event.visitor_email,
                "First Name": event.visitor_first_name,
                "Last Name": event.visitor_last_name,
                "Job Title": event.visitor_title,
                Department: event.visitor_department,
                Site: event.visitor_site,
                "Address 1": event.visitor_street,
                "Address 2": null,
                City: event.visitor_city,
                "Zip Code": '=""' + event.visitor_zip + '""',
                State: event.visitor_state,
                Country: event.visitor_country,
                "Time Visited": event.visit_time,
              };
            });

            let users = [];

            filteredData.map((event) => {
              let presence = users
                .map((user) => user.email)
                .indexOf(event.visitor_email);

              if (presence === -1) {
                users.push({
                  firstName: event.visitor_first_name,
                  lastName: event.visitor_last_name,
                  email: event.visitor_email,
                  company: event.visitor_account_name,
                  title: event.visitor_title,
                  site: event.visitor_site,
                  department: event.visitor_department,
                  actions: [
                    {
                      action: event.action,
                      visited: event.url_visited,
                      type: event.resource_type,
                      name: event.resource_name,
                      id: event.resource_id,
                      createdAt: event.visit_time,
                    },
                  ],
                });
              } else {
                users[presence].actions.push({
                  action: event.action,
                  visited: event.url_visited,
                  type: event.resource_type,
                  name: event.resource_name,
                  id: event.resource_id,
                  createdAt: event.visit_time,
                });
              }

              return null;
            });

            this.setState({
              leadsArray: arrayCSV,
              leadsReporting: filteredData,
              leadsArrayObjects: users,
              isLoading: false,
            });
          })
          .catch((error) => {
            if (this.props.debug.debug) {
              alert(`Error: Could not display Leads Report. ${error}`);
            }
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }

    // const event = {
    //   action: "Viewed",
    //   resource_type: "Leads",
    //   resource_id: null,
    //   url_visited: "/leads",
    //   resource_account_id: null,
    //   resource_account_name: null,
    //   resource_name: null,
    // };

    // trackAction(event);
    // this.props.trackAction(event);
  }

  render() {
    const { classes } = this.props;

    let pageTitle = "Leads";

    let contentLoading = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading Leads...
        </Typography>
      </Grid>
    );

    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Leads Found
        </Typography>
      </Grid>
    );

    let contentLoaded = this.state.leadsArrayObjects.map((lead, index) => {
      return (
        <Accordion key={index} className={classes.singleLead}>
          <AccordionSummary
            className={classes.expansionPanelMain}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6" component="div">
              {lead.firstName + " " + lead.lastName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" align="left">
                  {lead.email ? (
                    <React.Fragment>
                      <b>Email:</b> {lead.email}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.company ? (
                    <React.Fragment>
                      <b>Company:</b> {lead.company}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.title ? (
                    <React.Fragment>
                      <b>Title:</b> {lead.title}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.site ? (
                    <React.Fragment>
                      <b>Site:</b> {lead.site}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.department ? (
                    <React.Fragment>
                      <b>Department:</b> {lead.department}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
              </Grid>
              {/* removed due to no access to twilio */}
              {/* {this.props.user.user.email !== lead.email ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.chatButtonContainer}
                >
                  {!getTwilioOneOnOneConversation(
                    this.props.conversations,
                    this.props.user.user.email,
                    lead.email
                  ) ? (
                    //|| this.props.chat.indexOf(lead.email)
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          // this.selectPrivateChat(lead.email)
                          this.props.setChatWindow(true);
                          this.props.twilioCreateJoinOneOnOneConversation(
                            lead.email
                          );
                        }}
                      >
                        Start New Chat
                      </Button>
                    </FormControl>
                  ) : (
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          console.log(
                            'TEST',
                            this.props.conversations,
                            this.props.user.user.email,
                            lead.email
                          );
                          // this.selectPrivateChat(lead.email)
                          this.props.setChatWindow(true);
                          this.props.twilioCreateJoinOneOnOneConversation(
                            lead.email
                          );
                        }}
                      >
                        View Private Chat
                      </Button>
                    </FormControl>
                  )}
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )} */}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lead.actions.map((companyLeads, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell align="center">
                              {companyLeads.name}
                            </TableCell>
                            <TableCell align="center">
                              {companyLeads.type}
                            </TableCell>
                            <TableCell align="center">
                              {`${moment(companyLeads.createdAt).format(
                                "MMMM Do, h:mm a"
                              )}`}
                            </TableCell>
                            <TableCell align="center">
                              {companyLeads.action}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
    let bodyContent = contentLoading;
    if (!this.state.isLoading) {
      if (this.state.leadsArrayObjects.length === 0) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = contentLoaded;
      }
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12} md={9}>
            <Paper>
              <Typography
                variant="h4"
                align="center"
                style={{ padding: "12px" }}
              >
                Your {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} className={classes.downloadButtonContainer}>
            <CSVLink
              data={this.state.leadsArray}
              filename={"my-leads.csv"}
              className={classes.downloadLink}
              target="_blank"
            >
              <Button
                variant="contained"
                size="large"
                className={classes.downloadButton}
              >
                <GetAppIcon style={{ marginRight: "8px" }} />
                Download {pageTitle}
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        {/* <Grid container className={classes.header}>
          <Grid item xs={12}>
            <Reporting leadsReporting={this.state.leadsReporting} />
          </Grid>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Paper>
            <Typography variant="h4" align="center" style={{ padding: "12px" }}>
              Individual Leads
            </Typography>
          </Paper>
        </Grid>
        {bodyContent}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    chat: state.chat,
    event: state.event,
    // conversations: state.twilio.conversations,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Leads));
