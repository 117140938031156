export const acceptedDeclinedGridOptions = [
    
        { id: 'level_requested', numeric: false, disablePadding: false, label: 'Level', },
        { id: 'count',  numeric: false, disablePadding: false, label: 'Count', },     
]
export const prospectiveGridOptions = [
    
        { id: 'stage', numeric: false, disablePadding: false, label: 'Stage', },
        { id: 'count',  numeric: false, disablePadding: false, label: 'Count', },     
]
export const recentlyAcceptedGridOptions = [
    
        { id: 'account_name', numeric: false, disablePadding: false, label: 'Account Name', },
        { id: 'level_requested',  numeric: false, disablePadding: false, label: 'Level Requested', },     
]
export const recentlyDeclinedGridOptions = [
    
        { id: 'account_name', numeric: false, disablePadding: false, label: 'Account Name', },
        { id: 'level_requested',  numeric: false, disablePadding: false, label: 'Level Requested', },     
        { id: 'decline_reasons',  numeric: false, disablePadding: false, label: 'Decline Reason', },     
]
export const singleLevelGridOptions = [
    
        { id: 'account_name', numeric: false, disablePadding: false, label: 'Account Name', },
        { id: 'contact_first_name', numeric: false, disablePadding: false, label: 'First Name', },
        { id: 'contact_last_name', numeric: false, disablePadding: false, label: 'Last Name', },
        { id: 'level_requested',  numeric: false, disablePadding: false, label: 'Level Requested', },     
        { id: 'stage',  numeric: false, disablePadding: false, label: 'Stage', },     
        { id: 'probability',  numeric: false, disablePadding: false, label: 'Probability', },     
        { id: 'nda',  numeric: false, disablePadding: false, label: 'NDA', },     
        { id: 'modified_time',  numeric: false, disablePadding: false, label: 'Modified Time', },     
]