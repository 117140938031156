import React from 'react';
import { useSelector } from "react-redux";
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    marginLeft: 193,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }
}));

export function MainContent({ children }) {
  const classes = useStyles();
  const drawerOpen = useSelector((state) => state.navigation.open);

  return (
    <main className={clsx({ [classes.drawerOpen]: drawerOpen })}>
      <Box padding={12}>
        {children}
      </Box>
    </main>
  );
}
