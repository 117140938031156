import React from "react";
// import axios from 'axios';
// import { Auth } from 'aws-amplify';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// import Dialog from '../../Components/Dialog';

import { Formik, Form, Field, useField } from "formik";
// import { FileUploader } from '../Shared/FileUploader';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  submitGridItem: {
    position: "fixed",
    bottom: "16px",
    width: "100%",
    zIndex: 1,
  },
  submitButton: {
    color: "white",
    width: "70%",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    marginLeft: "10%",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
}));

const AbstractInfoField = ({ ...props }) => {
  const [field] = useField(props);
  if (field.value === null) {
    field.value = "";
  }
  if (field.name === "start_time") {
    if (field.value === "") {
    } else {
      field.value = moment(field.value, "HH:mm:ss").format("LT");
    }
  }

  return (
    <>
      <Field
        className="text-input"
        component={TextField}
        margin="dense"
        variant="outlined"
        fullWidth
        {...field}
        {...props}
      />
    </>
  );
};

// const wait = async function (ms) {
//     return new Promise(resolve => {
//       setTimeout(resolve, ms);
//     });
//   }

export function AbstractInfo(props) {
  // const [success, setSuccess] = useState(false);
  // const [file, setFile] = useState(null)
  const classes = useStyles();

  // const handleFileUpload = (file, meta, status) => {
  //     setFile(file)
  // }
  if (props.currentAbstract.contacts.length > 0) {
    props.currentAbstract.presenters = props.currentAbstract.contacts.filter(
      (contact) => contact.roles.join().toLowerCase().includes("presenter")
    );
    props.currentAbstract.authors = props.currentAbstract.contacts.filter(
      (contact) => contact.roles.join().toLowerCase().includes("author")
    );
    props.currentAbstract.submitters = props.currentAbstract.contacts.filter(
      (contact) => contact.roles.join().toLowerCase().includes("submitter")
    );
  }
  // console.log(props.currentAbstract)
  // props.currentAbstract.contacts.forEach(contact =>  {
  //     console.log(contact.roles.join().toLowerCase().includes('presenter'))
  // })

  return (
    <>
      <Formik
        initialValues={props.currentAbstract}
        // onSubmit={async (values, { setSubmitting }) => {
        //     console.log(values);
        //     await new Promise(r => setTimeout(r, 500));

        //     const user = await Auth.currentSession()

        //     if(file){

        //         let formData = new FormData();
        //         formData.append('file', file);

        //         const uploadFile = await axios({
        //             method: "POST",
        //             url: '/upload',
        //             headers: {
        //                 'Content-Type': 'multipart/form-data',
        //                 idtoken: user.idToken.jwtToken
        //             },
        //             data: formData
        //         });

        //         values.presentation_pdf = uploadFile.data.Location;

        //     }

        //     const response = await axios({
        //         method: 'PUT',
        //         url: '/abstracts/',
        //         headers: { idtoken: user.idToken.jwtToken },
        //         data: {
        //             values
        //         },
        //     });

        //     await wait(3000);

        //     if(response.data === true){
        //         setSuccess(true)
        //     } else {
        //         alert("Your Abstract could not be updated")
        //     }

        //     setSubmitting(false);

        //     props.getAbstracts()

        // }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.form}>
            <Grid container spacing={2} style={{ marginBottom: "56px" }}>
              <Grid item xs={12} md={8}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Abstract Information
                  </Typography>
                  <Divider className={classes.divider} />
                  <AbstractInfoField
                    disabled
                    name="title"
                    id="title"
                    label="Abstract Title"
                    type="text"
                    multiline
                    placeholder="Abstract Title"
                  />
                  <AbstractInfoField
                    disabled
                    name="accepted_type"
                    id="accepted_type"
                    label="Accepted Type"
                    type="text"
                    multiline
                    placeholder="Accepted Type"
                  />
                  <AbstractInfoField
                    disabled
                    name="types"
                    id="types"
                    label="Submitted Type(s)"
                    type="text"
                    multiline
                    placeholder="Submitted Type(s)"
                  />
                  <AbstractInfoField
                    disabled
                    name="cleared_for_public_view"
                    id="cleared_for_public_view"
                    label="Cleared for Public View"
                    type="text"
                    multiline
                    placeholder="Cleared for Public View"
                  />
                  <AbstractInfoField
                    disabled
                    name="area_of_science"
                    id="area_of_science"
                    label="Area of Science"
                    type="text"
                    multiline
                    placeholder="Area of Science"
                  />
                  <AbstractInfoField
                    disabled
                    name="topic"
                    id="topic"
                    label="Topic"
                    type="text"
                    multiline
                    placeholder="Topic"
                  />
                  <AbstractInfoField
                    disabled
                    name="content"
                    id="content"
                    label="Abstract"
                    type="text"
                    placeholder="Abstract Content"
                    maxRows={Infinity}
                    multiline
                  />
                  <AbstractInfoField
                    disabled
                    name="presentation_video"
                    id="presentation_video"
                    label="Presentation Video URL"
                    type="text"
                    placeholder="Abstract Video URL"
                  />
                  {/* <AbstractInfoField
                                    disabled
                                        name='title'
                                        id='title'
                                        label='Abstract'
                                        type='text'
                                        multiline
                                        placeholder='Abstract'
                                    />
                                    <AbstractInfoField
                                    disabled
                                        name='title'
                                        id='title'
                                        label='Abstract'
                                        type='text'
                                        multiline
                                        placeholder='Abstract'
                                    />
                                    <AbstractInfoField
                                    disabled
                                        name='title'
                                        id='title'
                                        label='Abstract'
                                        type='text'
                                        multiline
                                        placeholder='Abstract'
                                    /> */}
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Presentation PDF
                  </Typography>
                  <Divider className={classes.divider} />
                  {props.currentAbstract.presentation_pdf ? (
                    <a
                      href={props.currentAbstract.presentation_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        View PDF
                      </Button>
                    </a>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  {/* <p>Update PDF:</p>
                                    <FileUploader 
                                    handleFileUpload = {handleFileUpload.bind(this)}
                                    /> */}
                </Paper>
              </Grid>
              <Grid item xs={4}>
                {props.currentAbstract.presenters.map((presenter, index) => {
                  return (
                    <Paper className={classes.paper} key={`presenter${index}`}>
                      <Typography className={classes.header} variant="h5">
                        Presenter Info
                      </Typography>
                      <Divider className={classes.divider} />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].first_name`}
                        id={`presenters[${index}].first_name`}
                        label="Presenter First Name"
                        type="text"
                        multiline
                        placeholder="Presenter First Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].last_name`}
                        id={`presenters[${index}].last_name`}
                        label="Presenter Last Name"
                        type="text"
                        multiline
                        placeholder="Presenter Last Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].account_name`}
                        id={`presenters[${index}].account_name`}
                        label="Presenter Account"
                        type="text"
                        multiline
                        placeholder="Presenter Account"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].title`}
                        id={`presenters[${index}].title`}
                        label="Presenter Title"
                        type="text"
                        multiline
                        placeholder="Presenter Title"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].department`}
                        id={`presenters[${index}].department`}
                        label="Presenter Department"
                        type="text"
                        multiline
                        placeholder="Presenter Department"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].site`}
                        id={`presenters[${index}].site`}
                        label="Presenter Site"
                        type="text"
                        multiline
                        placeholder="Presenter Site"
                      />
                    </Paper>
                  );
                })}
              </Grid>
              <Grid item xs={4}>
                {props.currentAbstract.authors.map((author, index) => {
                  return (
                    <Paper className={classes.paper} key={`author${index}`}>
                      <Typography className={classes.header} variant="h5">
                        Author Info
                      </Typography>
                      <Divider className={classes.divider} />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].first_name`}
                        id={`authors[${index}].first_name`}
                        label="Author First Name"
                        type="text"
                        multiline
                        placeholder="Author First Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].last_name`}
                        id={`authors[${index}].last_name`}
                        label="Author Last Name"
                        type="text"
                        multiline
                        placeholder="Author Last Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].account_name`}
                        id={`authors[${index}].account_name`}
                        label="Author Account"
                        type="text"
                        multiline
                        placeholder="Author Account"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].title`}
                        id={`authors[${index}].title`}
                        label="Author Title"
                        type="text"
                        multiline
                        placeholder="Author Title"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].department`}
                        id={`authors[${index}].department`}
                        label="Author Department"
                        type="text"
                        multiline
                        placeholder="Author Department"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].site`}
                        id={`authors[${index}].site`}
                        label="Author Site"
                        type="text"
                        multiline
                        placeholder="Author Site"
                      />
                    </Paper>
                  );
                })}
              </Grid>
              <Grid item xs={4}>
                {props.currentAbstract.submitters.map((submitter, index) => {
                  return (
                    <Paper className={classes.paper} key={`submitter${index}`}>
                      <Typography className={classes.header} variant="h5">
                        Submitter Info
                      </Typography>
                      <Divider className={classes.divider} />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].first_name`}
                        id={`submitters[${index}].first_name`}
                        label="Submitter First Name"
                        type="text"
                        multiline
                        placeholder="Submitter First Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].last_name`}
                        id={`submitters[${index}].last_name`}
                        label="Submitter Last Name"
                        type="text"
                        multiline
                        placeholder="Submitter Last Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].account_name`}
                        id={`submitters[${index}].account_name`}
                        label="Submitter Account"
                        type="text"
                        multiline
                        placeholder="Submitter Account"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].title`}
                        id={`submitters[${index}].title`}
                        label="Submitter Title"
                        type="text"
                        multiline
                        placeholder="Submitter Title"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].department`}
                        id={`submitters[${index}].department`}
                        label="Submitter Department"
                        type="text"
                        multiline
                        placeholder="Submitter Department"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].site`}
                        id={`submitters[${index}].site`}
                        label="Submitter Site"
                        type="text"
                        multiline
                        placeholder="Submitter Site"
                      />
                    </Paper>
                  );
                })}
              </Grid>
            </Grid>
            {/* <Grid container item xs={12} justify='center'> */}
            {/* <Grid item xs={10} sm={12} className={classes.submitGridItem}>
                                <Button
                                    type='submit'
                                    disabled={isSubmitting}
                                    className={classes.submitButton}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                >
                                    <Typography variant='h6'>

                                    {isSubmitting ? 'Submitting' : 'Submit'}
                                    </Typography>
                                </Button>
                            </Grid> */}
            {/* </Grid> */}
          </Form>
        )}
      </Formik>
      {/* <Dialog open={success} handleClose={()=> props.handleEditClose()}>
            <Typography>
                    Success!
                </Typography>
                <Button onClick={()=> props.handleEditClose()}>
                    Updated
                </Button>
            </Dialog> */}
    </>
  );
}
