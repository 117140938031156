import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state
 */
const initialState = {
    open: true,
};

/**
 * Initial reducer slice
 */
const navSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        drawerOpened(state, action) {
            state.open = action.payload;
        },
        prepare(open) {
            return {
                payload: { open }
            }
        }
    }
})

/**
 * Export Actions
 */
export const { drawerOpened } = navSlice.actions

/**
 * Export reducer
 */
export default navSlice.reducer;