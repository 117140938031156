export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_CHATBAR = "SET_CHATBAR";

export const SET_AUTHENTICATED_USER = "SET_AUTHENTICATED_USER";
export const REMOVE_AUTHENTICATED_USER = "REMOVE_AUTHENTICATED_USER";

export const SET_USER_ROLE = "SET_USER_ROLE";
export const REMOVE_USER_ROLE = "REMOVE_USER_ROLE";

export const SET_USER_ACCOUNT_ID = "SET_USER_ACCOUNT_ID";
export const REMOVE_USER_ACCOUNT_ID = "REMOVE_USER_ACCOUNT_ID";

export const SET_EVENT_ID = "SET_EVENT_ID";
export const REMOVE_EVENT_ID = "REMOVE_EVENT_ID";

export const SET_ARCHIVED = "SET_ARCHIVED";
