import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

import { Link } from "react-router-dom";
import { Formik, Form, Field, useField } from "formik";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  root: {
    // display: "flex",
    // flexWrap: "wrap",
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  center: {
    textAlign: "center",
    padding: theme.spacing(2),
    // marginBottom: theme.spacing(3),
  },
  staffPasses: {},
  productLimit: {},
});

const CompanyInfoField = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <Field> and alse replace ErrorMessage entirely.x
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      setValue(e.target.value + "\r\n");
    }
  };

  return (
    <Grid container>
      <Grid item xs>
        <Field
          className="text-input"
          component={TextField}
          margin="dense"
          variant="outlined"
          fullWidth
          onKeyDown={props.minRows ? handleOnKeyDown : null}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error" style={{ color: "red" }}>
            {meta.error}
          </div>
        ) : null}
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title={props.tooltip} placement="right">
          <InfoIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      maxStaffVirtual: null,
      maxStaffPhysical: null,
      availableStaffVirtual: null,
      availableStaffPhysical: null,
      purchasableStaffVirtual: null,
      purchasableStaffPhysical: null,
      usedStaffVirtual: null,
      usedStaffPhysical: null,
      priceStaffVirtual: null,
      priceStaffPhysical: null,
      maxProduct: null,
      productsUsed: null,
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getOrderSummary();
    }
  }

  async getOrderSummary() {
    try {
      await this.setState({
        company: null,
        maxStaffVirtual: null,
        maxStaffPhysical: null,
        availableStaffVirtual: null,
        availableStaffPhysical: null,
        purchasableStaffVirtual: null,
        purchasableStaffPhysical: null,
        usedStaffVirtual: null,
        usedStaffPhysical: null,
        priceStaffVirtual: null,
        priceStaffPhysical: null,
        maxProduct: null,
        productsUsed: null,
      });

      let user = await Auth.currentSession();

      let companyInfo = await axios({
        method: "get",
        url:
          `/exhibitions/` +
          this.props.event.event.show_id +
          "/" +
          this.props.user.user.account_id +
          "/" +
          this.props.event.event.exhibition_id,
        headers: { idtoken: user.idToken.jwtToken },
      });
      if (
        companyInfo.data[0] === undefined ||
        companyInfo.data[0].length === 0
      ) {
        return null;
      }

      let boothInfo = await axios({
        method: "get",
        url:
          `/exhibitions/` +
          this.props.event.event.show_id +
          "/" +
          this.props.user.user.account_id +
          "/" +
          this.props.event.event.exhibition_id,
        headers: { idtoken: user.idToken.jwtToken },
      });

      let productInfo = await axios({
        method: "get",
        url:
          `/products/` +
          this.props.event.event.show_id +
          "/" +
          this.props.event.event.exhibition_id,
        headers: { idtoken: user.idToken.jwtToken },
        params: {
          order_by: "booth_order",
          order_dir: "asc",
        },
      });

      this.setState({
        company: companyInfo.data[0],
        maxStaffVirtual: boothInfo.data[0].max_staff,
        maxStaffPhysical: boothInfo.data[0].max_staff_physical,
        availableStaffVirtual: boothInfo.data[0].available_staff_virtual,
        availableStaffPhysical: boothInfo.data[0].available_staff_physical,
        purchasableStaffVirtual: boothInfo.data[0].purchasable_staff_virtual,
        purchasableStaffPhysical: boothInfo.data[0].purchasable_staff_physical,
        usedStaffVirtual: boothInfo.data[0].used_staff_virtual,
        usedStaffPhysical: boothInfo.data[0].used_staff_physical,
        priceStaffVirtual: boothInfo.data[0].price_staff_virtual,
        priceStaffPhysical: boothInfo.data[0].price_staff_physical,
        maxProduct: boothInfo.data[0].max_product,
        productsUsed: productInfo.data.data?.length,
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  async wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.getOrderSummary();
      return false;
    }
  }

  tagFieldFocus = () => {
    this.setState({
      tagFieldFocused: true,
    });
  };

  tagFieldBlur = () => {
    this.setState({
      tagFieldFocused: false,
    });
  };

  onKeyDown = (keyEvent) => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  };

  handleFileUpload = (file, meta, status) => {
    this.setState({ file });
  };

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    // let bodyContent;

    if (!this.state.company) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Typography className={classes.center} variant="h4">
                  Order Summary
                </Typography>
              </Paper>
              <Typography className={classes.center} variant="h5">
                We have your account but your booth is not set up yet.
                <br />
                Please reach out to PlanetConnect for assistance.
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    let levelAsString = "";
    switch (this.state.company.booth_type) {
      case "0":
        levelAsString = "Signature";
        break;
      case "1":
        levelAsString = "Diamond";
        break;
      case "2":
        levelAsString = "Platinum";
        break;
      case "3":
        levelAsString = "Gold";
        break;
      case "4":
        levelAsString = "Silver";
        break;
      default:
        levelAsString = "Booth";
    }

    return (
      <div className={classes.root}>
        <Formik
          initialValues={{
            // booth
            companyNameForBooth: this.state.company.account_alias,
            level: levelAsString,
            sponsorshipLevel: this.state.company.level_requested,
            // staff
            maxStaffVirtual: this.state.maxStaffVirtual,
            maxStaffPhysical: this.state.maxStaffPhysical,
            availableStaffVirtual: this.state.availableStaffVirtual,
            availableStaffPhysical: this.state.availableStaffPhysical,
            purchasableStaffVirtual: this.state.purchasableStaffVirtual,
            purchasableStaffPhysical: this.state.purchasableStaffPhysical,
            usedStaffVirtual: this.state.usedStaffVirtual,
            usedStaffPhysical: this.state.usedStaffPhysical,
            priceStaffVirtual: this.state.priceStaffVirtual,
            priceStaffPhysical: this.state.priceStaffPhysical,
            // product
            maxProduct: this.state.maxProduct,
            productsUsed: this.state.productsUsed,
            // user
            userEmail: this.props.event.event.email,
            userName: `${this.props.event.event.first_name} ${this.props.event.event.last_name}`,
            userTitle: this.props.event.event.title,
            userCompany: this.props.event.event.account_name,
            userPhone: this.props.event.event.phone,
          }}
          onSubmit={async (values, { setSubmitting }) => {}}
        >
          {({ isSubmitting, values, handleChange }) => (
            <Form onKeyDown={this.onKeyDown}>
              <Grid container spacing={2} style={{ marginBottom: "56px" }}>
                <Grid item xs={12}>
                  <Paper>
                    <Typography className={classes.center} variant="h4">
                      Order Summary
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      User
                    </Typography>
                    <Divider className={classes.divider} />
                    <CompanyInfoField
                      name="userEmail"
                      id="userEmail"
                      label="Email"
                      type="text"
                      tooltip="Your email."
                      disabled
                    />
                    <CompanyInfoField
                      name="userName"
                      id="userName"
                      label="Name"
                      type="text"
                      tooltip="Your first and last name."
                      disabled
                    />
                    <CompanyInfoField
                      name="userTitle"
                      id="userTitle"
                      label="Job Title"
                      type="text"
                      tooltip="The title of your profession."
                      disabled
                    />
                    <CompanyInfoField
                      name="userCompany"
                      id="userCompany"
                      label="Company"
                      type="text"
                      tooltip="The company you work for."
                      disabled
                    />

                    <CompanyInfoField
                      name="userPhone"
                      id="userPhone"
                      label="Phone Number"
                      type="text"
                      placeholder="No phone number on file"
                      tooltip="Your phone number."
                      disabled
                    />
                  </Paper>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      Booth
                    </Typography>
                    <Divider className={classes.divider} />
                    <CompanyInfoField
                      name="companyNameForBooth"
                      id="companyNameForBooth"
                      label="Company Name for Booth"
                      type="text"
                      placeholder="Company Name for Booth"
                      tooltip="Name that will display for your booth."
                      disabled
                    />
                    <CompanyInfoField
                      name="level"
                      id="level"
                      label="Sponsorhip Level"
                      type="text"
                      placeholder="Sponsorhip Level"
                      tooltip="The sponsorship level your booth is."
                      disabled
                    />

                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                      <Typography
                        className={classes.productLimit}
                        variant="h5"
                        color="textPrimary"
                      >
                        Products and Services
                      </Typography>
                      <Typography
                        className={classes.productLimit}
                        variant="h6"
                        color={
                          this.state.products?.length > this.state.maxProduct
                            ? "error"
                            : "textPrimary"
                        }
                      >
                        Items{" "}
                        {this.state.products?.length
                          ? `${this.state.products?.length}`
                          : `0`}
                        {this.state.maxProduct
                          ? ` / ${this.state.maxProduct}`
                          : ` / 0`}{" "}
                        Maximum
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs>
                        <Typography className={classes.header} variant="h5">
                          Staff
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Tooltip
                          title="Upload your company logo here. Either drag and drop the image into the box below or click the box to navigate and find the file."
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                      <Typography className={classes.staffPasses} variant="h6">
                        Virtual Staff
                      </Typography>
                      <Typography
                        className={classes.staffPasses}
                        variant="h6"
                        color={
                          this.state.usedStaffVirtual >
                          this.state.availableStaffVirtual
                            ? "error"
                            : "textPrimary"
                        }
                      >
                        {`${
                          this.state.usedStaffVirtual
                            ? this.state.usedStaffVirtual
                            : 0
                        } of ${
                          this.state.availableStaffVirtual
                            ? this.state.availableStaffVirtual
                            : 0
                        } Passes Used`}
                      </Typography>
                      <Typography
                        className={classes.staffPasses}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        Up to {this.state.maxStaffVirtual} Virtual passes
                        available
                      </Typography>
                      <Typography
                        className={classes.staffPasses}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {this.state.purchasableStaffVirtual} Virtual Staff pass
                        {this.state.purchasableStaffVirtual !== 1 ? "es " : " "}
                        available for purchase for $
                        {this.state.priceStaffVirtual} per day
                      </Typography>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid item xs={12}>
                      <Typography className={classes.staffPasses} variant="h6">
                        On-Site Staff
                      </Typography>
                      <Typography
                        className={classes.staffPasses}
                        variant="h6"
                        color={
                          this.state.usedStaffPhysical >
                          this.state.availableStaffPhysical
                            ? "error"
                            : "textPrimary"
                        }
                      >
                        {`${
                          this.state.usedStaffPhysical
                            ? this.state.usedStaffPhysical
                            : 0
                        } of ${
                          this.state.availableStaffPhysical
                            ? this.state.availableStaffPhysical
                            : 0
                        } Passes Used`}
                      </Typography>
                      <Typography
                        className={classes.staffPasses}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        Up to {this.state.maxStaffPhysical} On-Site passes
                        available
                      </Typography>
                      <Typography
                        className={classes.staffPasses}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {this.state.purchasableStaffPhysical} On-Site Staff pass
                        {this.state.purchasableStaffPhysical !== 1
                          ? "es "
                          : " "}
                        available for purchase for $
                        {this.state.priceStaffPhysical} per day
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
    archived: state.archived,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CompanyInfo));
