import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";
import Audit from "./Audit.Reporting";
import Leaderboard from "./Leaderboard.Reporting";
import TrackPopularity from "./Presentations/Track.Reporting";
import PresentationPopularity from "./Presentations/Presentation.Reporting";
import TopicPopularity from "./Posters/Topic.Reporting";
import PosterPopularity from "./Posters/Poster.Reporting";
import ExhibitorPopularity from "./Exhibitors/Exhibitor.Reporting";
import Accounts from "./Accounts";
import TopMetrics from "./TopMetrics";
import Rooms from "./Rooms";
import Location from "./Location";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    // padding: theme.spacing(2),
  },
}));

export default function Reporting() {
  const classes = useStyles();
  const event = useSelector((state) => state.event);
  const [presentations, setPresentations] = useState([]);
  const [posters, setPosters] = useState([]);
  const [exhibitors, setExhibitors] = useState([]);
  const [roomView, setRoomView] = useState([]);
  const [wholeReport, setWholeReport] = useState([]);
  const [times, setTimes] = useState([]);
  const [timesDaily, setTimesDaily] = useState([]);
  const [loading, setLoading] = useState([false]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const user = await Auth.currentSession();

        //   const recommendation = await axios({
        //     method: "GET",
        //     url: 'reporting/recommendation/' + event.event.show_id + '/' + 'mathieu.leenders@merck.com',
        //     headers: {
        //         idtoken: user.idToken.jwtToken
        //     },
        // });

        // console.log(recommendation)

        const report = await axios({
          method: "GET",
          url: "reporting/rooms/" + event.event.show_id,
          headers: {
            idtoken: user.idToken.jwtToken,
          },
        });

        let presentations = [];
        let posters = [];
        let exhibitors = [];
        let roomView = [];
        let times = [];
        let timesDaily = [];

        const wholeReport = report.data.map((datum, index) => {
          if (datum.resource_type === "Presentation") {
            if (!datum.track) {
              datum.track = "No Track";
            }
            presentations.push(datum);
          } else if (datum.resource_type === "Exhibit") {
            exhibitors.push(datum);
          } else if (datum.resource_type === "Poster") {
            posters.push(datum);
          }

          if (datum.action === "Viewed") {
            roomView.push(datum);
          }

          if (!datum.visitor_site) {
            datum.visitor_site = "No Site Given";
          }

          if (
            !times.includes(
              moment(datum.visit_time).format("MMM Do YYYY, hh:00 a")
            )
          ) {
            times.push(moment(datum.visit_time).format("MMM Do YYYY, hh:00 a"));
          }

          if (
            !timesDaily.includes(
              moment(datum.visit_time).format("dddd, MMM Do YYYY")
            )
          ) {
            timesDaily.push(
              moment(datum.visit_time).format("dddd, MMM Do YYYY")
            );
          }

          return datum;
        });

        setPresentations(presentations);
        setPosters(posters);
        setExhibitors(exhibitors);
        setRoomView(roomView);
        setTimes(times);
        setTimesDaily(timesDaily);
        setWholeReport(wholeReport);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [event.event.show_id]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.center} variant="h4">
              Reporting
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TopMetrics />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Accounts loading={loading} report={roomView} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Rooms loading={loading} report={roomView} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Location loading={loading} report={wholeReport} />
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12} sm={12}>
        {/* <Leaderboard /> */}
      </Grid>
      <Grid item xs={12} sm={12}>
        {/* audit is currently broken and causes page to bomb */}
        {/* <Audit times={times} timesDaily={timesDaily} report={wholeReport} /> */}
      </Grid>

      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography className={classes.center} variant="h5">
              Presentations
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TrackPopularity loading={loading} report={presentations} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PresentationPopularity loading={loading} report={presentations} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography className={classes.center} variant="h5">
              Posters
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TopicPopularity loading={loading} report={posters} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PosterPopularity loading={loading} report={posters} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography className={classes.center} variant="h5">
              Exhibitors
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExhibitorPopularity loading={loading} report={exhibitors} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
