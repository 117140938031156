import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { withAuthenticator } from 'aws-amplify-react';
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import momentTimezone from "moment-timezone"; //eslint-disable-line

// Redux
import { connect } from "react-redux";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    "& .MuiTypography-root": {
      alignSelf: "center",
    },
    "& .MuiChip-root	": {
      alignSelf: "center",
    },
    "& .MuiAccordionSummary-content": {
      margin: theme.spacing(0.5),
    },
  },
  grid: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "50%",
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  own: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: "5px",
    marginBottom: "3px",
    borderRadius: "15px",
    maxWidth: "100%",
  },
  img: {
    borderRadius: "50%",
    maxWidth: "100%",
  },
  other: {},
  expansionPanelMain: { padding: "0px 8px 0px 8px" },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "60%",
    flexShrink: 0,
  },
  icon: {
    minWidth: "10em",
    maxWidth: "30em",
    maxHeight: "30em",
    width: "100%",
  },
  merck: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  lineTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "left",
    paddingBottom: theme.spacing(1),
  },
  lineAbstract: {
    fontSize: "1rem",
    textAlign: "left",
  },
  lineSpeakerName: {
    fontWeight: "bold",
    textAlign: "left",
  },
  lineSpeakerTitle: {
    fontSize: "1rem",
    textAlign: "left",
  },
  lineSpeakerCompany: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "left",
  },
  selected: {
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
  },
  selectedText: {
    color: "#FFF",
  },
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracts: [],
    };
  }

  componentDidMount = async () => {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: "get",
          url: `abstracts/${this.props.event.event.show_id}/${this.props.event.event.exhibition_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
        })
          .then((response) => {
            let abstracts = [];

            for (let x = 0; x < response.data.data.length; x++) {
              let abstract = response.data.data[x];
              abstract.date = moment(
                abstract.start_time,
                "YYYY/MM/DD"
              ).format();
              abstracts.push(abstract);
            }

            this.setState({
              abstracts: [...this.state.abstracts, ...abstracts],
            });
          })
          .catch((error) => {
            // if (this.props.debug.debug) {
            console.log(`Error: Could not display Booth Abstracts. ${error}`);
            // }
          });
      })
      .catch((error) => {
        // if (this.props.debug.debug) {
        console.log(`Error: Could not display Booth Abstracts. ${error}`);
        // }
      });
  };

  navigate(route, type, resourceId) {
    this.props.history.push(`/${route}?${type}=${resourceId}`);
  }

  render() {
    const { classes } = this.props;

    const abstracts = this.state.abstracts.map((abstract, index) => {
      let favorited = false;

      let selectedClassCheck =
        this.props.selectedProductId === abstract.abstract_id
          ? classes.selected
          : null;
      let selectedClassCheckText =
        this.props.selectedProductId === abstract.abstract_id
          ? classes.selectedText
          : null;

      let listAbstract;

      switch (abstract.accepted_type) {
        case "Poster":
          listAbstract = (
            <Grid item xs={12} key={index} className={classes.grid}>
              <Accordion key={index} className={classes.root}>
                <AccordionSummary
                  className={
                    classes.expansionPanelMain + " " + selectedClassCheck
                  }
                  expandIcon={<ExpandMoreIcon />}
                  id={index}
                >
                  <Grid
                    className={classes.panelExpanded}
                    container
                    layout={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={1}>
                      {/* {favorited ? (
                        <IconButton
                          aria-label="remove from briefcase"
                          // onClick={() =>
                          //   this.props.deleteBriefcaseElement(
                          //     abstract.abstract_id,
                          //   )
                          // }
                        >
                          <WorkIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="add to briefcase"
                          //   onClick={() =>
                          //     this.props.addBriefcaseElement(
                          //       'Poster',
                          //       abstract.abstract_id,
                          //       abstract.account_name,
                          //     )
                          //   }
                        >
                          <WorkOutlineIcon />
                        </IconButton>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography
                        className={
                          classes.secondaryHeading +
                          " " +
                          selectedClassCheckText
                        }
                      >
                        {abstract.accepted_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={11} md={8} lg={8}>
                      <Typography className={classes.heading}>
                        {abstract.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    className={classes.panelExpanded}
                    container
                    layout={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} className={classes.grid}>
                      <Typography
                        variant="h6"
                        align="center"
                        color="textPrimary"
                      >
                        {abstract.title}
                      </Typography>
                      <Divider
                        className={classes.divider}
                        variant="fullWidth"
                      />
                      <br />
                      <Typography
                        className={classes.lineSpeakerName}
                        variant="h6"
                      >
                        {abstract.presenter_first_name ? (
                          <React.Fragment>
                            {abstract.presenter_first_name +
                              " " +
                              abstract.presenter_last_name}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_title
                          ? abstract.presenter_title
                          : ""}
                      </Typography>
                      <Typography className={classes.lineSpeakerCompany}>
                        {abstract.presenter_company
                          ? abstract.presenter_company
                          : ""}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_email ? (
                          <>
                            <b>Email:</b> {abstract.presenter_email}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                      {abstract.start_time && abstract.date ? (
                        <Typography variant="body1" align="center">
                          {`Starts at ${moment(abstract.start_time).format(
                            "h:mm a"
                          )} on  ${moment(
                            abstract.date.substring(0, 10)
                          ).format("dddd, MMMM Do")}`}
                        </Typography>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <br />
                      <FormControl className={classes.formControlButton}>
                        <Button variant="contained" className={classes.button}>
                          Go To Poster
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
          break;
        default:
          listAbstract = (
            <Grid item xs={12} key={index} className={classes.grid}>
              <Accordion key={index} className={classes.root}>
                <AccordionSummary
                  className={classes.expansionPanelMain}
                  expandIcon={<ExpandMoreIcon />}
                  id={index}
                >
                  <Grid
                    className={classes.panelExpanded}
                    container
                    layout={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={1}>
                      {/* {favorited ? (
                        <IconButton
                          aria-label="remove from briefcase"
                          onClick={() =>
                            this.props.deleteBriefcaseElement(
                              abstract.abstract_id
                            )
                          }
                        >
                          <WorkIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="add to briefcase"
                          onClick={() =>
                            this.props.addBriefcaseElement(
                              "Presentation",
                              abstract.abstract_id,
                              abstract.account_name
                            )
                          }
                        >
                          <WorkOutlineIcon />
                        </IconButton>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography className={classes.secondaryHeading}>
                        {abstract.accepted_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={11} md={8} lg={8}>
                      <Typography className={classes.heading}>
                        {abstract.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    className={classes.panelExpanded}
                    container
                    layout={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} className={classes.grid}>
                      <Typography
                        variant="h6"
                        align="center"
                        color="textPrimary"
                      >
                        {abstract.title}
                      </Typography>
                      <Divider
                        className={classes.divider}
                        variant="fullWidth"
                      />
                      <br />
                      <Typography
                        className={classes.lineSpeakerName}
                        variant="h6"
                      >
                        {abstract.presenter_first_name ? (
                          <React.Fragment>
                            {abstract.presenter_first_name +
                              " " +
                              abstract.presenter_last_name}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_title
                          ? abstract.presenter_title
                          : ""}
                      </Typography>
                      <Typography className={classes.lineSpeakerCompany}>
                        {abstract.presenter_company
                          ? abstract.presenter_company
                          : ""}
                      </Typography>
                      <Typography className={classes.lineSpeakerTitle}>
                        {abstract.presenter_email ? (
                          <>
                            <b>Email:</b> {abstract.presenter_email}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <br />
                      {abstract.start_time && abstract.date ? (
                        <Typography variant="body1" align="center">
                          {`Starts at ${moment(abstract.start_time).format(
                            "h:mm a"
                          )} on  ${moment(
                            abstract.date.substring(0, 10)
                          ).format("dddd, MMMM Do")}`}
                        </Typography>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <br />
                      <FormControl className={classes.formControlButton}>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={
                            () =>
                              alert(
                                "This would navigate to the presentation when viewed within the virtual environment."
                              )
                            // this.navigate(
                            //   "auditorium/",
                            //   "presentation_id",
                            //   abstract.abstract_id
                            // )
                          }
                        >
                          Go to Presentation
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
      }

      return listAbstract;
    });

    return (
      <div className={classes.grid}>
        <Grid container layout={"row"} justifyContent="center" spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div" align="center">
              Come See Us Throughout the Event!
            </Typography>
            <br />
            <br />
          </Grid>
          {abstracts}
        </Grid>
      </div>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: "#6f1a1d" },
};

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(withStyles(useStyles)(Chat)));
// remove amplify HOC sign in catch
// export default connect(mapStateToProps, null)(withRouter(withStyles(useStyles)(withAuthenticator(Chat, false, [], null, MyTheme))));
