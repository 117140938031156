const endpoint = "https://core-api.planetconnect.com/";

const allRoles = [
  "Booth Admin",
  "Host Abstract",
  "Host Admin",
  "Event Admin",
  "Reporting",
  "Host Reporting",
  "Poster Presenter",
];

const routerArray = [
  {
    title: "Events",
    container: "MyEventsList",
    icon: "EventIcon",
    route: "/events",
    allowedRoles: ["Admin"],
  },
  {
    title: "Event Config",
    container: "EventConfig",
    icon: "FormatListBulletedIcon",
    route: "/eventconfig",
    allowedRoles: ["Event Admin"],
  },
  {
    title: "Form Builder",
    container: "FormBuilder",
    icon: "BallotIcon",
    route: "/formbuilder",
    allowedRoles: ["Event Admin"],
  },
  {
    title: "Polls",
    container: "Polls",
    icon: "PollIcon",
    route: "/polls",
    allowedRoles: ["Event Admin"],
  },
  {
    title: "Event Info",
    container: "EventInfo",
    icon: "CategoryIcon",
    route: "/eventinfo",
    allowedRoles: ["Event Admin"],
  },
  {
    title: "Registration",
    container: "Registration",
    icon: "GroupAddIcon",
    route: "/registration",
    allowedRoles: ["Event Admin"],
  },
  {
    title: "All Abstracts",
    container: "AbstractsList",
    icon: "AssignmentIcon",
    route: "/abstracts",
    allowedRoles: ["Host Abstract"],
  },
  {
    title: "Abstract Voting",
    container: "VotingList",
    icon: "HowToVoteIcon",
    route: "/voting",
    allowedRoles: ["Host Abstract"],
  },
  {
    title: "Host",
    container: "HostList",
    icon: "AccountCircleIcon",
    route: "/host",
    allowedRoles: ["Host Reporting"],
  },
  {
    title: "My Abstracts",
    container: "AbstractOwner",
    icon: "FilterNoneIcon",
    route: "/abstractowner",
    allowedRoles: ["Poster Presenter", "Speaker"],
  },
  {
    title: "Order Summary",
    container: "OrderSummary",
    icon: "ViewQuiltIcon",
    route: "/ordersummary",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Company",
    container: "CompanyInfo",
    icon: "BusinessIcon",
    route: "/company",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Staff",
    container: "StaffList",
    icon: "PeopleIcon",
    route: "/people",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Products & Services",
    container: "ProductsList",
    icon: "BusinessCenterIcon",
    route: "/products",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Activities",
    container: "AssetsList",
    icon: "AssignmentTurnedInIcon",
    route: "/eventactivities",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Booth Preview",
    container: "ExhibitBooth",
    icon: "DevicesIcon",
    route: "/exhibitbooth",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "New Orders",
    container: "NewOrders",
    icon: "AddShoppingCartIcon",
    route: "/neworders",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Leads",
    container: "LeadsExhibitor",
    icon: "MyleadsIcon",
    route: "/leads",
    allowedRoles: ["Booth Admin"],
  },
  {
    title: "Reporting",
    container: "Reporting",
    icon: "EqualizerIcon",
    route: "/reporting",
    allowedRoles: ["Reporting"],
  },
  // {
  //     title: 'Posters',
  //     container: 'EventInfo',
  //     icon: 'FilterNoneIcon',
  //     route: '/eventinfo',
  //     allowedRoles: ['Event Admin']
  // },
  // {
  //     title: 'New Event',
  //     container: 'NewEvent',
  //     icon: 'BusinessIcon',
  //     route: '/newevent',
  //     allowedRoles: ['Booth Admin', 'Event Admin', 'Host Admin', 'Reporting'],
  // },
  // {
  //     title: 'New Attendee',
  //     container: 'NewAttendee',
  //     icon: 'BusinessIcon',
  //     route: '/newattendee',
  //     allowedRoles: ['Booth Admin', 'Event Admin', 'Host Admin', 'Reporting'],
  // },
  // {
  //     title: 'New Exhibit Booth',
  //     container: 'NewExhibitBooth',
  //     icon: 'BusinessIcon',
  //     route: '/newexhibitbooth',
  //     allowedRoles: ['Booth Admin', 'Event Admin', 'Host Admin', 'Reporting'],
  // },
];

const OktaSSOEnabled = false;
const MerckSSOEnabled = false;

const stripeTest = "pk_test_L7wePYva7p5mX8FZdFXVElkQ";
const stripeLive = "pk_live_zhf7UZEN4LPop0qZif0AOf0U";

export {
  endpoint,
  routerArray,
  allRoles,
  stripeTest,
  stripeLive,
  OktaSSOEnabled,
  MerckSSOEnabled,
};
