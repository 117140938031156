import { REMOVE_EVENT_ID } from './types';

export default function removeEventId() {
  return dispatch => {
    dispatch(removeEventIdAsync())
    }
}

function removeEventIdAsync(){
  return {
    type: REMOVE_EVENT_ID,
    payload: false
  };
}