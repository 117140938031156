/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'

export function ResourceGridActionButtons({ value }) {
    return (
        <React.Fragment>
            <ButtonGroup variant="contained" color="primary" size="small">
                <Button component={Link} to={`/resources/${value}`}>
                    Edit
                </Button>
                <Button component={Link} to="/delete" color="secondary">
                    Delete
                </Button>
            </ButtonGroup>
        </React.Fragment>
    );
}
