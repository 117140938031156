import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { PollsInfo } from "./PollsInfo";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { PollsGridActionButtons } from "./PollsGridActionButtons";
import { pollsGridOptions } from "./pollsGridOptions";
import { DataTable } from "../Shared/DataTable";
import Dialog from "../../Components/Dialog";

import moment from "moment";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  // root: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  // },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    // marginBottom: theme.spacing(1),
  },
});

class Polls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polls: [],
      editing: false,
      deleting: false,
      currentPoll: null,
      show: null,
      loaded: false,
      websiteUrl: "",
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getPolls();
      await this.getShow();
    }
  }

  async getPolls() {
    let noPollsFound = false;
    await axios({
      method: "get",
      url: "polls/",
      params: { showId: this.props.event.event.show_id },
      //   data: newPoll,
    })
      .then((response) => {
        // console.log(response.data);
        if (!response.data.length) {
          noPollsFound = true;
        }
        let sortedPolls = this.sortPolls(response.data);
        this.setState({ polls: sortedPolls });
      })
      .catch((error) => console.log(error));
    if (noPollsFound) {
      this.addNewPoll();
    }
    const user = await Auth.currentSession();
    let showInfo = await axios({
      method: "get",
      url: `/shows/${this.props.event.event.show_id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        idtoken: user.idToken.jwtToken,
      },
    });
    showInfo = showInfo.data[0];
    let websiteUrl = showInfo.virtual_environment_website;
    if (websiteUrl?.slice(-1) === "/") {
      websiteUrl = websiteUrl?.slice(0, -1);
    }
    this.setState({ websiteUrl });
  }

  async getShow() {
    try {
      const data = await Auth.currentSession();

      let myShow = await axios({
        method: "get",
        url: `/shows/${this.props.event.event.show_id}`,
        headers: { idtoken: data.idToken.jwtToken },
      });
      myShow = myShow.data[0];
      await this.setState({ show: myShow });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  sortPolls(polls) {
    let sortedPolls = polls.sort((a, b) =>
      moment(b.created_time).diff(moment(a.created_time))
    );
    return sortedPolls;
  }

  async addNewPoll() {
    const newPoll = {
      is_multiple: false,
      show_id: this.props.event.event.show_id,
      owner_id: this.props.user.user.account_id,
      image_url: "https://picsum.photos/200/300",
      question: "New Poll Question",
      status: "OPEN",
      is_published: false,
      answers: [
        {
          type: "text",
          value: "123456578",
        },
      ],
    };
    await axios({
      method: "post",
      url: "polls/", // + this.props.event.event.show_id,
      params: { showId: this.props.event.event.show_id },
      data: newPoll,
    })
      .then((response) => {})
      .catch((error) => console.log(error));
    let sortedPolls;
    let newlyAddedPoll;
    await axios({
      method: "get",
      url: "polls/", // + this.props.event.event.show_id,
      params: { showId: this.props.event.event.show_id },
    })
      .then((response) => {
        sortedPolls = this.sortPolls(response.data);
        newlyAddedPoll = sortedPolls[0];
      })
      .catch((error) => console.log(error));
    this.setCurrentPoll(newlyAddedPoll);
    this.setState({ polls: sortedPolls, editing: true });
  }

  handleEditingChange(e) {
    this.setState({
      editing: e,
    });
  }

  handleCreatingChange(e) {
    this.setState({
      editing: e,
      currentPoll: null,
    });
  }

  async handleDeletingChange(e) {
    this.setState({
      deleting: e,
    });
  }

  handleDeletingCancel() {
    this.setState({
      deleting: false,
    });
  }

  handleEditClose() {
    this.setState({
      editing: false,
      currentPoll: null,
    });

    this.getPolls();
  }

  handleDeletingConfirm(e) {
    Auth.currentSession().then((data) => {
      axios({
        method: "delete",
        url: `/polls/` + this.state.currentPoll.poll_id,
        headers: { idtoken: data.idToken.jwtToken },
      })
        .then((response) => {
          // previously used to filter out the one that was removed, using getPolls() instead
          // console.log(response);
          // let newPollsArray = this.state.polls.filter((e) => {
          //   console.log("currentPollId", this.state.currentPoll.poll_id);
          //   console.log("singlePollId", e.poll_id);
          //   if (this.state.currentPoll.poll_id !== e.poll_id) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // });
          // this.setState({
          //   polls: newPollsArray,
          //   currentPoll: null,
          // });
          this.getPolls();
        })
        .catch((error) => {
          console.log(error);
        });
    });

    this.setState({
      deleting: false,
    });

    // this.getPolls();
  }

  setCurrentPoll(e) {
    e.vote_url = this.state.websiteUrl;
    this.setState({
      currentPoll: e,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentPoll: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }
    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You have no submitted polls for this event
        </Typography>
      </Grid>
    );

    if (!this.state.polls.length) {
      if (this.state.editing) {
        bodyContent = (
          <PollsInfo
            currentPoll={this.state.currentPoll}
            showId={this.props.event.event.show_id}
            accountId={this.props.user.user.account_id} //{this.props.user.user.Account_Name.id}
            exhibitionId={this.props.event.event.exhibition_id}
            handleEditClose={this.handleEditClose.bind(this)}
            websiteUrl={this.state.websiteUrl}
            show={this.state.show}
          />
        );
      } else {
        if (this.state.loaded) {
          bodyContent = noResultsFound;
        } else {
          bodyContent = loadingGraphic;
        }
      }
    } else {
      bodyContent = (
        <Paper className={classes.paper}>
          <DataTable
            initialGridOptions={pollsGridOptions}
            initialRowData={this.state.polls}
            actionButtons={PollsGridActionButtons}
            type="poll"
            setDeleting={(e) => this.handleDeletingChange(e)}
            setEditing={(e) => this.handleEditingChange(e)}
            setCurrentPage={(e) => this.setCurrentPoll(e)}
          />
        </Paper>
      );
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={3}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditingChange(false)}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    Polls and Services
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    You can add new polls and edit current polls.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  align="right"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  {!this.state.editing ? (
                    <Button
                      onClick={() => this.addNewPoll()}
                      variant="contained"
                      color="primary"
                    >
                      Add Poll
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
            {this.state.editing ? (
              <PollsInfo
                currentPoll={this.state.currentPoll}
                showId={this.props.event.event.show_id}
                exhibitionId={this.props.event.event.exhibition_id}
                accountId={this.props.user.user.account_id} //{this.props.user.user.Account_Name.id}
                handleEditClose={this.handleEditClose.bind(this)}
                sidebar={this.props.sidebar}
                show={this.state.show}
              />
            ) : (
              bodyContent
            )}
          </Grid>
        </Grid>
        <Dialog
          open={this.state.deleting}
          handleClose={() => this.handleDeletingCancel()}
        >
          <Typography>Are you sure you want to delete?</Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => this.handleDeletingConfirm()}
          >
            Yes
          </Button>
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleDeletingCancel()}
          >
            No
          </Button>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Polls));

// sample poll data shape

// {
//     "poll_id": "71b91660-c4fe-4de2-8917-4b866f295f4b",
//     "is_multiple": false,
//     "show_id": "1691992000035470006",
//     "owner_id": "1691992000035412345",
//     "start_time": null,
//     "end_time": null,
//     "image_url": "https://picsum.photos/200/300",
//     "question": "Test Question For this POLL",
//     "status": "OPEN",
//     "api_token": "cefcd0d2-96bd-4625-91b4-e56e6fe68c08",
//     "is_published": true,
//     "answers": [
//         {
//             "answer_id": "c7490bd8-8148-490c-9ee1-a966888fae54",
//             "poll_id": "71b91660-c4fe-4de2-8917-4b866f295f4b",
//             "type": "text",
//             "value": "test",
//             "vote_url": "https://planetconnect.com/actions/vote_in_poll?poll_id=71b91660-c4fe-4de2-8917-4b866f295f4b&answer=test",
//             "qrcode_url": "https://pci-admin-kfc-portal.s3.amazonaws.com/1655321197091-526.png"
//         },
//         {
//             "answer_id": "e7658dc8-8d57-4743-ac49-7b73480e7c04",
//             "poll_id": "71b91660-c4fe-4de2-8917-4b866f295f4b",
//             "type": "user",
//             "value": "123456578",
//             "vote_url": "https://planetconnect.com/actions/vote_in_poll?poll_id=71b91660-c4fe-4de2-8917-4b866f295f4b&answer=123456578",
//             "qrcode_url": "https://pci-admin-kfc-portal.s3.amazonaws.com/1655321197539-407.png"
//         }
//     ],
//     "created_time": "2022-06-15T19:26:37.086Z",
//     "modified_time": "2022-06-15T19:26:37.086Z"
// }
