export const formDataShape = [
  {
    //   "form_id": 1,
    show_id: "",
    type: "registration",
    name: "Attendee Registration",
    label: "Attendee Registration",
    is_enabled: true,
    fields: [
      {
        //   "form_id": 1,
        //   "field_id": 55,
        name: "firstName",
        label: "First Name",
        type: "text_field",
        placeholder: "Please input First Name",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 1,
        //   "field_id": 56,
        name: "lastName",
        label: "Last Name",
        type: "text_field",
        placeholder: "Please input Last Name",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 1,
        //   "field_id": 58,
        name: "jobTitle",
        label: "Job Title",
        type: "text_field",
        placeholder: "Please input Job Title",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 1,
        //   "field_id": 57,
        name: "department",
        label: "Department",
        type: "text_field",
        placeholder: "Please input Department",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 57,
        name: "division",
        label: "Division",
        type: "text_field",
        placeholder: "Please input Division",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 59,
        name: "company",
        label: "Company",
        type: "text_field",
        placeholder: "Please input Company",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 1,
        //   "field_id": 60,
        name: "site",
        label: "Site",
        type: "text_field",
        placeholder: "Please input Site",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 61,
        name: "addressStreet",
        label: "Street Address",
        type: "text_field",
        placeholder: "Please input Street Address",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 1,
        //   "field_id": 62,
        name: "addressCity",
        label: "City",
        type: "text_field",
        placeholder: "Please input City",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 1,
        //   "field_id": 63,
        name: "addressState",
        label: "State",
        type: "text_field",
        placeholder: "Please input State",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 1,
        //   "field_id": 64,
        name: "addressZip",
        label: "Zip",
        type: "text_field",
        placeholder: "Please input Zip Code",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 1,
        //   "field_id": 65,
        name: "addressCountry",
        label: "Country",
        type: "select",
        placeholder: "Please input Country",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 1,
        //   "field_id": 66,
        name: "phoneNumber",
        label: "Phone Number",
        type: "text_field",
        placeholder: "Please input Phone Number",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 1,
        //   "field_id": new,
        name: "How will you be attending?",
        label: "Attendance",
        type: "multi_select",
        placeholder: "How will you be attending?",
        section: "fieldsStandard",
        is_enabled: true,
        is_required: true,
        choices: [{ label: "Virtual", value: "Virtual" }],
      },
      {
        //   "form_id": 1,
        //   "field_id": 67,
        name: "",
        label: "Custom 1",
        type: "text_field",
        placeholder: "Please input info",
        section: "fieldsCustom",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 68,
        name: "",
        label: "Custom 2",
        type: "text_field",
        placeholder: "Please input info",
        section: "fieldsCustom",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 69,
        name: "",
        label: "Custom 3",
        type: "text_field",
        placeholder: "Please input info",
        section: "fieldsCustom",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 70,
        name: "",
        label: "Custom 4",
        type: "text_field",
        placeholder: "Please input info",
        section: "fieldsCustom",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 71,
        name: "",
        label: "Custom 5",
        type: "text_field",
        placeholder: "Please input info",
        section: "fieldsCustom",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 72,
        name: "",
        label: "Custom 6",
        type: "text_field",
        placeholder: "Please input info",
        section: "fieldsCustom",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 73,
        name: "",
        label: "Question 1",
        type: "text_field",
        placeholder: "Please input your answer",
        section: "question",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 74,
        name: "",
        label: "Question 2",
        type: "text_field",
        placeholder: "Please input your answer",
        section: "question",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 75,
        name: "",
        label: "Question 3",
        type: "text_field",
        placeholder: "Please input your answer",
        section: "question",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
      {
        //   "form_id": 1,
        //   "field_id": 76,
        name: "",
        label: "Question 4",
        type: "text_field",
        placeholder: "Please input your answer",
        section: "question",
        is_enabled: false,
        is_required: false,
        choices: null,
      },
    ],
  },
  {
    //   "form_id": 2,
    show_id: "",
    type: "abstract_submission",
    name: "Abstract Submission",
    label: "Abstract Submission",
    is_enabled: true,
    fields: [
      {
        //   "form_id": 2,
        //   "field_id": 9,
        name: "talk",
        label: "Talk",
        type: "text_field",
        placeholder: "Talk",
        section: "type",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "poster",
        label: "Poster",
        type: "text_field",
        placeholder: "Poster",
        section: "type",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "track_talk",
        label: "Track Talk",
        type: "text_field",
        placeholder: "Track Talk",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "mrl_app_lab",
        label: "MRL App Lab",
        type: "text_field",
        placeholder: "MRL App Lab",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "workshop",
        label: "Workshop",
        type: "text_field",
        placeholder: "Workshop",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "product_theater",
        label: "Product Theater",
        type: "text_field",
        placeholder: "Product Theater",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "supplier_theater",
        label: "Supplier Theater",
        type: "text_field",
        placeholder: "Supplier Theater",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "plenary_poster",
        label: "Plenary Poster",
        type: "text_field",
        placeholder: "Plenary Poster",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 10,
        name: "science_as_art",
        label: "Science as Art",
        type: "text_field",
        placeholder: "Science as Art",
        section: "type",
        is_enabled: false,
        is_required: true,
      },
      // {
      //   //   "form_id": 2,
      //   //   "field_id": 10,
      //   name: "rapid_fire_talk",
      //   label: "Rapid Fire Talk",
      //   type: "text_field",
      //   placeholder: "Rapid Fire Talk",
      //   section: "type",
      //   is_enabled: false,
      //   is_required: true,
      // },
      // {
      //   //   "form_id": 2,
      //   //   "field_id": 9,
      //   name: "round_table",
      //   label: "Round Table",
      //   type: "text_field",
      //   placeholder: "Round Table",
      //   section: "type",
      //   is_enabled: false,
      //   is_required: true,
      // },
      // {
      //   //   "form_id": 2,
      //   //   "field_id": 10,
      //   name: "webinar",
      //   label: "Webinar",
      //   type: "text_field",
      //   placeholder: "Webinar",
      //   section: "type",
      //   is_enabled: false,
      //   is_required: true,
      // },

      // {
      //   //   "form_id": 2,
      //   //   "field_id": 10,
      //   name: "keynote",
      //   label: "Keynote",
      //   type: "text_field",
      //   placeholder: "Keynote",
      //   section: "type",
      //   is_enabled: false,
      //   is_required: true,
      // },
      {
        //   "form_id": 2,
        //   "field_id": 21,
        name: "author_first_name",
        label: "First Name",
        type: "text_field",
        placeholder: "First Name of the Author",
        section: "author_fields",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 22,
        name: "author_last_name",
        label: "Last Name",
        type: "text_field",
        placeholder: "Last Name of the Author",
        section: "author_fields",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 23,
        name: "author_email",
        label: "Email",
        type: "email",
        placeholder: "Email of the Author",
        section: "author_fields",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 24,
        name: "author_company",
        label: "Company",
        type: "text_field",
        placeholder: "Company of the Author",
        section: "author_fields",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 2,
        //   "field_id": 25,
        name: "author_job_title",
        label: "Job Title",
        type: "text_field",
        placeholder: "Job Title of the Author",
        section: "author_fields",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 2,
        //   "field_id": 30,
        name: "author_linkedin_url",
        label: "LinkedIn URL",
        type: "text_field",
        placeholder: "URl of the Author's LinkedIn",
        section: "author_fields",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 2,
        //   "field_id": 26,
        name: "presenter_first_name",
        label: "First Name",
        type: "text_field",
        placeholder: "First Name of the Presenter",
        section: "presenter_fields",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 27,
        name: "presenter_last_name",
        label: "Last Name",
        type: "text_field",
        placeholder: "Last Name of the Presenter",
        section: "presenter_fields",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 28,
        name: "presenter_email",
        label: "Email",
        type: "email",
        placeholder: "Email of the Presenter",
        section: "presenter_fields",
        is_enabled: true,
        is_required: true,
      },
      {
        //   "form_id": 2,
        //   "field_id": 29,
        name: "presenter_company",
        label: "Company",
        type: "text_field",
        placeholder: "Company of the Presenter",
        section: "presenter_fields",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 2,
        //   "field_id": 30,
        name: "presenter_job_title",
        label: "Job Title",
        type: "text_field",
        placeholder: "Job Title of the Presenter",
        section: "presenter_fields",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 2,
        //   "field_id": 30,
        name: "presenter_linkedin_url",
        label: "LinkedIn URL",
        type: "text_field",
        placeholder: "URl of the Presenter's LinkedIn",
        section: "presenter_fields",
        is_enabled: true,
        is_required: false,
      },
      {
        //   "form_id": 2,
        //   "field_id": 31,
        name: "presenter_bio",
        label: "Bio",
        type: "text_area",
        placeholder: "A bio about the Presenter",
        section: "presenter_fields",
        is_enabled: true,
        is_required: false,
      },
    ],
  },
];

export const choiceShapeMaker = (index, type) => {
  if (type === "Attendance") {
    if (index !== 0) {
      return { value: `Day ${index}`, label: `Day ${index}`, is_enabled: true };
    } else {
      return { value: `Virtual`, label: `Virtual`, is_enabled: true };
    }
  } else {
    return { value: type, label: type, is_enabled: true };
  }
};
