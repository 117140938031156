import { SET_AUTHENTICATED_USER } from "./types";
import axios from "axios";
import { Auth } from "aws-amplify";

export default function setAuthenticatedUser(event) {
  return async (dispatch) => {
    const user = await Auth.currentSession();

    let userContact = await axios({
      method: "GET",
      url: "/contacts/" + user.idToken.payload.email, // this.props.event.event.show_id,
      headers: {
        idtoken: user.idToken.jwtToken,
      },
    });

    let newUser = userContact.data[0];

    if (event) {
      let userShow = await axios({
        method: "GET",
        url: `/show-attendees/${event.show_id}/${user.idToken.payload.email}`, // this.props.event.event.show_id,
        headers: {
          idtoken: user.idToken.jwtToken,
        },
      });

      if (userShow.data.length) {
        newUser.roles = userShow.data[0].roles + ", Admin";
      } else {
        newUser.roles = "Admin";
      }
    } else {
      // console.log("else", newUser);

      if (!newUser) {
        newUser = {
          roles: "Admin",
        };
      } else {
        newUser.roles = "Admin";
      }
    }

    dispatch(setAuthenticatedUserAsync(newUser));
  };
}

function setAuthenticatedUserAsync(user) {
  return {
    type: SET_AUTHENTICATED_USER,
    payload: user,
  };
}
