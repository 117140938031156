import { SET_EVENT_ID, REMOVE_EVENT_ID } from '../Actions/types';

const initialState = {
  event: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_ID:
        return {
          ...state,
          event:action.payload
      };
    case REMOVE_EVENT_ID:
      return{
        ...state,
        event: null
      }
    default:
      return state;
  }
}

export default reducer