import React, { useState, useEffect } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Dialog from "../../Components/Dialog";
import { Formik, Form, Field, useField, FieldArray } from "formik";
import { FileUploader } from "../Shared/FileUploader";

import getValidUrl from "../../Utilities/getValidUrl";

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(12),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  submitGridItem: {
    position: "fixed",
    bottom: "60px",
    // left: (props) => (props.sidebar?.open ? "120px" : "2px"),
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  },
  submitButton: {
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
  toggleButtonGroup: {
    "& .Mui-selected": {
      color: "#FFF",
      backgroundColor: theme.palette.primary.main,
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  toggleButton: {
    height: "40px",
    width: "40px",
  },
}));

const AssetInfoField = ({ ...props }) => {
  const [field, meta] = useField(props);
  if (field.value === null) {
    field.value = "";
  }
  return (
    <>
      <Grid container>
        <Grid item xs>
          <Field
            className="text-input"
            component={TextField}
            margin="dense"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div className="error" style={{ color: "red" }}>
              {meta.error}
            </div>
          ) : null}
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "8px",
          }}
        >
          <Tooltip title={props.tooltip ? props.tooltip : ""} placement="left">
            <InfoIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

const EmailLookupField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  if (field.value === null) {
    field.value = "";
  }
  const { setValue } = helpers;

  let validEmail = props.user.email.substring(
    props.user.email.lastIndexOf("@") + 1
  );

  let propsForField = { ...props };
  // remove props that shouldnt' be placed on <Field>
  delete propsForField.setcurrentabstract;
  delete propsForField.currentabstract;
  delete propsForField.setupdateneeded;
  delete propsForField.setemailerror;
  delete propsForField.setemailerrormessage;
  delete propsForField.user;

  return (
    <>
      <Field
        className="text-input"
        component={TextField}
        margin="dense"
        variant="outlined"
        fullWidth
        {...field}
        {...propsForField}
        // {...props}
        onKeyPress={(e) => {
          e.which === 13 && e.preventDefault();
        }}
      />
      <Button
        color="primary"
        variant="contained"
        size="large"
        fullWidth
        // all lowercase
        onClick={() => {
          lookupContact(
            field.value,
            props.currentabstract,
            props.role,
            props.setcurrentabstract,
            props.setupdateneeded,
            props.setemailerror,
            props.setemailerrormessage,
            setValue,
            validEmail
          );
        }}
      >
        + Add {props.role}
      </Button>
      {/* {meta.touched && meta.error ? (
                <div className="error" style={{color: 'red',}}>{meta.error}</div>
            ) : null} */}
    </>
  );
};

const lookupContact = async (
  email,
  currentAbstract,
  role,
  setCurrentAbstract,
  setUpdateNeeded,
  setEmailError,
  setEmailErrorMessage,
  setValue,
  validEmail
) => {
  // axios request to look up email
  // assign response to contact
  // return contact
  email = email.toLowerCase();
  let validation = true;

  if (!email) {
    validation = false;
    setEmailError(role);
    setEmailErrorMessage("Email is required");
  }

  if (email.indexOf(" ") > 0) {
    validation = false;
    setEmailError(role);
    setEmailErrorMessage("Email cannot contain spaces");
  }

  if (!email.includes("@") || !email.includes(".")) {
    validation = false;
    setEmailError(role);
    setEmailErrorMessage("Email must contain:  @ and .");
  }

  if (!email.includes(validEmail)) {
    validation = false;
    setEmailError(role);
    setEmailErrorMessage(
      "Cannot add users from outside your company. Please contact PCI to request and add them."
    );
  }

  if (validation) {
    const user = await Auth.currentSession();

    let contact = await axios({
      method: "GET",
      url: `/contacts/${email}`,
      headers: { idtoken: user.idToken.jwtToken },
      email,
    });

    let newContact;
    if (contact.data.length) {
      contact = contact.data[0];
      newContact = {
        abstract_id: currentAbstract.abstract_id,
        contact_id: contact.contact_id,
        roles: [role],
        order: null,
        email: contact.email,
        first_name: contact.first_name,
        last_name: contact.last_name,
        title: contact.title,
        account_id: contact.account_id,
        account_name: currentAbstract.account_name,
        department: contact.department,
        site: contact.site,
        photo_url: contact.photo_url,
        expertise_area: contact.expertise_area,
        bio: contact.bio,
        modality: contact.modality,
        mailing_country: contact.mailing_country,
      };
    } else {
      newContact = {
        abstract_id: currentAbstract.abstract_id,
        contact_id: "",
        roles: [role],
        order: null,
        email: email,
        first_name: "",
        last_name: "",
        title: "",
        account_id: currentAbstract.account_id,
        account_name: currentAbstract.account_name,
        department: "",
        site: "",
        photo_url: "",
        expertise_area: "",
        bio: "",
        modality: "",
        mailing_country: "",
      };
    }

    let potentialContactUpdate = null;
    let potentialIndexUpdate = null;
    currentAbstract.contacts.forEach((contact, index) => {
      if (contact.contact_id === newContact.contact_id) {
        potentialContactUpdate = contact;
        potentialIndexUpdate = index;
      }
    });
    let skipEmailError = false;
    if (!potentialContactUpdate) {
      // let roles = [...potentialContactUpdate.roles]
      let newRoles = [...newContact.roles, role];
      let uniqueNewRoles = [...new Set(newRoles)];
      newContact.roles = uniqueNewRoles;
      currentAbstract.contacts.push(newContact);
    } else {
      // contact already exists on abstract, needs additional role applied
      let roles = [...potentialContactUpdate.roles];
      let newRoles = [...potentialContactUpdate.roles, role];
      let uniqueNewRoles = [...new Set(newRoles)];
      potentialContactUpdate.roles = uniqueNewRoles;
      currentAbstract.contacts[potentialIndexUpdate] = potentialContactUpdate;
      if (roles.includes(role)) {
        setEmailError(role);
        setEmailErrorMessage(`This person is already assigned as ${role}`);
        setUpdateNeeded(true);
        skipEmailError = true;
      }
    }

    setCurrentAbstract(currentAbstract);
    setUpdateNeeded(true);
    if (!skipEmailError) {
      setEmailError(false);
      setEmailErrorMessage("");
    }
    setValue("");
  }
};

const AssetToggleField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);
  if (field.value === null) {
    field.value = "";
  }
  const { setValue } = helpers;
  let toggleEnabled = false;

  function setTrueFalse(toggleEnabled) {
    toggleEnabled = !toggleEnabled;
    if (toggleEnabled) {
      setValue("Yes - Approved");
    } else {
      setValue("No");
    }
  }

  switch (field.value) {
    case "":
      setValue("No");
      toggleEnabled = false;
      break;
    case "No":
      toggleEnabled = false;
      break;
    case "Yes - Approved":
      toggleEnabled = true;
      break;
    default:
      setValue("No");
      toggleEnabled = false;
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Field
            className={props.togglebuttongroup}
            // className={props.toggleButtonGroup}
            style={{}}
            component={ToggleButtonGroup}
          >
            <ToggleButton
              className={props.togglebutton}
              value="check"
              selected={toggleEnabled}
              // selected={field.value}
              {...field}
              {...props}
              onClick={() => setTrueFalse(toggleEnabled)}
            >
              <CheckIcon fontSize="small" />
            </ToggleButton>
          </Field>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </Grid>
        {/* <Grid
          item
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title={props.tooltip ? props.tooltip : ""} placement="left">
            <InfoIcon />
          </Tooltip>
        </Grid> */}
      </Grid>
    </>
  );
};

const ConfirmButtons = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  if (field.value === null) {
    field.value = "";
  }

  const updateContacts = () => {
    let selectedContact = props.currentContact;
    selectedContact.roles = selectedContact.roles.filter(
      (role) => role !== props.deleteType
    );
    let revisedContacts = props.values.contacts
      .map((contact) => {
        if (contact.contact_id === selectedContact.contact_id) {
          if (selectedContact.roles.length === 0) {
            // this is set up here to return false and be used in filter to
            // remove contacts that are no longer attached to this abstract
            return false;
          } else {
            contact.roles = selectedContact.roles;
            return contact;
          }
        } else {
          return contact;
        }
      })
      .filter((contact) => contact);
    setValue(revisedContacts);
    props.currentAbstract.contacts = revisedContacts;
    props.setCurrentAbstract(props.currentAbstract);
    props.setClose();
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={updateContacts}>
        Confirm
      </Button>
      <br />
      <Button variant="contained" color="secondary" onClick={props.setClose}>
        Cancel
      </Button>
    </>
  );
};

const wait = async function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

let validateURL = (url) => {
  let error;
  const urlExpression =
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
  let regex = new RegExp(urlExpression);
  if (url !== "") {
    if (url.match(regex)) {
      error = "";
    } else {
      error = "Invalid URL";
    }
  }
  return error;
};

const CompanyTagField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;
  let tags;
  if (props.values.keywords) {
    tags = props.values.keywords.split(",");
  } else {
    tags = [];
  }

  // const handleOnKeyDown = (e) => {
  //   if (e.keyCode === 13) {
  //     props.values.keywords = [...tags, e.target.value];
  //     setValue('');
  //   }
  // };

  const handleAddTagButton = (e) => {
    if (e) {
      tags.push(e);
    }
    props.values.keywords = tags.join(",");
    // props.values.keywords = [...tags, e.target.value];
    setValue("");
  };

  return (
    <Grid container>
      <Grid item xs>
        <Box
          component="ul"
          style={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            padding: "2px",
            margin: 0,
          }}
        >
          <Field
            component={TextField}
            //   className={this.classes.infoField}
            // type="text"
            margin="dense"
            variant="outlined"
            fullWidth
            // onKeyDown={handleOnKeyDown}
            {...field}
            {...props}
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <Button
            color="primary"
            variant="contained"
            // size='large'
            style={{ width: "100%", marginBottom: "16px" }}
            onClick={() => handleAddTagButton(field.value)}
          >
            Add Tag
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title={props.tooltip ? props.tooltip : ""} placement="left">
          <InfoIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const CompanyChips = ({ ...props }) => {
  // const classes = useStyles();
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;
  let tags;
  if (props.values.keywords) {
    tags = props.values.keywords.split(",");
  } else {
    tags = [];
  }

  const handleDelete = (e) => {
    tags = tags.filter((tag) => {
      return tag !== e;
    });
    props.values.keywords = tags.join(",");
    setValue(tags);
  };

  let tagsMapped;
  if (tags) {
    tagsMapped = tags.map((data, index) => {
      return (
        <li key={index}>
          <Chip
            label={data}
            style={{ margin: "2px" }}
            color="primary"
            onDelete={() => handleDelete(data)}
          />
        </li>
      );
    });
  } else {
    tagsMapped = null;
  }

  return (
    <Box
      component="ul"
      style={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "2px",
        margin: 0,
      }}
    >
      {tagsMapped}
    </Box>
  );
};

const viewGetSignedFile = async (fullPdfUrl) => {
  try {
    if (fullPdfUrl.includes("eventhorizon-assets")) {
      const user = await Auth.currentSession();

      let filenamePath = fullPdfUrl.split(".com/")[1];

      let dataShape = {
        operation: "getObject",
        file_name: filenamePath,
        bucket_name: "eventhorizon-assets",
      };

      const signedUrl = await axios({
        method: "POST",
        url: "/upload/signed",
        headers: {
          idtoken: user.idToken.jwtToken,
        },
        data: dataShape,
      });

      window.open(signedUrl.data, "_blank");
    } else {
      window.open(getValidUrl(fullPdfUrl), "_blank");
    }
  } catch (error) {
    console.log(error);
    window.open(getValidUrl(fullPdfUrl), "_blank");
  }
};

export function AssetInfo(props) {
  const [currentAbstract, setCurrentAbstract] = useState(props.currentAbstract);
  const [success, setSuccess] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updateNeeded, setUpdateNeeded] = useState(false);
  // const [currentEmail, setCurrentEmail] = useState('');
  // VVVVVV to be used when we enable delete/add of roles VVVVVV
  const [deleteType, setDeleteType] = useState(""); //eslint-disable-line
  const [currentContact, setCurrentContact] = useState({
    contact_id: null,
    roles: [],
  }); //eslint-disable-line
  // ^^^^^^ to be used when we enable delete/add of roles ^^^^^^
  const [emailError, setEmailError] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const [video, setVideo] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setUpdateNeeded(false);
    // setEmailError(false);
    // setEmailErrorMessage('');
  }, [currentAbstract, updateNeeded, currentContact]);

  const handleFileUpload = (file, meta, status) => {
    setFile(file);
  };

  const handleVideoUpload = (video, meta, status) => {
    setVideo(video);
  };

  let userIsBoothAdmin = props.user.roles.toLowerCase().includes("booth admin");
  let userIsSubmitter = false;
  if (currentAbstract.contacts.length > 0) {
    currentAbstract.contacts
      .filter((contact) =>
        contact.roles.join().toLowerCase().includes("submitter")
      )
      .forEach((submitter) => {
        if (props.user.email.toLowerCase() === submitter.email.toLowerCase()) {
          userIsSubmitter = true;
        }
      });
  }

  let upgradedCurrentAbstract = {
    ...currentAbstract,
    newPresenterEmail: "",
    newAuthorEmail: "",
    currentTag: "",
  };

  let someoneElsesContactDisclaimer = (
    <Typography variant="subtitle2" color="primary">
      You are editing someone elses contact. Please ensure that the email
      matches the details you enter. If it does not, remove this person and
      lookup a new email.
    </Typography>
  );
  // <Typography variant="subtitle2" color="primary">
  //   Editing this contact is not allowed as you are not
  //   this person. You may remove this contact or look up
  //   another person but may not modify this current
  //   contact.{" "}
  // </Typography>
  return (
    <>
      <Formik
        initialValues={upgradedCurrentAbstract}
        // initialValues={currentAbstract}
        onSubmit={async (values, { setSubmitting }) => {
          await new Promise((r) => setTimeout(r, 500));

          try {
            const user = await Auth.currentSession();

            const functionUploadFile = async (item, fieldName) => {
              try {
                let filenamePath = `${props.show.pharma_company.toLowerCase()}/${
                  props.show.show_code
                }/${item.name}`;
                let dataShape = {
                  operation: "putObject",
                  file_name: filenamePath,
                  bucket_name: "eventhorizon-assets",
                };

                const signedUrl = await axios({
                  method: "POST",
                  url: "/upload/signed",
                  headers: {
                    idtoken: user.idToken.jwtToken,
                  },
                  data: dataShape,
                });

                await fetch(signedUrl.data, {
                  method: "PUT",
                  body: item,
                });

                values[fieldName] = signedUrl.data.split("?")[0];
              } catch (error) {
                console.log(error);
              }
            };

            // if (!values.presentation_pdf) {
            if (file) {
              let formData = new FormData();
              formData.append("file", file);

              try {
                await functionUploadFile(file, "presentation_pdf");
              } catch (error) {
                console.log(error);
              }
            }
            // }
            // if (!values.presentation_video) {
            if (video) {
              let formData = new FormData();
              formData.append("file", video);

              try {
                await functionUploadFile(video, "presentation_video");
              } catch (error) {
                console.log(error);
              }
            }
            // }

            const response = await axios({
              method: "PUT",
              url: `/zoho/abstracts/${currentAbstract.show_id}/${currentAbstract.account_id}/${currentAbstract.abstract_id}`,
              headers: { idtoken: user.idToken.jwtToken },
              data: {
                values,
              },
            });

            const contactResponses = [];
            for (let i = 0; i < values.contacts.length; i++) {
              let newValues = values.contacts[i];
              newValues.id = newValues.contact_id;

              if (newValues.contact_id) {
                const response2 = await axios({
                  method: "PUT",
                  url: `/zoho/contacts/id/${newValues.contact_id}`,
                  headers: { idtoken: user.idToken.jwtToken },
                  data: newValues,
                });
                contactResponses.push(response2);
              }
            }

            await wait(3000);

            if (JSON.parse(response.data.body).data[0].code === "SUCCESS") {
              setSuccess(true);
            } else {
              alert("We encountered an error updating your abstract1");
              setSuccess(false);
            }
          } catch (error) {
            alert("We encountered an error updating your abstract2");
            console.log(error);
          }
          setSubmitting(false);

          props.getAssets();
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Abstract Information
                  </Typography>
                  <Divider className={classes.divider} />
                  <AssetInfoField
                    name="title"
                    id="title"
                    label="Abstract Title"
                    type="text"
                    multiline
                    placeholder="Abstract Title"
                    tooltip="The title of your abstract"
                  />
                  <AssetInfoField
                    disabled
                    name="accepted_type"
                    id="accepted_type"
                    label="Accepted Type"
                    type="text"
                    multiline
                    placeholder="Accepted Type"
                    tooltip="The type assigned to your poster by the committee"
                  />
                  <AssetInfoField
                    disabled
                    name="seq_no"
                    id="seq_no"
                    label="Sequence Number"
                    type="text"
                    multiline
                    placeholder="Sequence Number"
                    tooltip="Your abstracts sequence number"
                  />
                  <AssetInfoField
                    name="content"
                    id="content"
                    label="Abstract"
                    type="text"
                    placeholder="Abstract Content"
                    minRows={5}
                    maxRows={Infinity}
                    multiline
                    tooltip="The main content about your abstract"
                  />
                  {/* REMOVED FOR SANOFI, PUT BACK IN AFTER SGAS23 */}
                  {/* <AssetInfoField
                    disabled={
                      values.presentation_link.toLowerCase().includes("whereby")
                        ? true
                        : false
                    }
                    name="presentation_link"
                    id="presentation_link"
                    label="Meet Me Link"
                    type="text"
                    placeholder="Meet Me Link"
                    validate={validateURL}
                    tooltip="The video call link you'd like to be public 
                    for attendees to join and talk to you"
                  /> */}
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <Typography align="center" variant="body2">
                        Keyword Tags
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="keywords"
                        render={() => {
                          return (
                            <>
                              <CompanyTagField
                                name="currentTag"
                                id="currentTag"
                                label="Input Your Abstract Tags"
                                values={values}
                                // onFocus={this.tagFieldFocus}
                                // onBlur={this.tagFieldBlur}
                                tooltip="Keyword tags related to your abstract"
                              />
                              <CompanyChips
                                name="tags"
                                id="tags"
                                label="Tags"
                                values={values}
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginRight: "8px",
                        marginTop: "4px",
                        flexGrow: 0,
                      }}
                    >
                      <AssetToggleField
                        name="cleared_for_public_view"
                        id="cleared_for_public_view"
                        togglebuttongroup={classes.toggleButtonGroup}
                        // toggleButtonGroup={classes.toggleButtonGroup}
                        togglebutton={classes.toggleButton}
                        // toggleButton={classes.toggleButton}
                      />
                    </Grid>
                    {/* <Grid item> */}
                    <Typography variant="body1">
                      Is cleared for public view
                    </Typography>
                    {/* </Grid> */}
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <Tooltip
                        title="Toggle on if you would like to make your abstract public to all attendee and exhibitors. Toggle off to make it private so other exhibitors cannot see it."
                        placement="left"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                    {values.cleared_for_public_view === "Yes - Approved" ? (
                      <Typography
                        color="error"
                        variant="body1"
                        style={{ marginLeft: "4px" }}
                      >
                        Anyone at the event can view your abstract.
                      </Typography>
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs>
                      <Typography className={classes.header} variant="h5">
                        Presentation PDF
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title="Upload a PDF about the asset"
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Typography
                    color="primary"
                    className={classes.header}
                    variant="subtitle2"
                  >
                    If your PDF is hosted internally, DO NOT drag and drop a PDF
                    below. Instead, provide us the URL to your PDF. <br />
                    {/* Providing a link here will overwrite any PDF uploaded below. */}
                  </Typography>
                  <AssetInfoField
                    name="presentation_pdf"
                    id="presentation_pdf"
                    label="Presentation PDF URL"
                    type="text"
                    multiline
                    placeholder="Presentation PDF URL"
                    tooltip="A link to a PDF about your abstract"
                  />
                  <Divider className={classes.divider} />
                  {currentAbstract.presentation_pdf ? (
                    <>
                      <Button
                        // href={currentAbstract.presentation_pdf}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={() =>
                          viewGetSignedFile(currentAbstract.presentation_pdf)
                        }
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        View PDF
                      </Button>
                      <Divider className={classes.divider} />
                    </>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <Typography className={classes.header} variant="subtitle1">
                    Update PDF:
                  </Typography>
                  <Typography className={classes.header} variant="subtitle2">
                    Accepts PDF. Max size 50mb.
                  </Typography>
                  <FileUploader
                    handleFileUpload={handleFileUpload.bind(this)}
                    acceptedTypes={[".pdf"]}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs>
                      <Typography className={classes.header} variant="h5">
                        Intro Video
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title="Upload a Video about the asset or provide your own link"
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Typography
                    color="primary"
                    className={classes.header}
                    variant="subtitle2"
                  >
                    If your Video is hosted internally, DO NOT drag and drop a
                    Video below. Instead, provide us the URL to your Video.
                    <br />
                    {/* Providing a link here will overwrite any Video uploaded
                    below. */}
                  </Typography>
                  <AssetInfoField
                    name="presentation_video"
                    id="presentation_video"
                    label="Intro Video URL"
                    type="text"
                    multiline
                    placeholder="Intro Video URL"
                    validate={validateURL}
                    tooltip="A link to an intro video about your abstract"
                  />
                  <Divider className={classes.divider} />
                  {currentAbstract.presentation_video ? (
                    <>
                      <Button
                        // href={currentAbstract.presentation_video}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={() =>
                          viewGetSignedFile(currentAbstract.presentation_video)
                        }
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        View Video
                      </Button>
                      <Divider className={classes.divider} />
                    </>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <Typography className={classes.header} variant="subtitle1">
                    Update Video:
                  </Typography>
                  <Typography className={classes.header} variant="subtitle2">
                    Accepts Video formats such as mp4, mov, wmv, avi. Max size
                    50mb.
                  </Typography>
                  <FileUploader
                    handleFileUpload={handleVideoUpload.bind(this)}
                    acceptedTypes={["video/*"]}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4}>
                {currentAbstract.contacts.filter((contact) =>
                  contact.roles.includes("Presenter")
                ).length ? null : (
                  <Paper className={classes.paper}>
                    <Typography
                      color="primary"
                      variant="body1"
                      style={{ textAlign: "center" }}
                    >
                      A presenter contact is required for an abstract prior to
                      show open. Please choose your presenter.
                    </Typography>
                  </Paper>
                )}
                {currentAbstract.contacts.map((contact, index) => {
                  let isPresenter = contact.roles
                    .join()
                    .toLowerCase()
                    .includes("presenter");
                  let isSelf =
                    props.user.email.toLowerCase() ===
                    contact.email.toLowerCase();
                  if (isPresenter) {
                    return (
                      <Paper
                        className={classes.paper}
                        key={`presenter${index}`}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography className={classes.header} variant="h5">
                              Presenter Info
                            </Typography>
                          </Grid>
                          {userIsBoothAdmin || userIsSubmitter ? (
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setConfirmDelete(true);
                                  setDeleteType("Presenter");
                                  setCurrentContact({
                                    contact_id: contact.contact_id,
                                    roles: [...contact.roles, "Presenter"],
                                  });
                                }}
                              >
                                X
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Divider className={classes.divider} />
                        {!isSelf ? someoneElsesContactDisclaimer : null}
                        <AssetInfoField
                          disabled
                          name={`contacts[${index}].email`}
                          id={`contacts[${index}].email`}
                          label="Presenter Email"
                          type="text"
                          multiline
                          placeholder="Presenter Email"
                          required
                          tooltip="Presenter's work email address"
                        />
                        {/* {values.contacts[index].contact_id ? null : emailAssetInfoField } */}
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].first_name`}
                          id={`contacts[${index}].first_name`}
                          label="Presenter First Name"
                          type="text"
                          multiline
                          placeholder="Presenter First Name"
                          required
                          tooltip="Presenter's forename"
                        />
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].last_name`}
                          id={`contacts[${index}].last_name`}
                          label="Presenter Last Name"
                          type="text"
                          multiline
                          placeholder="Presenter Last Name"
                          required
                          tooltip="Presenter's surname"
                        />
                        {/* <AssetInfoField
                                            disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                                                name={`contacts[${index}].account_name`}
                                                id={`contacts[${index}].account_name`}
                                                label='Presenter Account'
                                                type='text'
                                                multiline
                                                placeholder='Presenter Account'
                                            /> */}
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].title`}
                          id={`contacts[${index}].title`}
                          label="Presenter Title"
                          type="text"
                          multiline
                          placeholder="Presenter Title"
                          tooltip="Presenter's job title"
                        />
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].department`}
                          id={`contacts[${index}].department`}
                          label="Presenter Department"
                          type="text"
                          multiline
                          placeholder="Presenter Department"
                          tooltip="Presenter's department within the company"
                        />
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].site`}
                          id={`contacts[${index}].site`}
                          label="Presenter Site"
                          type="text"
                          multiline
                          placeholder="Presenter Site"
                          tooltip="Presenter's location"
                        />
                      </Paper>
                    );
                  } else {
                    return null;
                  }
                })}
                {userIsBoothAdmin || userIsSubmitter ? (
                  <Paper className={classes.paper}>
                    <Typography variant="subtitle2">
                      Enter email first, then + Add Presenter
                    </Typography>
                    {emailError === "Presenter" ? (
                      <>
                        <Divider className={classes.divider} />
                        <Typography color="error">
                          {emailErrorMessage}
                        </Typography>
                        <Divider className={classes.divider} />
                      </>
                    ) : null}
                    <EmailLookupField
                      name="newPresenterEmail"
                      id="newPresenterEmail"
                      label="Lookup Contact Email"
                      type="text"
                      role="Presenter"
                      placeholder="Lookup Contact Email"
                      // currentEmail={currentEmail}
                      // setCurrentEmail={setCurrentEmail}
                      setcurrentabstract={setCurrentAbstract}
                      currentabstract={currentAbstract}
                      // lookupContact={lookupContact}
                      setupdateneeded={setUpdateNeeded}
                      setemailerror={setEmailError}
                      setemailerrormessage={setEmailErrorMessage}
                      user={props.user}
                    />
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                {currentAbstract.contacts.map((contact, index) => {
                  let isAuthor = contact.roles
                    .join()
                    .toLowerCase()
                    .includes("author");
                  let isSelf =
                    props.user.email.toLowerCase() ===
                    contact.email.toLowerCase();
                  if (isAuthor) {
                    // console.log(contact);
                    return (
                      <Paper className={classes.paper} key={`author${index}`}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography className={classes.header} variant="h5">
                              Author Info
                            </Typography>
                          </Grid>
                          {userIsBoothAdmin || userIsSubmitter ? (
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setConfirmDelete(true);
                                  setDeleteType("Author");
                                  setCurrentContact({
                                    contact_id: contact.contact_id,
                                    roles: [...contact.roles, "Author"],
                                  });
                                }}
                              >
                                X
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Divider className={classes.divider} />
                        {!isSelf ? someoneElsesContactDisclaimer : null}
                        <AssetInfoField
                          disabled
                          name={`contacts[${index}].email`}
                          id={`contacts[${index}].email`}
                          label="Author Email"
                          type="text"
                          multiline
                          placeholder="Author Email"
                          required
                          tooltip="Author's work email address"
                        />
                        {/* {values.contacts[index].contact_id ? null : emailAssetInfoField } */}
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].first_name`}
                          id={`contacts[${index}].first_name`}
                          label="Author First Name"
                          type="text"
                          multiline
                          placeholder="Author First Name"
                          required
                          tooltip="Author's forename"
                        />
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].last_name`}
                          id={`contacts[${index}].last_name`}
                          label="Author Last Name"
                          type="text"
                          multiline
                          placeholder="Author Last Name"
                          required
                          tooltip="Author's surname"
                        />
                        {/* <AssetInfoField
                                                // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                                                    name={`contacts[${index}].account_name`}
                                                    id={`contacts[${index}].account_name`}
                                                    label='Author Account'
                                                    type='text'
                                                    multiline
                                                    placeholder='Author Account'
                                                /> */}
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].title`}
                          id={`contacts[${index}].title`}
                          label="Author Title"
                          type="text"
                          multiline
                          placeholder="Author Title"
                          tooltip="Author's job title"
                        />
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].department`}
                          id={`contacts[${index}].department`}
                          label="Author Department"
                          type="text"
                          multiline
                          placeholder="Author Department"
                          tooltip="Author's department within the company"
                        />
                        <AssetInfoField
                          // disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                          name={`contacts[${index}].site`}
                          id={`contacts[${index}].site`}
                          label="Author Site"
                          type="text"
                          multiline
                          placeholder="Author Site"
                          tooltip="Author's location"
                        />
                      </Paper>
                    );
                  } else {
                    return null;
                  }
                })}
                {userIsBoothAdmin || userIsSubmitter ? (
                  <Paper className={classes.paper}>
                    {emailError === "Author" ? (
                      <Typography color="error">{emailErrorMessage}</Typography>
                    ) : null}
                    <Typography variant="subtitle2">
                      Enter email first, then + Add Author
                    </Typography>
                    <EmailLookupField
                      name="newAuthorEmail"
                      id="newAuthorEmail"
                      label="Lookup Contact Email"
                      type="text"
                      role="Author"
                      placeholder="Lookup Contact Email"
                      // currentEmail={currentEmail}
                      // setCurrentEmail={setCurrentEmail}
                      setcurrentabstract={setCurrentAbstract}
                      currentabstract={currentAbstract}
                      // lookupcontact={lookupContact}
                      setupdateneeded={setUpdateNeeded}
                      setemailerror={setEmailError}
                      setemailerrormessage={setEmailErrorMessage}
                      user={props.user}
                    />
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                {currentAbstract.contacts.map((contact, index) => {
                  let isSubmitter = contact.roles
                    .join()
                    .toLowerCase()
                    .includes("submitter");
                  if (isSubmitter) {
                    return (
                      <Paper
                        className={classes.paper}
                        key={`submitter${index}`}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography className={classes.header} variant="h5">
                              Submitter Info
                            </Typography>
                          </Grid>
                          {/* <Grid item>
                                                        <Button 
                                                        variant='outlined' 
                                                        onClick={() => {
                                                            setConfirmDelete(true);
                                                            setDeleteType('Submitter');
                                                            setCurrentContact(contact);
                                                        }}
                                                        >
                                                            X
                                                        </Button>
                                                    </Grid> */}
                        </Grid>
                        <Divider className={classes.divider} />
                        <AssetInfoField
                          disabled
                          name={`contacts[${index}].email`}
                          id={`contacts[${index}].email`}
                          label="Submitter Email"
                          type="text"
                          multiline
                          placeholder="Submitter Email"
                          tooltip="Submitter's work email address"
                        />
                        <AssetInfoField
                          disabled={
                            userIsBoothAdmin || userIsSubmitter ? false : true
                          }
                          name={`contacts[${index}].first_name`}
                          id={`contacts[${index}].first_name`}
                          label="Submitter First Name"
                          type="text"
                          multiline
                          placeholder="Submitter First Name"
                          tooltip="Submitter's forename"
                        />
                        <AssetInfoField
                          disabled={
                            userIsBoothAdmin || userIsSubmitter ? false : true
                          }
                          name={`contacts[${index}].last_name`}
                          id={`contacts[${index}].last_name`}
                          label="Submitter Last Name"
                          type="text"
                          multiline
                          placeholder="Submitter Last Name"
                          tooltip="Submitter's surname"
                        />
                        {/* <AssetInfoField
                                                disabled={userIsBoothAdmin || userIsSubmitter ? false : true}
                                                    name={`contacts[${index}].account_name`}
                                                    id={`contacts[${index}].account_name`}
                                                    label='Submitter Account'
                                                    type='text'
                                                    multiline
                                                    placeholder='Submitter Account'
                                                /> */}
                        <AssetInfoField
                          disabled={
                            userIsBoothAdmin || userIsSubmitter ? false : true
                          }
                          name={`contacts[${index}].title`}
                          id={`contacts[${index}].title`}
                          label="Submitter Title"
                          type="text"
                          multiline
                          placeholder="Submitter Title"
                          tooltip="Submitter's job title"
                        />
                        <AssetInfoField
                          disabled={
                            userIsBoothAdmin || userIsSubmitter ? false : true
                          }
                          name={`contacts[${index}].department`}
                          id={`contacts[${index}].department`}
                          label="Submitter Department"
                          type="text"
                          multiline
                          placeholder="Submitter Department"
                          tooltip="Submitter's department within the company"
                        />
                        <AssetInfoField
                          disabled={
                            userIsBoothAdmin || userIsSubmitter ? false : true
                          }
                          name={`contacts[${index}].site`}
                          id={`contacts[${index}].site`}
                          label="Submitter Site"
                          type="text"
                          multiline
                          placeholder="Submitter Site"
                          tooltip="Submitter's location"
                        />
                      </Paper>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.submitGridItem}
              style={{ left: props.sidebar?.open ? "120px" : "2px" }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                className={classes.submitButton}
                color="primary"
                variant="contained"
                size="large"
              >
                <Typography variant="h6">
                  {isSubmitting ? "Saving..." : "Save Changes"}
                </Typography>
              </Button>
            </Grid>
            <Dialog
              open={confirmDelete}
              handleClose={() => setConfirmDelete(false)}
            >
              <Typography>
                Are you sure you want to remove this '{deleteType}' role?
              </Typography>
              <ConfirmButtons
                name="contacts"
                id="contacts"
                setClose={() => setConfirmDelete(false)}
                currentContact={currentContact}
                currentAbstract={currentAbstract}
                setCurrentAbstract={setCurrentAbstract}
                deleteType={deleteType}
                values={values}
              />
            </Dialog>
          </Form>
        )}
      </Formik>
      <Dialog open={success} handleClose={() => props.handleEditClose()}>
        <Typography variant="h5">Success!</Typography>
        <Typography>Your Abstract has been updated.</Typography>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.handleEditClose()}
        >
          X Close
        </Button>
      </Dialog>
    </>
  );
}
