import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Top Ten Most Popular Exhibitors'
        }
      },
      yAxes: [
        {
          ticks: {
            display: true
          }
        }
      ]
};

export default function Reporting(props) {
const [ filteredReport, setFilteredReport] = useState([])

    useEffect(() => {

            let exhibitors = []
            let exhibitorClickCount = {}

            for (let i = 0; i < props.report.length; i++){

              let looking= true;
              let j = 0

              while(looking){
                if(Object.keys(exhibitorClickCount)[j] === props.report[i].resource_name){
                  
                  exhibitorClickCount[props.report[i].resource_name] ++
                  looking = false
                  
                } else if (j === props.report.length){
                  
                  exhibitorClickCount[props.report[i].resource_name] = 1;
                  looking = false

                } else {

                  j++

                }
              }
            }
          
            let newExhibitorClickCount = {}
    
            props.report.map((datum) => {

                    if(!exhibitors.includes(datum.resource_name)){
                        exhibitors.push(datum.resource_name)
                    }

                    if (!Object.keys(newExhibitorClickCount).includes(datum.resource_name)){
                        newExhibitorClickCount[datum.resource_name] = 1;
                    } else {
                        newExhibitorClickCount[datum.resource_name]++
                    }
    
                return true
            })
          

            let sortednExhibitorClickCount = Object.entries(newExhibitorClickCount)
            .sort(([,a],[,b]) => b - a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});


            let labels = Object.keys(sortednExhibitorClickCount).slice(0, 10)
            let data = Object.values(sortednExhibitorClickCount).slice(0, 10)


            setFilteredReport({
                labels,
                datasets: [
                    {
                      label: 'Total Clicks',
                      data,
                      fill: true,
                      backgroundColor: '#6f1a1d',
                      borderColor: '#6f1a1d',
                    },
                ]
            })

    }, [props.report])

  return props.loading ? <CircularProgress/> : <Bar data={filteredReport} options={options} />
}
