import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dialog from "../../Components/Dialog";
import VotingLikes from "./VotingLikes";

import { Formik, Form, Field, useField } from "formik";
// import { FileUploader } from '../Shared/FileUploader';

import moment from "moment";

import { Auth } from "aws-amplify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  exhibitInfo: {
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.spacing(0.5),
  },
  submitGridItem: {
    position: "fixed",
    bottom: "16px",
    width: "100%",
    zIndex: 1,
  },
  submitButton: {
    color: "white",
    width: "70%",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    marginLeft: "10%",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
  notes: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  notesSubmitGridItem: {
    //
  },
  notesSubmitButton: {
    color: "white",
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
}));

const AbstractInfoField = ({ ...props }) => {
  const [field] = useField(props);
  if (field.value === null) {
    field.value = "";
  }
  if (field.name === "start_time") {
    if (field.value === "") {
    } else {
      field.value = moment(field.value, "HH:mm:ss").format("LT");
    }
  }

  const styledTextField = withStyles({
    root: {
      "& div.MuiInputBase-root.Mui-disabled": {
        color: "black",
        background: "#f3f3f3",
      },
      "& label.MuiFormLabel-root.Mui-disabled": {
        color: "black",
      },
    },
  })(TextField);
  return (
    <>
      <Field
        className="text-input"
        component={props.disabled ? styledTextField : TextField}
        // component={TextField}
        margin="dense"
        variant="outlined"
        fullWidth
        {...field}
        {...props}
      />
    </>
  );
};

const wait = async function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function AbstractInfo(props) {
  const [success, setSuccess] = useState(false);
  // const [file, setFile] = useState(null)
  const classes = useStyles();

  let currentExhibitor = props.exhibitors.filter(
    (ex) => ex?.account_id === props.currentAbstract?.account_id
  )[0];

  let companyAndSponsorLevel;
  if (currentExhibitor) {
    let sponsorLevel;
    switch (currentExhibitor?.booth_type) {
      case "0":
        sponsorLevel = "Signature";
        break;
      case "1":
        sponsorLevel = "Diamond";
        break;
      case "2":
        sponsorLevel = "Platinum";
        break;
      case "3":
        sponsorLevel = "Gold";
        break;
      case "4":
        sponsorLevel = "Silver";
        break;
      case "5":
        sponsorLevel = "Booth";
        break;
      case "6":
        sponsorLevel = "Tabletop";
        break;
      default:
        sponsorLevel = "";
        break;
    }

    companyAndSponsorLevel = (
      <Paper className={classes.paper}>
        <Typography className={classes.exhibitInfo} variant="h5">
          Company
        </Typography>
        <Typography variant="h6">{currentExhibitor.account_name}</Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.exhibitInfo} variant="h5">
          Sponsor Level
        </Typography>
        <Typography variant="h6">{sponsorLevel}</Typography>
      </Paper>
    );
  }

  // const handleFileUpload = (file, meta, status) => {
  //     setFile(file)
  // }
  let abstractValues = { ...props.currentAbstract, new_note: "" };
  return (
    <>
      <Formik
        initialValues={abstractValues}
        onSubmit={async (
          values,
          { setSubmitting, setValues, setFieldValue }
        ) => {
          await new Promise((r) => setTimeout(r, 500));

          const user = await Auth.currentSession();

          let currentAbstract = await axios({
            method: "get",
            url: `/voting/${props.currentAbstract.show_id}/${props.currentAbstract.abstract_id}`,
            headers: {
              idtoken: user.idToken.jwtToken,
            },
          });
          let latestNotes = currentAbstract.data.data[0]?.description;

          let newValues = { ...values };
          let currentTime = moment().format("MMMDD-HH:mm");
          let newFormattedNote = `${currentTime} - ${props.user.first_name} ${props.user.last_name}: ${values.new_note}`;
          newValues.description = latestNotes + `\n\n` + newFormattedNote;

          values = newValues;

          // if(file){

          //     let formData = new FormData();
          //     formData.append('file', file);

          //     const uploadFile = await axios({
          //         method: "POST",
          //         url: '/upload',
          //         headers: {
          //             'Content-Type': 'multipart/form-data',
          //             idtoken: user.idToken.jwtToken
          //         },
          //         data: formData
          //     });

          //     values.presentation_pdf = uploadFile.data.Location;

          // }

          const response = await axios({
            method: "PUT",
            url: `/zoho/abstracts/${props.currentAbstract.show_id}/${props.currentAbstract.account_id}/${props.currentAbstract.abstract_id}`,
            headers: { idtoken: user.idToken.jwtToken },
            data: {
              values,
            },
          });

          const contactResponses = [];
          for (let i = 0; i < values.contacts.length; i++) {
            let newContactValues = values.contacts[i];
            newContactValues.id = newContactValues.contact_id;

            if (newContactValues.contact_id) {
              const response2 = await axios({
                method: "PUT",
                url: `/zoho/contacts/id/${newContactValues.contact_id}`,
                headers: { idtoken: user.idToken.jwtToken },
                data: newContactValues,
              });
              contactResponses.push(response2);
            }
          }

          await wait(3000);

          if (response.data.statusCode === 200) {
            setSuccess(true);
          } else {
            alert("Your Abstract could not be updated");
          }

          setSubmitting(false);

          await props.getAbstracts();
          await setValues(values);
          await setFieldValue("new_note", "");
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Abstract Information
                  </Typography>
                  <Typography variant="body2">
                    Fields with a gray background cannot be edited.
                  </Typography>
                  <Divider className={classes.divider} />
                  <AbstractInfoField
                    disabled
                    name="title"
                    id="title"
                    label="Abstract Title"
                    type="text"
                    multiline
                    placeholder="Abstract Title"
                  />
                  {/* will accepted type ever be showin in the voting phase? possibly remove */}
                  {values.accepted_type && (
                    <AbstractInfoField
                      disabled
                      name="accepted_type"
                      id="accepted_type"
                      label="Accepted Type"
                      type="text"
                      multiline
                      placeholder="Accepted Type"
                    />
                  )}
                  <AbstractInfoField
                    disabled
                    name="types"
                    id="types"
                    label="Submitted Type(s)"
                    type="text"
                    multiline
                    placeholder="Submitted Type(s)"
                  />
                  <AbstractInfoField
                    disabled
                    name="cleared_for_public_view"
                    id="cleared_for_public_view"
                    label="Cleared for Public View"
                    type="text"
                    multiline
                    placeholder="Cleared for Public View"
                  />
                  {/* will area of science ever be showin in the voting phase? possibly remove */}
                  {values.area_of_science && (
                    <AbstractInfoField
                      disabled
                      name="area_of_science"
                      id="area_of_science"
                      label="Area of Science"
                      type="text"
                      multiline
                      placeholder="Area of Science"
                    />
                  )}
                  <AbstractInfoField
                    disabled
                    name="topic"
                    id="topic"
                    label="Topic"
                    type="text"
                    multiline
                    placeholder="Topic"
                  />
                  <AbstractInfoField
                    disabled
                    name="content"
                    id="content"
                    label="Abstract"
                    type="text"
                    placeholder="Abstract Content"
                    maxRows={Infinity}
                    multiline
                  />
                  {/* will presentation video ever be showin in the voting phase? possibly remove */}
                  {values.presentation_video && (
                    <AbstractInfoField
                      disabled
                      name="presentation_video"
                      id="presentation_video"
                      label="Presentation Video URL"
                      type="text"
                      placeholder="Abstract Video URL"
                    />
                  )}
                  <AbstractInfoField
                    disabled
                    name="keywords"
                    id="keywords"
                    label="Keywords Tags"
                    type="text"
                    placeholder="Keyword Tags"
                    multiline
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <VotingLikes
                  currentAbstract={props.currentAbstract}
                  eventId={props.eventId}
                />
                {props.currentAbstract.presentation_pdf && (
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      Presentation PDF
                    </Typography>
                    <Divider className={classes.divider} />
                    {props.currentAbstract.presentation_pdf ? (
                      <a
                        href={props.currentAbstract.presentation_pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          fullWidth
                        >
                          View PDF
                        </Button>
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {/* <p>Update PDF:</p>
                                    <FileUploader 
                                    handleFileUpload = {handleFileUpload.bind(this)}
                                    /> */}
                  </Paper>
                )}

                {currentExhibitor && companyAndSponsorLevel}
              </Grid>
              <Grid item xs={4}>
                {props.currentAbstract.presenters.map((presenter, index) => {
                  return (
                    <Paper className={classes.paper} key={`presenter${index}`}>
                      <Typography className={classes.header} variant="h5">
                        Presenter Info
                      </Typography>
                      <Divider className={classes.divider} />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].first_name`}
                        id={`presenters[${index}].first_name`}
                        label="Presenter First Name"
                        type="text"
                        multiline
                        placeholder="Presenter First Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].last_name`}
                        id={`presenters[${index}].last_name`}
                        label="Presenter Last Name"
                        type="text"
                        multiline
                        placeholder="Presenter Last Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].account_name`}
                        id={`presenters[${index}].account_name`}
                        label="Presenter Account"
                        type="text"
                        multiline
                        placeholder="Presenter Account"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].title`}
                        id={`presenters[${index}].title`}
                        label="Presenter Title"
                        type="text"
                        multiline
                        placeholder="Presenter Title"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].department`}
                        id={`presenters[${index}].department`}
                        label="Presenter Department"
                        type="text"
                        multiline
                        placeholder="Presenter Department"
                      />
                      <AbstractInfoField
                        disabled
                        name={`presenters[${index}].site`}
                        id={`presenters[${index}].site`}
                        label="Presenter Site"
                        type="text"
                        multiline
                        placeholder="Presenter Site"
                      />
                    </Paper>
                  );
                })}
              </Grid>
              <Grid item xs={4}>
                {props.currentAbstract.authors.map((author, index) => {
                  return (
                    <Paper className={classes.paper} key={`author${index}`}>
                      <Typography className={classes.header} variant="h5">
                        Author Info
                      </Typography>
                      <Divider className={classes.divider} />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].first_name`}
                        id={`authors[${index}].first_name`}
                        label="Author First Name"
                        type="text"
                        multiline
                        placeholder="Author First Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].last_name`}
                        id={`authors[${index}].last_name`}
                        label="Author Last Name"
                        type="text"
                        multiline
                        placeholder="Author Last Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].account_name`}
                        id={`authors[${index}].account_name`}
                        label="Author Account"
                        type="text"
                        multiline
                        placeholder="Author Account"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].title`}
                        id={`authors[${index}].title`}
                        label="Author Title"
                        type="text"
                        multiline
                        placeholder="Author Title"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].department`}
                        id={`authors[${index}].department`}
                        label="Author Department"
                        type="text"
                        multiline
                        placeholder="Author Department"
                      />
                      <AbstractInfoField
                        disabled
                        name={`authors[${index}].site`}
                        id={`authors[${index}].site`}
                        label="Author Site"
                        type="text"
                        multiline
                        placeholder="Author Site"
                      />
                    </Paper>
                  );
                })}
              </Grid>
              <Grid item xs={4}>
                {props.currentAbstract.submitters.map((submitter, index) => {
                  return (
                    <Paper className={classes.paper} key={`submitter${index}`}>
                      <Typography className={classes.header} variant="h5">
                        Submitter Info
                      </Typography>
                      <Divider className={classes.divider} />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].first_name`}
                        id={`submitters[${index}].first_name`}
                        label="Submitter First Name"
                        type="text"
                        multiline
                        placeholder="Submitter First Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].last_name`}
                        id={`submitters[${index}].last_name`}
                        label="Submitter Last Name"
                        type="text"
                        multiline
                        placeholder="Submitter Last Name"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].account_name`}
                        id={`submitters[${index}].account_name`}
                        label="Submitter Account"
                        type="text"
                        multiline
                        placeholder="Submitter Account"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].title`}
                        id={`submitters[${index}].title`}
                        label="Submitter Title"
                        type="text"
                        multiline
                        placeholder="Submitter Title"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].department`}
                        id={`submitters[${index}].department`}
                        label="Submitter Department"
                        type="text"
                        multiline
                        placeholder="Submitter Department"
                      />
                      <AbstractInfoField
                        disabled
                        name={`submitters[${index}].site`}
                        id={`submitters[${index}].site`}
                        label="Submitter Site"
                        type="text"
                        multiline
                        placeholder="Submitter Site"
                      />
                    </Paper>
                  );
                })}
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <Paper className={classes.paper}>
                  <Typography className={classes.notes} variant="h5">
                    Voting Notes
                  </Typography>
                  <AbstractInfoField
                    disabled
                    name="description"
                    id="description"
                    label="Notes"
                    type="text"
                    placeholder="Notes"
                    multiline
                  />
                  <Divider className={classes.divider} />
                  <Typography className={classes.notes} variant="body1">
                    Add a new Note
                  </Typography>
                  <AbstractInfoField
                    name="new_note"
                    id="new_note"
                    label="Add a new Note"
                    type="text"
                    placeholder="Add a new Note"
                    multiline
                  />
                  <Grid item xs={10} sm={12} className={classes.notesSubmit}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className={classes.notesSubmitButton}
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      <Typography variant="h6">
                        {isSubmitting ? "Submitting" : "Submit"}
                      </Typography>
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            {/* <Grid container item xs={12} justify='center'> */}
            {/* <Grid item xs={10} sm={12} className={classes.submitGridItem}>
                                <Button
                                    type='submit'
                                    disabled={isSubmitting}
                                    className={classes.submitButton}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                >
                                    <Typography variant='h6'>

                                    {isSubmitting ? 'Submitting' : 'Submit'}
                                    </Typography>
                                </Button>
                            </Grid> */}
            {/* </Grid> */}
          </Form>
        )}
      </Formik>
      <Dialog open={success} handleClose={() => setSuccess(false)}>
        <Typography variant="h5">Success!</Typography>
        <Typography>Your Note has been added.</Typography>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => setSuccess(false)}
        >
          X Close
        </Button>
      </Dialog>
    </>
  );
}
