import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// import Switch from "@material-ui/core/Switch";
import axios from "axios";
import { Auth } from "aws-amplify";
import ExhibitBoothImage0 from "../../Components/Images/BoothImage0-Signature";
// import ExhibitBoothImage0 from "../../Components/Images/ExhibitBoothImage1";
import ExhibitBoothImage1 from "../../Components/Images/BoothImage1-Diamond";
// import ExhibitBoothImage1 from "../../Components/Images/ExhibitBoothImage1";
import ExhibitBoothImage2 from "../../Components/Images/BoothImage2-Platinum";
// import ExhibitBoothImage2 from "../../Components/Images/ExhibitBoothImage2";
import ExhibitBoothImage3 from "../../Components/Images/BoothImage3-Gold";
// import ExhibitBoothImage3 from "../../Components/Images/ExhibitBoothImage3";
import ExhibitBoothImage4 from "../../Components/Images/BoothImage4-Silver";
// import ExhibitBoothImage4 from "../../Components/Images/ExhibitBoothImage4";
import ExhibitBoothImage5 from "../../Components/Images/BoothImage5-Booth";
// import ExhibitBoothImage5 from "../../Components/Images/ExhibitBoothImage5";
import Button from "@material-ui/core/Button";
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExhibitProductItem from "./ExhibitProductItem";
// import ExhibitResourceItem from './ExhibitResourceItem';
import Container from "@material-ui/core/Container";
// import trackAction from "../../Utilities/tracker";
import BoothChat from "./BoothChat";
import Chip from "@material-ui/core/Chip";
// import BoothStaff from './BoothStaff';
import BoothAssets from "./BoothAssets";
// import PerkinElmerResources from './PerkinElmerResources';
import getValidUrl from "../../Utilities/getValidUrl";
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import Divider from "@material-ui/core/Divider";

import moment from "moment";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

// import addBriefcaseElement from '../../Store/Actions/addBriefcaseElement';
// import deleteBriefcaseElement from '../../Store/Actions/deleteBriefcaseElement';

const useStyles = (theme) => ({
  header: {
    margin: "auto",
  },
  image: {
    maxHeight: "80vh",
  },
  imageMapper: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    borderRadius: "0.5%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainPictureImage: {
    maxWidth: "100%",
  },
  gridContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    scrollBehavior: "smooth",
  },
  sash: {
    textAlign: "center",
    lineHeight: "2.5em",
    color: "white",
    boxShadow: "0 0 3px rgba(0,0,0,.3)",
    textShadow: "0px 5px 20px #000000CC",
    borderRadius: theme.spacing(0.5),
    marginBottom: "-4px",
    // width: 'calc(100% + 16px)',
    // marginLeft: '-8px',
  },
  // sash: {
  //   marginTop: "-60px",
  //   marginRight: "-70px",
  //   width: "300px",
  //   position: "sticky",
  //   top: "50px",
  //   left: "auto",
  //   textAlign: "center",
  //   lineHeight: "2.5em",
  //   color: "white",
  //   transform: "rotate(45deg)",
  //   webkitTransform: "rotate(45deg)",
  //   boxShadow: "0 0 3px rgba(0,0,0,.3)",
  //   textShadow: "0px 5px 20px #000000CC",
  //   float: "right",
  // },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  tabSingle: {
    minWidth: "auto",
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});

class ExhibitionBooth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      selectedProductId: null,
      visual: true,
      exhibit: null,
    };
  }

  handleTabChange = (event, newValue, productId) => {
    this.setState({ tabValue: newValue, selectedProductId: productId });
  };

  handleTabChangeImage = (event, newValue, productId) => {
    this.setState({ tabValue: newValue + 1, selectedProductId: productId });
  };

  massageProducts = async (products, exhibit) => {
    if (products.data.data.length) {
      exhibit.data[0].products = await products.data.data
        .map(async (product) => {
          if (product.product_image_url) {
            await this.viewGetImage(product.product_image_url).then(
              (value) => (product.product_image_url = value)
            );
          }
          return product;
        })
        .sort((a, b) => {
          if (!a.booth_order) {
            a.booth_order = 100;
          }

          if (!b.booth_order) {
            b.booth_order = 100;
          }

          return a.booth_order - b.booth_order;
        })
        .slice(0, exhibit.data[0].max_product);
    } else {
      exhibit.data[0].products = [];
    }
    // return Promise.all(promises);
    return exhibit;
  };

  viewGetImage = async (fullImageUrl) => {
    try {
      if (fullImageUrl.includes("eventhorizon-assets")) {
        const user = await Auth.currentSession();

        let filenamePath = fullImageUrl.split(".com/")[1];

        let dataShape = {
          operation: "getObject",
          file_name: filenamePath,
          bucket_name: "eventhorizon-assets",
        };

        const signedUrl = await axios({
          method: "POST",
          url: "/upload/signed",
          headers: {
            idtoken: user.idToken.jwtToken,
          },
          data: dataShape,
        });

        return signedUrl.data;
      } else {
        return fullImageUrl;
      }
    } catch (error) {
      console.log(error);
      return fullImageUrl;
    }
  };

  async componentDidMount() {
    try {
      const user = await Auth.currentSession();

      let exhibit = await axios({
        method: "get",
        url: `/exhibitions/${this.props.event.event.show_id}/accountid/${this.props.event.event.exhibition_id}`, //account id is arbitrary but necessary to hit proper route
        headers: { idtoken: user.idToken.jwtToken },
      });

      const products = await axios({
        method: "get",
        url: `/products/${this.props.event.event.show_id}/${this.props.event.event.exhibition_id}`,
        headers: { idtoken: user.idToken.jwtToken },
      });

      const abstracts = await axios({
        method: "get",
        url: `abstracts/${this.props.event.event.show_id}/${this.props.event.event.exhibition_id}`,
        // url: `/products/${this.props.event.event.show_id}/${this.props.event.event.exhibition_id}`,
        headers: { idtoken: user.idToken.jwtToken },
      });

      // massage product data
      exhibit = await this.massageProducts(products, exhibit);

      let productsSigned = Promise.all(exhibit.data[0].products).then(
        (values) => {
          return values;
        }
      );
      exhibit.data[0].products = await productsSigned;

      // massage abstract data

      let abstractsOrganized = [];

      // for (let x = 0; x < response?.data?.length; x++) {
      //   let abstract = response.data[x];
      //   abstract.date = moment(abstract.start_time, 'YYYY/MM/DD').format();
      //   abstracts.push(abstract);
      // }

      abstractsOrganized = abstracts?.data?.data
        ?.map((abstract) => {
          if (abstract.start_time) {
            abstract.date = moment(abstract.start_time, "YYYY/MM/DD").format();
          } else {
            abstract.date = moment(abstract.created_time, "YYYY/MM/DD")
              .add(5, "years")
              .format();
          }
          return abstract;
        })
        .sort((a, b) => {
          let aEndTime = a.end_time;
          aEndTime = moment(a.end_time).add(15, "minutes").format("HH:mm:ss");
          let bEndTime = b.end_time;
          bEndTime = moment(b.end_time).add(15, "minutes").format("HH:mm:ss");

          if (a.date > b.date) {
            return 1;
          }
          if (a.date < b.date) {
            return -1;
          }
          if (a.date === b.date) {
            if (aEndTime > bEndTime) {
              return 1;
            }
            if (aEndTime < bEndTime) {
              return -1;
            }
            return 0;
          }
        });
      exhibit.data[0].abstracts = abstractsOrganized;

      await this.setState({
        exhibit: exhibit.data[0],
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  handleVisualToggle = () => {
    this.setState({ visual: !this.state.visual });
  };

  briefcaseSelect = () => {
    this.setState({
      tabValue: this.state.tabValue,
    });
  };

  render() {
    const { classes } = this.props;

    if (!this.state.exhibit) {
      return <div></div>;
    }
    let meetMeButton = (
      <>
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          disableElevation
          target="_blank"
          // href={getValidUrl(this.state.exhibit.meeting_room_host_link)}
          style={{ marginTop: "16px", marginBottom: "16px" }}
          onClick={() =>
            alert(
              "This will open your meet me window to video chat with attendees who are in the room too."
            )
          }
        >
          Meet Me
        </Button>
        <br />
      </>
    );

    let exhibitBoothContent;
    // console.log(this.state.exhibit);
    switch (this.state.tabValue) {
      case 1: // overview
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Container>
              <br />
              <Typography
                color="textPrimary"
                variant="h4"
                gutterBottom
                align="center"
              >
                {this.state.exhibit.account_alias}
              </Typography>
              <Button
                variant="contained"
                size="large"
                disableElevation
                target="_blank"
                href={getValidUrl(this.state.exhibit.url)}
                style={{ marginTop: "16px", marginBottom: "16px" }}
              >
                {this.state.exhibit.url}
                {/* {this.state.exhibit.url
                  ? this.state.exhibit.url
                  : this.state.exhibit.website} */}
              </Button>
              <br />
              {meetMeButton}
              <pre
                style={{
                  maxWidth: "70vw",
                  whiteSpace: "pre-line",
                  wordWrap: "break-word",
                }}
              >
                <Typography
                  color="textPrimary"
                  variant="body1"
                  gutterBottom
                  align="justify"
                >
                  {this.state.exhibit.main_text}
                </Typography>
              </pre>
              <br />
              <br />
              <div className={classes.chips}>
                {this.state.exhibit.linked_in_tags && (
                  <>
                    <Typography variant="body1">
                      <b>Keywords</b> <br />
                    </Typography>
                    <Typography variant="subtitle2">
                      {this.state.exhibit.linked_in_tags.split(",").join(", ")}
                    </Typography>
                  </>
                )}
              </div>
            </Container>
            <br />
            <br />
          </Grid>
        );
        break;
      case 2: // products
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom align="center">
              {this.state.exhibit.products.map((product, index) => {
                return (
                  <ExhibitProductItem
                    key={index}
                    product={product}
                    selectedProductId={this.state.selectedProductId}
                  />
                );
              })}
            </Typography>
          </Grid>
        );
        break;
      case 3: //  event activities
        exhibitBoothContent = (
          <Grid item xs={12}>
            <BoothAssets
              exhibit={this.state.exhibit}
              selectedProductId={this.state.selectedProductId}
            />
          </Grid>
        );
        break;
      default:
        exhibitBoothContent = (
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom align="center">
              {this.state.exhibit.title}
            </Typography>
          </Grid>
        );
        break;
    }

    let favorited = false;
    // if (this.props.briefcase.briefcase) {
    //   for (let n = 0; n < this.props.briefcase.briefcase.length; n++) {
    //     if (this.props.briefcase.briefcase[n].resource_id === this.state.exhibit.exhibition_id) {
    //       favorited = true;
    //       break;
    //     }
    //   }
    // }

    let tabRow = (
      <Tabs
        value={this.state.tabValue}
        onChange={this.handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        {favorited ? (
          <Tab
            className={classes.tabSingle}
            onClick={() => this.briefcaseSelect()}
            // icon={<WorkIcon />}
            disableRipple
          />
        ) : (
          <Tab
            className={classes.tabSingle}
            onClick={() => this.briefcaseSelect()}
            // icon={<WorkOutlineIcon />}
            disableRipple
          />
        )}
        <Tab className={classes.tabSingle} label={"Overview"} />
        <Tab className={classes.tabSingle} label={"Products & Services"} />
        <Tab className={classes.tabSingle} label={"Event Activities"} />
      </Tabs>
    );

    let boothHeader = (
      <React.Fragment>
        <Grid item xs={2} align="left">
          {/* <Button onClick={() => this.props.closeBooth()}>
            <ChevronLeftIcon /> Back
          </Button> */}
        </Grid>
        <Grid container item xs={8} justifyContent="center">
          {tabRow}
        </Grid>
        <Grid item xs={2} align="right">
          {/* <Button onClick={this.handleVisualToggle}>
            Display Booth
            <Switch
              checked={this.state.visual}
              onChange={this.handleVisualToggle}
              color="primary"
              name="Visual"
              inputProps={{ "aria-label": "Visual" }}
            />
          </Button> */}
        </Grid>
      </React.Fragment>
    );
    let level;
    let color;
    let lightColor;
    switch (this.state.exhibit.booth_type) {
      case "0":
        color = "#0600b1";
        lightColor = "#554fff";
        level = "Signature Sponsor";
        break;
      case "1":
        color = "#b4e5ca";
        lightColor = "#c9ffe2";
        level = "Diamond Sponsor";
        break;
      case "2":
        color = "#996ae6";
        lightColor = "#c2a7f0";
        level = "Platinum Sponsor";
        break;
      case "3":
        color = "#ff9a0d";
        lightColor = "#fcb044";
        level = "Gold Sponsor";
        break;
      case "4":
        color = "#9e9e9e";
        lightColor = "#c0c0c0";
        level = "Silver Sponsor";
        break;
      case "7":
        color = "#9e9e9e";
        lightColor = "#c0c0c0";
        level = "Media Partner";
        break;
      case "8":
        color = "#f0a82b";
        lightColor = "#edb85c";
        level = "Event Manager";
        break;
      case "9":
        color = "#990000";
        lightColor = "#ab2b2b";
        level = "University";
        break;
      case "10":
        color = "#9e9e9e";
        lightColor = "#c0c0c0";
        level = "Information";
        break;
      case "11":
        color = "#9e9e9e";
        lightColor = "#c0c0c0";
        level = "Host";
        break;
      default:
        color = "#ffffff00";
        lightColor = "#ffffff00";
        level = "";
        break;
    }

    let sash;
    if (
      this.state.exhibit.type === "Internal" ||
      this.state.exhibit.type === "Digital Lab" ||
      this.state.exhibit.account_name === "PlanetConnect Events"
    ) {
      sash = null;
    } else {
      sash = (
        <Typography
          variant="h5"
          className={classes.sash}
          color="textPrimary"
          style={{
            backgroundImage: `linear-gradient(${color}, ${lightColor})`,
          }}
        >
          {level}
        </Typography>
      );
    }

    let ExhibitBoothImageContainer;
    let booth_type = parseInt(this.state.exhibit.booth_type);
    switch (booth_type) {
      case 0:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage0
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 1:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage1
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 2:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage2
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 3:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage3
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 4:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage4
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 8:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage2
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      case 9:
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            {sash}
            <ExhibitBoothImage3
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
            />
          </div>
        );
        break;
      default:
        console.log(this.state);
        ExhibitBoothImageContainer = (
          <div style={{ overflow: "hidden" }}>
            <ExhibitBoothImage5
              handleTabChange={this.handleTabChangeImage.bind(this)}
              exhibit={this.state.exhibit}
              products={this.state.exhibit.products}
              abstracts={this.state.exhibit.abstracts}
              tabValue={this.state.tabValue}
              style={{ padding: "200px" }}
            />
          </div>
        );
        break;
    }

    let boothLayout;

    let chatBox = null;

    chatBox = (
      <Grid item xs={12} lg={12} align="center">
        <Paper className={classes.paper} id={booth_type <= 2 ? "goTo" : ""}>
          <Typography variant="body1" color="textSecondary">
            This will allow you to see which of your staff will display in the
            virtual booth.
          </Typography>
          <BoothChat
            exhibit={this.state.exhibit}
            channel={this.state.exhibit.exhibition_id}
          />
          <Divider
            className={classes.divider}
            variant="fullWidth"
            style={{ paddingTop: "2px", paddingBottom: "2px" }}
          />
          <br />
          <br />
          <Typography variant="body1" color="textSecondary">
            This is a placeholder of the group conversation that will be present
            in your booth.
          </Typography>
          <img
            alt="groupchat"
            src="https://pci-admin-kfc-portal.s3.amazonaws.com/GroupConversationImage.png"
            style={{ width: "-webkit-fill-available" }}
          />
        </Paper>
      </Grid>
    );

    if (this.state.visual) {
      boothLayout = (
        <React.Fragment>
          <Grid item xs={12} md={booth_type > 2 ? 6 : 12} align="center">
            <Paper className={classes.paper}>
              {ExhibitBoothImageContainer}
            </Paper>
            {booth_type > 2 ? (
              <Paper className={classes.paper} style={{ marginTop: "16px" }}>
                {exhibitBoothContent}
              </Paper>
            ) : (
              <React.Fragment />
            )}
          </Grid>
          {booth_type <= 2 ? (
            <React.Fragment>
              <Grid
                container
                item
                xs={12}
                md={12}
                justifyContent="center"
                style={{ textAlign: "center" }}
              >
                {tabRow}
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>{exhibitBoothContent}</Paper>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <Grid item xs={12} md={6} align="center">
            {chatBox}
          </Grid>
        </React.Fragment>
      );
    } else {
      boothLayout = (
        <React.Fragment>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>{exhibitBoothContent}</Paper>
          </Grid>
          <Grid item xs={12} md={6} align="center">
            {chatBox}
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <Grid
        container
        className={classes.gridContainer}
        layout={"row"}
        spacing={2}
      >
        {boothHeader}
        {boothLayout}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    debug: state.debug,
    briefcase: state.briefcase,
    event: state.event,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ExhibitionBooth));
