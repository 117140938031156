import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Grid } from "@material-ui/core/";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpIconOutlined from "@material-ui/icons/ThumbUpOutlined";
import Typography from "@material-ui/core/Typography";

import { Auth } from "aws-amplify";
import axios from "axios";

const useStyles = (theme) => ({
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  center: {
    textAlign: "center",
    width: "100%",
  },
});

class VotingLikes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // liked is coming through as a string. Convert to boolean.
      liked: this.props.currentAbstract.liked === "True" ? true : false,
    };
  }

  async upvotePoster() {
    try {
      const data = await Auth.currentSession();

      if (
        !this.state.liked ||
        this.state.liked === "False" ||
        this.state.liked === "false"
      ) {
        await axios({
          method: "POST",
          url: `/voting/${this.props.eventId}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.currentAbstract.abstract_id,
            resource_type: this.props.currentAbstract.accepted_type,
          },
        });
        this.setState({
          liked: true,
        });
      } else {
        await axios({
          method: "DELETE",
          url: `/voting/${this.props.eventId}/${this.props.currentAbstract.abstract_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          data: {
            resource_id: this.props.currentAbstract.abstract_id,
          },
        });
        this.setState({
          liked: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <Paper className={classes.paper}>
        <Button
          className={classes.center}
          variant="outlined"
          color="primary"
          onClick={() => {
            this.upvotePoster();
          }}
          style={{
            backgroundColor: this.state.liked
              ? theme.palette.primary.light
              : theme.palette.common.white,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.header}
                variant="h5"
                align="center"
              >
                Upvote Abstract
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {this.state.liked === "True" || this.state.liked === true ? (
                <ThumbUpIcon />
              ) : (
                <ThumbUpIconOutlined />
              )}
            </Grid>
          </Grid>
        </Button>
      </Paper>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(VotingLikes);
