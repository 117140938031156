import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  paper: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "80%",
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "60%",
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
});

const showRoles = [
  "Attendee",
  "Attendee Only",
  "Booth Admin",
  "Booth Staff",
  "Event Admin",
  "Host Abstract",
  "Host Admin",
  "Poster Presenter",
  "Reporting",
  "Speaker",
];

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      formSubmitted: false,
      error: false,
      company: "",
      roles: [],
      registerNewUser: false,
      registrationSuccess: null,

      // first name
      firstName: "",
      firstNameError: null,
      firstNameErrorMessage: "",

      // last name
      lastName: "",
      lastNameError: null,
      lastNameErrorMessage: "",

      // email
      email: "",
      emailError: null,
      emailErrorMessage: "",

      // job title
      jobTitle: "",
      jobTitleError: null,
      jobTitleErrorMessage: "",

      // site
      site: "",
      siteError: null,
      siteErrorMessage: "",

      // phone
      phone: "",
      phoneError: null,
      phoneErrorMessage: "",

      // business address
      businessAddress: "",
      businessAddressError: null,
      businessAddressErrorMessage: "",

      // city
      city: "",
      cityError: null,
      cityErrorMessage: "",

      // state
      state: "",
      stateError: null,
      stateErrorMessage: "",

      // department
      department: "",
      departmentError: null,
      departmentErrorMessage: "",

      // job title
      postalCode: "",
      postalCodeError: null,
      postalCodeErrorMessage: "",

      // country
      country: "",
      countryError: null,
      countryErrorMessage: "",

      // account
      accountText: "",
      accountId: null,
      accountError: null,
      accountErrorMessage: "",
      accountSelection: null,
      accounts: {
        options: [],
        getOptionLabel: (option) => option.title,
      },
      selectedAccount: null,

      contactId: null,
      atttendeeId: null,

      Attendee: false,
      "Attendee Only": false,
      "Booth Admin": false,
      "Booth Staff": false,
      "Event Admin": false,
      "Host Abstract": false,
      "Host Admin": false,
      "Poster Presenter": false,
      Reporting: false,
      Speaker: false,
    };
  }

  handleRolesChange(e) {
    this.setState({
      [e.target.value]: !this.state[e.target.value],
    });
  }

  getUserByEmail = async (email) => {
    try {
      this.setState({
        email: email,
        registrationSuccess: null,
        Attendee: false,
        "Attendee Only": false,
        "Booth Admin": false,
        "Booth Staff": false,
        "Event Admin": false,
        "Host Abstract": false,
        "Host Admin": false,
        "Poster Presenter": false,
        Reporting: false,
        Speaker: false,
      });

      const existingRegistrant = await axios({
        method: "GET",
        url: `/show-attendees/${this.props.event.event.show_id}/${email}`,
      });

      if (existingRegistrant.data.length) {
        this.setState({
          accountId: existingRegistrant.data[0].account_id,
          accountText: existingRegistrant.data[0].account_name,
          firstName: existingRegistrant.data[0].first_name,
          lastName: existingRegistrant.data[0].last_name,
          roles: existingRegistrant.data[0].roles,
          site: existingRegistrant.data[0].site,
          contactId: existingRegistrant.data[0].contact_id,
          attendeeId: existingRegistrant.data[0].attendee_id,
          jobTitle: existingRegistrant.data[0].title,
          department: existingRegistrant.data[0].department,
          Attendee: existingRegistrant.data[0].roles.includes("Attendee"),
          "Attendee Only":
            existingRegistrant.data[0].roles.includes("Attendee Only"),
          "Booth Admin":
            existingRegistrant.data[0].roles.includes("Booth Admin"),
          "Booth Staff":
            existingRegistrant.data[0].roles.includes("Booth Staff"),
          "Event Admin":
            existingRegistrant.data[0].roles.includes("Event Admin"),
          "Host Abstract":
            existingRegistrant.data[0].roles.includes("Host Abstract"),
          "Host Admin": existingRegistrant.data[0].roles.includes("Host Admin"),
          "Poster Presenter":
            existingRegistrant.data[0].roles.includes("Poster Presenter"),
          Reporting: existingRegistrant.data[0].roles.includes("Reporting"),
          Speaker: existingRegistrant.data[0].roles.includes("Speaker"),
        });

        this.setState({
          currentUser: true,
        });
      } else {
        this.setState({
          currentUser: false,
          accountId: null,
          accountText: "",
          firstName: "",
          lastName: "",
          roles: null,
          site: "",
          contactId: null,
          attendeeId: null,
          jobTitle: "",
          department: "",
        });
      }
    } catch (error) {
      this.setState({
        formSubmitted: false,
        error: true,
      });
    }
  };

  handleZohoRegistration = async () => {
    this.setState({
      globalError: false,
      emailError: null,
      emailErrorMessage: "",
      firstNameError: null,
      firstNameErrorMessage: "",
      lastNameError: null,
      lastNameErrorMessage: "",
      jobTitleError: null,
      jobTitleErrorMessage: "",
      departmentError: null,
      departmentErrorMessage: "",
    });

    let validation = true;

    if (this.state.email.indexOf(" ") > 0) {
      validation = false;

      this.setState({
        emailError: true,
        emailErrorMessage: "Email cannot contain spaces",
        globalError: true,
      });
    }

    if (!this.state.email) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage: "Email is required",
        globalError: true,
      });
    }

    if (!this.state.email.includes("@") || !this.state.email.includes(".")) {
      validation = false;
      this.setState({
        emailError: true,
        emailErrorMessage: "Email must contain: @ and .",
        globalError: true,
      });
    }

    if (!this.state.firstName) {
      validation = false;
      this.setState({
        firstNameError: true,
        firstNameErrorMessage: "First name is required",
        globalError: true,
      });
    }

    if (!this.state.lastName) {
      validation = false;
      this.setState({
        lastNameError: true,
        lastNameErrorMessage: "Last name is required",
        globalError: true,
      });
    }

    validation = true;

    if (validation) {
      this.setState({
        formSubmitted: true,
        emailError: false,
        emailErrorMessage: "",
        globalError: false,
      });

      try {
        let newRoles = showRoles.filter((role) => {
          if (this.state[role]) {
            return this.state[role];
          } else {
            return null;
          }
        });

        let user = await Auth.currentSession();

        let registrationStatus = await axios({
          method: "POST",
          url: "/zoho/show-attendees/admin",
          headers: {
            idtoken: user.idToken.jwtToken,
          },
          data: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            jobTitle: this.state.jobTitle,
            site: this.state.site,
            // phone: this.state.phone,
            businessAddress: this.state.businessAddress,
            city: this.state.city,
            state: this.state.state,
            postalCode: this.state.postalCode,
            country: this.state.country,
            // notes: `${this.state.findOut},${this.state.getOut}`,
            showId: this.props.event.event.show_id,
            department: this.state.department,
            accountId: this.state.selectedAccount.account_id,
            roles: newRoles,
          },
        });

        if (registrationStatus) {
          this.setState({
            formSubmitted: false,
            registrationSuccess: true,
          });
        }
      } catch (error) {
        this.setState({
          existingZohoRegistrant: false,
          formSubmitted: false,
          globalError: true,
          emailError: true,
          emailErrorMessage:
            "Email is not valid for registration. Please contact support.",
        });
      }
    }
  };

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  handleCountrySelect() {}

  handleAccountSelection() {}

  handleAccountTypeAhead = async (accountName) => {
    try {
      this.setState({
        accountText: accountName,
      });

      const currentUser = await Auth.currentSession();

      const accounts = await axios({
        method: "get",
        url: `accounts/listfilter/${this.state.accountText}`,
        headers: { idtoken: currentUser.idToken.jwtToken },
      });

      let options = accounts.data.map((account) => {
        account.title = account.name;
        return account;
      });

      this.setState({
        accounts: {
          options: options,
          getOptionLabel: (option) => option.title,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;

    let user;

    if (this.state.registrationSuccess) {
      user = (
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <br />
                <Typography className={classes.center} variant="h5">
                  User has been registered!
                </Typography>
                <br />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    } else if (this.state.currentUser) {
      user = (
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <br />
                <Typography className={classes.center} variant="h5">
                  User already exists
                </Typography>
                <br />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    } else if (this.state.currentUser === false) {
      user = (
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <br />
                <Typography className={classes.center} variant="h5">
                  Register New User
                </Typography>
                <br />
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    onChange={(event) => this.handleChange(event, "firstName")}
                    value={this.state.firstName}
                    label="First Name"
                    error={this.state.firstNameError}
                    helperText={this.state.firstNameErrorMessage}
                  />
                  <br />
                  <TextField
                    required
                    onChange={(event) => this.handleChange(event, "lastName")}
                    value={this.state.lastName}
                    label="Last Name"
                    error={this.state.lastNameError}
                    helperText={this.state.lastNameErrorMessage}
                  />
                  <br />
                  <TextField
                    required
                    onChange={(event) => this.handleChange(event, "department")}
                    value={this.state.department}
                    label="Department"
                    error={this.state.departmentError}
                    helperText={this.state.departmentErrorMessage}
                  />
                  <br />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    onChange={(event) => this.handleChange(event, "jobTitle")}
                    value={this.state.jobTitle}
                    label="Job Title"
                    error={this.state.jobTitleError}
                    helperText={this.state.jobTitleErrorMessage}
                  />
                </FormControl>
                {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "site")}
                                    value={this.state.site}
                                    label="Site"
                                    error = {this.state.siteError}
                                    helperText={this.state.siteErrorMessage}
                                />
                            </FormControl> */}
                {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "phone")}
                                    value={this.state.phone}
                                    label="Phone Number"
                                    error = {this.state.phoneError}
                                    helperText={this.state.phoneErrorMessage}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "businessAddress")}
                                    value={this.state.businessAddress}
                                    label="Business Address"
                                    error = {this.state.businessAddressError}
                                    helperText={this.state.businessAddressErrorMessage}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "city")}
                                    value={this.state.city}
                                    label="City/Locality"
                                    error = {this.state.cityError}
                                    helperText={this.state.cityErrorMessage}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "state")}
                                    value={this.state.state}
                                    label="State/Province"
                                    error = {this.state.stateError}
                                    helperText={this.state.stateErrorMessage}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleChange(event, "postalCode")}
                                    value={this.state.postalCode}
                                    label="Postal Code"
                                    error = {this.state.postalCodeError}
                                    helperText={this.state.postalCodeErrorMessage}
                                />
                            </FormControl> */}
                {/* <FormControl className={classes.formControl}>
                                <TextField
                                    onChange={(event) => this.handleAccountTypeAhead(event.target.value)}
                                    value={this.state.accountText}
                                    label="Account Name"
                                    error = {this.state.accountError}
                                    helperText={this.state.accountErrorMessage}
                                />
                            </FormControl> */}
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    {...this.state.accounts}
                    onChange={(event, value) =>
                      this.setState({ selectedAccount: value })
                    } // prints the selected value
                    renderInput={(params) => (
                      <TextField
                        required
                        error={this.state.accountError}
                        helperText={this.state.accountErrorMessage}
                        onChange={(event) =>
                          this.handleAccountTypeAhead(event.target.value)
                        }
                        {...params}
                        label="Account Name"
                        margin="normal"
                      />
                    )}
                  />
                </FormControl>
                <FormGroup row>
                  {showRoles.map((role, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={this.state[role]}
                            onChange={(e) => this.handleRolesChange(e)}
                            name={role}
                            color="primary"
                            value={role}
                          />
                        }
                        label={role}
                      />
                    );
                  })}
                </FormGroup>
                {this.state["Attendee"] ||
                this.state["Attendee Only"] ||
                this.state["Host Abstract"] ? (
                  <Typography
                    className={classes.center}
                    style={{ color: "red" }}
                    variant="body1"
                  >
                    WARNING: User Can View Confidential Information
                  </Typography>
                ) : null}
                {/* <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.country}
                                error={this.state.countryError}
                                onChange={this.handleCountrySelect}
                                >
                                    {countryList.map((country, index) => {
                                        return <MenuItem key={index} value={country}>{country}</MenuItem>
                                    })}
                                </Select>
                                {this.state.countryErrorMessage ? <FormHelperText>{this.state.countryErrorMessage}</FormHelperText>  : null}
                            </FormControl> */}
                <FormControl className={classes.formControlButton}>
                  {this.state.formSubmitted ? (
                    <Button
                      variant="outlined"
                      onClick={() => this.registerNewUser}
                    >
                      Registering...
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => this.handleZohoRegistration()}
                      className={classes.button}
                    >
                      Register
                    </Button>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    Super Admin Register User
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  onChange={(event) => this.getUserByEmail(event.target.value)}
                  value={this.state.email}
                  label="Email"
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                />
              </FormControl>
            </Paper>
          </Grid>
          {user}
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}
export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(Registration));
