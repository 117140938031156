import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
      // indexAxis: 'y',
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Top Ten Posters by Popularity'
        }
      }
};

export default function Reporting(props) {
const [ filteredReport, setFilteredReport] = useState([])

    useEffect(() => {

            let posters = []
            let posterClickCount = {}
    
            props.report.map((datum) => {

                let labelString
                  if(datum.resource_name.length > 30){
                    labelString = datum.resource_name.substring(0,27) + '...'
                  }else {
                    labelString = datum.resource_name
                  }

                    if(!posters.includes(labelString)){
                        posters.push(labelString)
                    }

                    if (!Object.keys(posterClickCount).includes(labelString)){
                        posterClickCount[labelString] = 1;
                    } else {
                        posterClickCount[labelString]++
                    }
    
                return true
            })

            let sortedPosterClickCount = Object.entries(posterClickCount)
            .sort(([,a],[,b]) => b - a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});


            let labels = Object.keys(sortedPosterClickCount).slice(0, 10)
            let data = Object.values(sortedPosterClickCount).slice(0, 10)


            setFilteredReport({
                labels: labels,
                datasets: [
                    {
                      label: 'Total Clicks',
                      data: data,
                      fill: true,
                      backgroundColor: '#6f1a1d',
                      borderColor: '#6f1a1d',
                    },
                ]
            })

    }, [props.report])

  return props.loading ? <CircularProgress/> : <Bar data={filteredReport} options={options} />
}
