import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { eventHostGridOptions } from "./eventGridOptions";
import { DataTable } from "../Shared/DataTable";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  // root: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  // },
  paperHeader: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  paper: {
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    height: "100%",
  },
  centerHeader: {
    textAlign: "center",
    // marginBottom: theme.spacing(1),
  },
  center: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  showDetail: {
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    marginBottom: theme.spacing(2),
    // paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(0.5),
  },
});

class EventInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
    };
  }

  componentDidMount() {
    if (this.props.event.event !== null) {
      this.getEvent();
      // this.getAcceptedSponsorships()
      // this.getDeclinedSponsorships()
      // this.getProspectiveSponsorshipPartnersByStage()
      // this.getRecentlyAcceptedSponsorshipPartners()
      // this.getRecentlyDeclinedSponsorshipPartners()
    }
  }

  getEvent() {
    //Event details
    try {
      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url: "shows/" + this.props.event.event.show_id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            this.setState({
              event: response.data[0],
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      // this.setState({
      //     editing: false,
      //     currentAbstract: null,
      // })
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let singleTable = (titleText, initialGridOptions, initialRowData, type) => (
      <Paper className={classes.paper}>
        <Typography className={classes.center} variant="h6">
          {titleText}
        </Typography>
        <Divider className={classes.divider} variant="fullWidth" />
        <DataTable
          initialGridOptions={initialGridOptions}
          initialRowData={initialRowData}
          // actionButtons={AssetGridActionButtons}
          type={type}
          // setEditing={(e) => this.handleEditingChange(e)}
          // setCurrentPage={(e) => this.setCurrentAbstract(e)}
        />
        {!initialRowData.length ? (
          <Typography className={classes.center} variant="subtitle1">
            No Data Available
          </Typography>
        ) : null}
      </Paper>
    );

    let singleItem = (showPlaceholder, actualShowDetail) => (
      <Grid container layout={"row"} spacing={2}>
        <Grid item xs={6} md={5}>
          <Typography
            className={classes.showDetail}
            variant="body1"
            color="textPrimary"
          >
            <b>{showPlaceholder}</b>
          </Typography>
        </Grid>
        <Grid item xs={6} md={7}>
          <Typography
            className={classes.showDetail}
            variant="body1"
            color="textPrimary"
          >
            {typeof actualShowDetail === "boolean"
              ? actualShowDetail.toString()
              : actualShowDetail}
          </Typography>
        </Grid>
      </Grid>
    );

    let bodyContent;

    if (!this.state.event) {
      bodyContent = (
        <Typography className={classes.center} variant="h5">
          Event Info cannot be found
        </Typography>
      );
    } else {
      bodyContent = (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.center}
                variant="h6"
                color="textPrimary"
              >
                Show Basics
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              {singleItem("Show Name:", this.state.event.name)}
              {singleItem("Show Active:", this.state.event.is_active)}
              {singleItem("Show Start Date:", this.state.event.start_date)}
              {singleItem("Show End Date:", this.state.event.end_date)}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.center}
                variant="h6"
                color="textPrimary"
              >
                Target Estimates
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              {/* {singleItem('Target Virtual Attendees Registered:', `${this.state.event.target_number_attendees} SAME FIELD 1`)} */}
              {singleItem(
                "Target Exhibit Booths Registered:",
                this.state.event.target_number_booths
              )}
              {singleItem(
                "Target In Person Attendees Registered:",
                `${this.state.event.target_number_attendees}`
              )}
              {singleItem(
                "Target Total Posters:",
                this.state.event.target_number_posters
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.center}
                variant="h6"
                color="textPrimary"
              >
                Show Details
              </Typography>
              <Divider className={classes.divider} variant="fullWidth" />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {singleItem("Event Code:", this.state.event.show_id)}
                  {singleItem(
                    "Attendee Website:",
                    this.state.event.attendee_website
                  )}
                  {singleItem(
                    "Exhibitor Website:",
                    this.state.event.host_portal
                  )}
                </Grid>
                <Grid item xs={6}>
                  {singleItem("Year:", this.state.event.year)}
                  {singleItem(
                    "Virtual Environment Website:",
                    this.state.event.virtual_environment_website
                  )}
                  {singleItem("Event Facility:", this.state.event.facility)}
                  {singleItem(
                    "V Conversion Rate:",
                    this.state.event.conversion_rate
                  )}
                </Grid>
              </Grid>
              {/* {singleItem('Description:', this.state.event.description)} */}
              <br />
              <Grid container layout={"row"} spacing={2}>
                <Grid item xs={6} md={2}>
                  <Typography
                    className={classes.showDetail}
                    variant="body1"
                    color="textPrimary"
                  >
                    <b>Description:</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography
                    className={classes.showDetail}
                    variant="body1"
                    color="textPrimary"
                  >
                    {this.state.event.description}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* the below line is in need of programming for the event host information. the third paramater, the empty array, is where it should go. -Ryan */}
            {singleTable(
              "Event Host Information",
              eventHostGridOptions,
              [],
              "event_host_info"
            )}
          </Grid>
        </Grid>
      );
    }
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paperHeader}>
              <Typography
                className={classes.centerHeader}
                variant="h4"
                color="textPrimary"
              >
                Event Dashboard
              </Typography>
            </Paper>
            {/* {this.state.editing ?
                                <AssetInfo
                                    currentAbstract={this.state.currentAbstract}
                                    getAssets={this.getAssets.bind(this)}
                                    handleEditClose={this.handleEditClose.bind(this)}
                                /> :
                            } */}
            {bodyContent}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(EventInfo));
