import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
          component={Link}
          to={"/"}
        >
          Home
        </Typography>
        {/* <Button color="inherit" component={Link} to={"/signin"}>
          Sign in
        </Button> */}
        {/* <Button color="inherit" component={Link} to={'/signup'}>New User</Button> */}
      </Toolbar>
    </AppBar>
  );
}
