import React, { useState } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";

import { Formik, Form, Field, FieldArray, useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { FileUploader } from "../Shared/FileUploader";
import Dialog from "../../Components/Dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(12),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    // padding: theme.spacing(1)
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  submitGridItem: {
    position: "fixed",
    bottom: "60px",
    // left: (props) => (props.sidebar?.open ? "120px" : "2px"),
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  },
  submitButton: {
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
}));

const PollsInfoField = ({ ...props }) => {
  const [field, meta] = useField(props);

  if (field.value === null) {
    field.value = "";
  }
  return (
    <>
      <Field
        className="text-input"
        component={TextField}
        margin="dense"
        variant="outlined"
        fullWidth
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error" style={{ color: "red" }}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

const PollSwitchField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  if (field.value === null) {
    field.value = "";
  }

  const handleVisualToggle = () => {
    setValue(!field.value);
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Field
          component={Switch}
          checked={field.value}
          onChange={handleVisualToggle}
          color="primary"
          name="Visual"
          inputProps={{ "aria-label": "Visual" }}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">{props.placeholder}</Typography>
      </Grid>
    </Grid>
  );
};

const PollsAnswerField = ({ ...props }) => {
  const [field] = useField(props);

  let newProps = { ...props };
  delete newProps.deleteanswer;
  return (
    <Grid container>
      <Grid item xs={9} sm={10}>
        <Field
          className="text-input"
          component={TextField}
          margin="dense"
          variant="outlined"
          fullWidth
          {...field}
          value={field.value}
          {...newProps}
        />
      </Grid>
      <Grid
        item
        xs={3}
        sm={2}
        style={{ alignSelf: "center", textAlign: "end" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={props.deleteanswer}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

const wait = async function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

let validateURL = (url) => {
  let error;
  const urlExpression =
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
  let regex = new RegExp(urlExpression);
  if (url !== "") {
    if (url.match(regex)) {
      error = "";
    } else {
      error = "Invalid URL";
    }
  }
  return error;
};

export function PollsInfo(props) {
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const classes = useStyles();

  const handleFileUpload = (file, meta, status) => {
    setFile(file);
  };

  let basePoll = {
    is_multiple: false,
    show_id: "",
    owner_id: "",
    start_time: null,
    end_time: null,
    image_url: null,
    question: "",
    status: "",
    api_token: "",
    is_published: false,
    answers: [
      {
        type: "text",
        value: "",
      },
      {
        type: "text",
        value: "",
      },
    ],
    created_time: "",
    modified_time: "",
    vote_url: props.websiteUrl,
  };

  let emptyAnswer = {
    poll_id: props.currentPoll?.poll_id ? props.currentPoll.poll_id : "", // to be same as id as poll id fromt poll object
    type: "text",
    value: "",
  };

  // console.log('file', file);
  return (
    <div className={classes.root}>
      <Formik
        initialValues={props.currentPoll ? props.currentPoll : basePoll}
        onSubmit={async (values, { setSubmitting }) => {
          await new Promise((r) => setTimeout(r, 500));

          const user = await Auth.currentSession();

          if (file) {
            let formData = new FormData();
            formData.append("file", file);

            try {
              let filenamePath = `${props.show.pharma_company.toLowerCase()}/${
                props.show.show_code
              }/${file.name}`;
              let dataShape = {
                operation: "putObject",
                file_name: filenamePath,
                bucket_name: "eventhorizon-assets-public",
              };

              const signedUrl = await axios({
                method: "POST",
                url: "/upload/signed",
                headers: {
                  idtoken: user.idToken.jwtToken,
                },
                data: dataShape,
              });

              await fetch(signedUrl.data, {
                method: "PUT",
                body: file,
              });

              values.image_url = signedUrl.data.split("?")[0];
            } catch (error) {
              console.log(error);
            }
          }

          const response = await axios({
            method: "put",
            url: `polls/${props.currentPoll.poll_id}`,
            data: values,
          });

          if (response.data) {
            setSuccess(true);
          } else {
            alert("Your product could not be updated");
          }

          await wait(3000);

          await setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Poll Information
                  </Typography>
                  <Divider className={classes.divider} />
                  <PollsInfoField
                    name="question"
                    id="question"
                    label="Question"
                    type="text"
                    placeholder="Question"
                  />
                  <PollsInfoField
                    name="status"
                    id="status"
                    label="Status"
                    type="text"
                    multiline
                    placeholder="Status"
                  />
                  {/* CREATE TIME FIELD FOR THE 4 DIFFERENT TIME FIELDS */}
                  <PollsInfoField
                    name="image_url"
                    id="image_url"
                    label="Image Url"
                    type="text"
                    placeholder="Image Url"
                    validate={validateURL}
                  />
                  {/* <Typography
                    style={{ marginBottom: "8px" }}
                    variant="subtitle2"
                  >
                    Uploading a PDF will take the place of this product URL
                  </Typography> */}
                  {/* <PollsInfoField
                    name="product_video_url"
                    id="product_video_url"
                    label="Video Url"
                    type="text"
                    placeholder="Video Url"
                    validate={validateURL}
                  /> */}
                  {/* <Divider className={classes.divider} />
                  Time fields go here  */}
                  {/* TODO: install time fields */}
                  <Divider className={classes.divider} />
                  <PollSwitchField
                    name="is_published"
                    id="is_published"
                    label="Is Published"
                    type="text"
                    placeholder="Is Published"
                  />
                  <PollSwitchField
                    name="is_multiple"
                    id="is_multiple"
                    label="Is Multiple"
                    type="text"
                    placeholder="Is Multiple"
                  />
                </Paper>
                <Paper className={classes.paper}>
                  <FieldArray
                    name="answers"
                    render={(arrayHelpers) => (
                      <>
                        <Grid container>
                          <Grid item xs={3} />
                          <Grid item xs={6}>
                            <Typography className={classes.header} variant="h5">
                              Potential Answers
                            </Typography>
                          </Grid>
                          <Grid item xs={3} style={{ textAlign: "right" }}>
                            <Button
                              onClick={() => {
                                arrayHelpers.push(emptyAnswer);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Add Answer
                            </Button>
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        {values.answers?.length ? (
                          values.answers.map((value, index) => {
                            return (
                              <PollsAnswerField
                                key={`answer ${index}`}
                                name={`answers[${index}].value`}
                                id={`answers[${index}].value`}
                                label={`Answer ${index + 1}`}
                                type="text"
                                placeholder={`Answer ${index + 1}`}
                                deleteanswer={() => arrayHelpers.remove(index)}
                              />
                            );
                          })
                        ) : (
                          <Typography>Please add potential answers</Typography>
                        )}
                      </>
                    )}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Current Image
                  </Typography>
                  <Divider className={classes.divider} />
                  {props.currentPoll ? (
                    <img
                      src={props.currentPoll.image_url}
                      width="50%"
                      alt="poll"
                      // style={{marginLeft: '25%',}}
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <Divider className={classes.divider} />
                  <Typography className={classes.header} variant="subtitle1">
                    Update Poll Photo:
                  </Typography>
                  <Typography className={classes.header} variant="subtitle2">
                    Accepts PNG, JPG and GIF. Square aspect ratio such as 800px
                    by 800px is recommended. Max size 50mb.
                  </Typography>
                  <FileUploader
                    handleFileUpload={handleFileUpload.bind(this)}
                    acceptedTypes={[".png", ".jpg", ".jpeg", ".gif"]}
                  />
                </Paper>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.submitGridItem}
              style={{ left: props.sidebar?.open ? "120px" : "2px" }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                className={classes.submitButton}
                color="primary"
                variant="contained"
                size="large"
                // style={{ marginTop: '16px', width: '100%', height: '100%',}}
              >
                <Typography variant="h6">
                  {isSubmitting ? "Saving..." : "Save Changes"}
                </Typography>
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
      <Dialog open={success} handleClose={() => props.handleEditClose()}>
        <Typography variant="h5">Success!</Typography>
        <Typography>Your Poll has been updated.</Typography>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.handleEditClose()}
        >
          X Close
        </Button>
      </Dialog>
    </div>
  );
}
