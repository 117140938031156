import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Formik, Form } from 'formik';

import { EditResourceInfo } from './EditResourceInfo';
import { EditResourceLink } from './EditResourceLink';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        padding: theme.spacing(1)
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

export function ResourceInfo() {
    const classes = useStyles();

    // const formik = useFormik({
    //     initialValues: {
    //         primaryColor: '',
    //         secondaryColor: ''
    //     },
    //     onSubmit: values => {
    //         alert(JSON.stringify(values, null, 2));
    //     },
    // });

    return (
        <div className={classes.root}>
            <Formik>
                <Form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.paper}>
                                <h2>Resource Information</h2>
                                <EditResourceInfo />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <h2>Link</h2>
                                <EditResourceLink />
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    );
}