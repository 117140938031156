import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
// import { withAuthenticator } from "aws-amplify-react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Auth } from "aws-amplify";
import Divider from "@material-ui/core/Divider";

// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import getChatMessages from '../../Store/Actions/getChatMessages';
// import addChatSubscription from '../../Store/Actions/addChatSubscription';
// import setChatMembership from '../../Store/Actions/setChatMembership';
// import selectActivePrivateChat from '../../Store/Actions/selectActivePrivateChat';
// import setChatbar from '../../Store/Actions/setChatbar';
// import viewPrivateChat from '../../Store/Actions/viewPrivateChat';

const useStyles = (theme) => ({
  root: {
    width: "100%",
    "& .MuiTypography-root": {
      alignSelf: "center",
    },
    "& .MuiChip-root	": {
      alignSelf: "center",
    },
    "& .MuiExpansionPanelSummary-content": {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "80%",
  },
  formControlButton: {
    margin: theme.spacing(2),
    // minWidth: 120,
    // width: '50%'
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  own: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    maxWidth: "100%",
  },
  img: {
    borderRadius: "50%",
    maxWidth: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: "20vh",
  },
  other: {},
  expansionPanelMain: { padding: "0px 8px 0px 8px" },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "60%",
    flexShrink: 0,
  },
  staffCard: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  icon: {
    minWidth: "10em",
    maxWidth: "30em",
    maxHeight: "30em",
    width: "100%",
  },
  merck: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      input: "",
      channelOccupants: [],
      maxStaff: this.props.exhibit.max_staff,
      boothStaff: [],
    };
  }

  componentDidMount = async () => {
    Auth.currentSession()
      .then((data) => {
        axios({
          method: "get",
          url: `/show-attendees/staff/${this.props.event.event.show_id}/${this.props.event.event.exhibition_id}`,
          headers: {
            idtoken: data.idToken.jwtToken,
          },
          params: {
            order_by: "booth_order",
            order_dir: "asc",
          },
        })
          .then((response) => {
            let visibleStaff = [];

            response.data.data.map((staff) => {
              if (staff.booth_visible) {
                visibleStaff.push(staff);
              }
              return staff;
            });
            // I am not sure why this sort is necessary as items should be coming back in booth_order ascending based on above params. -Ryan
            visibleStaff.sort((a, b) => a.booth_order - b.booth_order);
            visibleStaff = visibleStaff.slice(0, this.state.maxStaff);
            this.setState({
              boothStaff: [...this.state.boothStaff, ...visibleStaff],
            });
          })
          .catch((error) => {
            console.log(error);
            alert(
              `Error: The Booth Staff could not be displayed. Please reload the page and check your connection. If you continue to have problems, please reach out the the technical support team using the chat in the bottom right corner of the virtual environment.`
            );
          });
      })
      .catch((error) => {
        alert(
          `Error: The Booth Staff could not be displayed. Please reload the page and check your connection. If you continue to have problems, please reach out the the technical support team using the chat in the bottom right corner of the virtual environment.`
        );
      });
  };

  render() {
    const { classes } = this.props;

    const truncateAfterXWords = (str) => {
      let x = 30;
      // need to add a ... if the limit is reached
      return str.split(" ").splice(0, x).join(" ");
    };

    const boothStaff = this.state.boothStaff.map((staffer, index) => {
      let communicationButtons = (
        <FormControl className={classes.formControlButton}>
          <Button variant="contained" className={classes.button}>
            Chat
          </Button>
        </FormControl>
      );

      return (
        <Grid item xs={6} key={index} className={classes.staffCard}>
          <Typography
            className={classes.name}
            variant="h6"
            color="textPrimary"
            align="center"
          >
            {staffer.first_name + " " + staffer.last_name}
          </Typography>
          {staffer.photo_url ? (
            <img src={staffer.photo_url} className={classes.img} alt="staff" />
          ) : (
            <React.Fragment />
          )}
          <Divider className={classes.divider} variant="middle" />
          <Typography variant="body1" align="center">
            {staffer.expertise_area ? (
              <React.Fragment>
                <b>Expertise:</b> {truncateAfterXWords(staffer.expertise_area)}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Typography>
          {staffer.expertise_area ? (
            <Divider className={classes.divider} variant="middle" />
          ) : (
            <React.Fragment />
          )}
          <Typography variant="body1" align="center">
            {staffer.title ? (
              <React.Fragment>
                <b>Title:</b> {staffer.title}{" "}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </Typography>
          {/* <FormControl className={classes.formControlButton}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${staffer.email}`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" className={classes.button}>
                Email
              </Button>
            </a>
          </FormControl>
          {communicationButtons} */}
        </Grid>
      );
    });

    // let occupants = this.state.channelOccupants.map((occupant, index) => {
    //     let color = 'other'
    //     if(occupant.custom.accountName === "Merck"){
    //         color = 'merck'
    //     }
    //     return (
    //         <ExpansionPanel
    //         key={occupant.email}
    //         className={classes.root}
    //       >
    //         <ExpansionPanelSummary
    //           className={classes.expansionPanelMain + " " + classes[color]}
    //           expandIcon={<ExpandMoreIcon />}
    //         >
    //         <Typography variant="h6" component='div'>
    //                 {occupant.name}
    //             </Typography>
    //         </ExpansionPanelSummary>
    //         <ExpansionPanelDetails>
    //           <Grid
    //             container
    //             direction='row'
    //             spacing={2}
    //             alignItems='center'
    //             justifyContent='space-between'
    //           >
    //             <Grid item xs={12}>
    //             <Typography variant="body1" align='left' >
    //                 {occupant.custom.accountName? <React.Fragment><b>Company:</b> {occupant.custom.accountName}</React.Fragment>: <React.Fragment/>}
    //             </Typography>
    //             <Typography variant="body1" align='left' >
    //                 {occupant.custom.title ? <React.Fragment><b>Title:</b> {occupant.custom.title}</React.Fragment>: <React.Fragment/>}
    //             </Typography>
    //             <Typography variant="body1" align='left' >
    //                 {occupant.custom.department ? <React.Fragment><b>Department:</b> {occupant.custom.department}</React.Fragment>: <React.Fragment/>}
    //             </Typography>
    //             <Typography variant="body1" align='left' >
    //                 {occupant.custom.site ? <React.Fragment><b>Site:</b> {occupant.custom.site}</React.Fragment>: <React.Fragment/>}
    //             </Typography>
    //             {this.props.user.user.email !== occupant.email && !this.props.chat.users.find((user) => user.uuid.id === occupant.email) ?  //|| this.props.chat.indexOf(occupant.email)
    //             <FormControl className={classes.formControlButton}>
    //                 <Button variant="contained" className={classes.button} onClick={() => this.inviteUser(occupant.id)} >
    //                      Chat
    //                 </Button>
    //             </FormControl>
    //             :
    //             <FormControl className={classes.formControlButton}>
    //                 <Button variant="contained" className={classes.button} onClick={() => this.selectPrivateChat(occupant.email)} >
    //                     View Private Chat
    //                 </Button>
    //             </FormControl>
    //             }
    //             </Grid>
    //           </Grid>
    //         </ExpansionPanelDetails>
    //       </ExpansionPanel>
    //     )
    // })

    let previousSender;
    let messages = this.state.messages.map((message) => {
      if (!message.entry.text) {
        return null;
      }

      let align = "left";
      let color = "other";

      if (message.entry.email === this.props.user.user.email) {
        align = "right";
        color = "own";
      }

      if (previousSender === message.entry.email) {
        return (
          <React.Fragment key={message.timetoken}>
            <Typography variant="body1" component="div" align={align}>
              <div className={classes[color]}>{message.entry.text}</div>
            </Typography>
          </React.Fragment>
        );
      } else {
        previousSender = message.entry.email;
        return (
          <React.Fragment key={message.timetoken}>
            <br />
            <Typography variant="h6" component="div" align={align}>
              {message.entry.from ? message.entry.from : "Anonymous"}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              align={align}
              className={classes[color]}
            >
              {message.entry.text}
            </Typography>
          </React.Fragment>
        );
      }
    });

    return (
      <Grid container layout={"row"} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            component="div"
            align="center"
            color="textPrimary"
          >
            Booth Staff
          </Typography>
          <Divider
            className={classes.divider}
            variant="fullWidth"
            style={{ paddingTop: "2px", paddingBottom: "2px" }}
          />
        </Grid>
        <Grid
          container
          layout={"column"}
          justifyContent="center"
          style={{ maxHeight: "50vh", overflowY: "scroll" }}
        >
          {boothStaff}
        </Grid>
        {/* <Grid
          item
          xs={12}
          style={{
            maxHeight: "80vw",
            overflowY: "scroll",
            overflowX: "hidden",
            margin: "2vw",
          }}
        >
          <Typography variant="h5" component="div" align="center">
            Here Now
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} style={{ maxHeight: '80vw', overflowY: 'scroll', paddingLeft: '8px', paddingRight: '8px',}}>
                        <Divider className={classes.divider} variant="fullWidth" style={{paddingTop: '2px', paddingBottom: '2px',}} />
                        <Typography variant="h5" component='div' align='center' color='textPrimary'>
                            Group Conversation
                        </Typography>
                            {messages}
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    value={this.state.input}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlButton}>
                                <Button variant="contained" onClick={this.sendMessage} className={classes.button}>
                                    Send
                             </Button>
                            </FormControl>
                    </Grid> */}
      </Grid>
      // </div>
    );
  }
}

const MyTheme = {
  button: { backgroundColor: "#6f1a1d" },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // getChatMessages: getChatMessages,
      // addChatSubscription: addChatSubscription,
      // setChatMembership: setChatMembership,
      // selectActivePrivateChat: selectActivePrivateChat,
      // setChatbar: setChatbar,
      // viewPrivateChat: viewPrivateChat,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(Chat)));
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(
//   withRouter(
//     withStyles(useStyles)(withAuthenticator(Chat, false, [], null, MyTheme))
//   )
// );
