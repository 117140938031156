import React, { useState, useEffect } from 'react';

import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Topic Popularity'
        }
      }
};

export default function Reporting(props) {
const [ filteredReport, setFilteredReport] = useState([])

    useEffect(() => {

            let topics = []
            let topicClickCount = {}
    
            props.report.map((datum) => {

              let labelString
              if(datum.topic.length > 30){
                labelString = datum.topic.substring(0,27) + '...'
              } else {
                labelString = datum.topic
              }

                    if(!topics.includes(labelString)){
                        topics.push(labelString)
                    }

                    if (!Object.keys(topicClickCount).includes(labelString)){
                        topicClickCount[labelString] = 1;
                    } else {
                        topicClickCount[labelString]++
                    }
    
                return true
            })

            setFilteredReport({
                labels: Object.keys(topicClickCount),
                datasets: [
                    {
                      label: 'Total Clicks',
                      data: Object.values(topicClickCount),
                      fill: true,
                      backgroundColor: '#6f1a1d',
                      borderColor: '#6f1a1d',
                    },
                ]
            })

    }, [props.report])

  return props.loading ? <CircularProgress /> : <Bar data={filteredReport} options={options} />
}
