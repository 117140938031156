import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';

import { AppDrawer } from './AppDrawer';
import { AppTitleBar } from './AppTitleBar';

import { drawerOpened } from './navSlice';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export function NavContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.navigation.open);

  const handleDrawerOpen = () => {
    dispatch(drawerOpened(true))
  };

  const handleDrawerClose = () => {
    dispatch(drawerOpened(false))
  };

  return (
    <div className={classes.root}>
      <AppTitleBar open={open} handleClick={handleDrawerOpen} />
      <AppDrawer open={open} handleClick={handleDrawerClose} />
    </div>
  );
}